import React from 'react'
import { Grid } from '@mui/material'
import * as S from './style'
import { Formik, Form, useFormik } from 'formik';
import * as Yup from "yup";
import flatpickr from "flatpickr";
import "assets/theme/custom-flatpickr.css";
import { flatpickrOptions } from 'utils/utils';
import InputAdornment from '@mui/material/InputAdornment';
import { RiCalendarEventFill } from 'react-icons/ri';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import { getFinancialDownloadData } from 'utils/requests/financial';
import { CSVLink } from "react-csv";
import moment from 'moment';
import 'moment/locale/pt-br';

const DownloadInsights = () => {
  moment.locale("pt-br");

  const [validateFields, setValidateFields] = React.useState(false);

  const unixDate = (date) => {
    return Number(moment(date, 'DD/MM/YYYY').valueOf());
  };


  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      startDate: '',
      endDate: '',
    },

    validateOnChange: validateFields,
    validateOnBlur: false,

    validationSchema: Yup.object({
      startDate: Yup.string().test('start-date-test', 'Data inicial não pode ser maior que a final', function (value) {
        if (!formik.values.endDate || !value) {
          return true;
        }
        if (formik.errors.endDate) {
          return true;
        }
        return unixDate(value) <= unixDate(formik.values.endDate);
      }),
      endDate: Yup.string().test('end-date-test', 'Data final não pode ser menor que a inicial', function (value) {
        if (!formik.values.startDate || !value) {
          return true;
        }
        if (formik.errors.startDate) {
          return true;
        }
        return unixDate(value) >= unixDate(formik.values.startDate);
      }),
    }),

    onSubmit: (values) => {
      /*fetchDownloadData();*/
    }
  });

  const [checked, setChecked] = React.useState(true);
  const [filterValues, setFilterValues] = React.useState([]);
  const [filters, setFilters] = React.useState({ type: null, startDate: formik.values.startDate, endDate: formik.values.endDate });
  const [downloadData, setDownloadData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [handleCsvData, setHandleCsvData] = React.useState(false);

  const mockFilters = [
    { name: 'Entradas', filter: 'ENTRADA' },
    { name: 'Resumo', filter: 'SAIDA' },
    { name: 'Lista de Pedidos', filter: "TRANSFERENCIA" },
    { name: 'Produto + Renda Total', filter: "TRANSFERENCIA" },
    { name: 'Origem + Vendedor', filter: "TRANSFERENCIA" },
  ];

  const handleAllChange = (bool) => {
    setChecked(!checked)
    if (bool === true) {
      const checkArr = Array.from({ length: mockFilters.length }, () => true);
      setFilterValues(checkArr);
    };
    if (bool === false) {
      const checkArr = Array.from({ length: mockFilters.length }, () => false);
      setFilterValues(checkArr);
    };
  };

  const handleOnChange = (position) => {
    const updatedCheckedState = filterValues.map((item, index) =>
      index === position ? !item : item
    );
    setFilterValues(updatedCheckedState);
    if (updatedCheckedState.includes(true)) {
      setChecked(false);
    };
    if (!updatedCheckedState.includes(false)) {
      setChecked(true);
    };
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  flatpickr("#startDate", flatpickrOptions('startDate', formik.setFieldValue, formik.setFieldTouched, true));
  flatpickr(".endDate", flatpickrOptions('endDate', formik.setFieldValue, formik.setFieldTouched, true));

  React.useState(() => {
    const checkArr = Array.from({ length: mockFilters.length }, () => false);
    setFilterValues(checkArr);
  }, [mockFilters]);

  const categoryFilter = () => {
    const checkedIndexes = filterValues?.map((bool, index) => bool === true ? index : null).filter((el) => (el !== null));
    const result = checkedIndexes?.map((index) => mockFilters[index].filter);
    result.length > 0
      ?
      setFilters((prevData) => ({ ...prevData, type: result }))
      :
      setFilters((prevData) => ({ ...prevData, type: null }));
  };

  React.useEffect(() => {
    categoryFilter();
  }, [filterValues]);

  React.useEffect(() => {
    if (formik.values.startDate) setFilters((prevData) => ({ ...prevData, startDate: unixDate(formik.values.startDate) }));
    if (formik.values.endDate) setFilters((prevData) => ({ ...prevData, endDate: unixDate(formik.values.endDate) }));
  }, [formik.values]);

  const fetchDownloadData = async () => {
    setLoading(true)
    const data = await getFinancialDownloadData(filters);
    if (!data.message) {
      setDownloadData(data);
      setLoading(false)
      setHandleCsvData(true);
    }
  };

  React.useEffect(() => {
    if (downloadData) setHandleCsvData(false)
  }, [filters]);

  return (
    <Grid container flexDirection='column'>
      <p>Baixar CSV de insights</p>
      <Formik initialValues={formik.initialValues} validationSchema={formik.validationSchema} onSubmit={formik.handleSubmit} validateOnChange validateOnBlur validateOnMount>
        <Form style={{ width: '100%' }}>
          <Grid container flexDirection='column' gap='15px' mt='10px'>
            <S.DateInputGrid item xs={12} md={12} xl={12} id='startDate'>
              <S.Input
                name='startDate'
                hiddenLabel
                type='text'
                placeholder="Data Inicial"
                fullWidth
                value={formik.values.startDate}
                data-input
                autoComplete='off'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <RiCalendarEventFill />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end'>
                      <CloseIcon
                        onClick={() => {
                          formik.setFieldValue('startDate', '')
                          setFilters((prevData) => ({ ...prevData, startDate: null }))
                        }}
                        sx={{ cursor: "pointer", "&:hover": { color: "#E22B00" } }}
                      />
                    </InputAdornment>
                  )
                }}
                error={Boolean(formik.errors.startDate) && formik.touched.startDate}
                helperText={formik.touched.startDate ? formik.errors.startDate : false}
              />
            </S.DateInputGrid>
            <S.DateInputGrid item xs={12} md={12} xl={12} className='endDate'>
              <S.Input
                name='endDate'
                hiddenLabel
                type='text'
                placeholder="Data Final"
                fullWidth
                value={formik.values.endDate}
                data-input
                autoComplete='off'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <RiCalendarEventFill />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end'>
                      <CloseIcon
                        onClick={() => {
                          formik.setFieldValue('endDate', '')
                          setFilters((prevData) => ({ ...prevData, endDate: null }))
                        }}
                        sx={{ cursor: "pointer", "&:hover": { color: "#E22B00" } }}
                      />
                    </InputAdornment>
                  )
                }}
                error={Boolean(formik.errors.endDate) && formik.touched.endDate}
                helperText={formik.touched.endDate ? formik.errors.endDate : false}
              />
            </S.DateInputGrid>
            <Grid container flexDirection='column' px='14px'>
              <p>Tipos:</p>
              <S.CheckboxGrid container onClick={() => handleAllChange(!checked)}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={checked}
                />
                <p style={{ fontSize: '16px' }}>Todos</p>
              </S.CheckboxGrid>
              {mockFilters.map((category, index) => (
                <Grid container key={index}>
                  <S.CheckboxGrid container key={category.name} onClick={() => handleOnChange(index)}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={filterValues[index]}
                    /*                   sx={{
                                        '.MuiSvgIcon-root': {
                                          fill: `rgb(${colors[index]}) !important`,
                                        },
                                      }} */
                    />
                    <p style={{ fontSize: '16px' }}>{category.name}</p>
                  </S.CheckboxGrid>
                </Grid>
              ))
              }
            </Grid>
            <Grid container justifyContent='flex-end'>
              {!handleCsvData && !loading &&
                <ButtonCustom
                  label='Emitir CSV'
                  type='submit'
                  sx={{ width: "150px", height: "40px !important", fontSize: "18px", fontWeight: "500" }}
                  /*onClick={() => setValidateFields(true)}*/
                />
              }
              {loading &&
                <S.DownloadLoadingButton loading />
              }
              {handleCsvData &&
                <CSVLink
                  data={downloadData}
                  filename='dados-exportados.csv'
                  asyncOnClick
                  onClick={(event, done) => {
                    done();
                  }}
                >
                  <ButtonCustom
                    label='Download CSV'
                    /*                   type='submit' */
                    sx={{ width: "150px", height: "40px !important", fontSize: "18px", fontWeight: "500" }}
                  />
                </CSVLink>
              }
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </Grid>
  )
}

export default DownloadInsights