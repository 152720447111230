import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import VuiBox from "components/VuiBox";
import { Card, Grid } from '@mui/material'
import { useContext, useState, useEffect } from "react";
import { TokenContext } from "context/TokenContext";
import * as S from './style'
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import "assets/theme/custom-flatpickr.css";
import 'moment/locale/pt-br';
import { RiMailFill } from "react-icons/ri";
import { FiArrowUpRight } from "react-icons/fi";
import Table from "defaultComponents/Tables/Table";
import { getClientOrders } from "../clients";

const EcommerceClientDetails = () => {
  const { decodedToken } = useContext(TokenContext);
  const role = decodedToken?.type;

  const [orders, setOrders] = useState([]);

  const fetchOrders = async () => {
    const data = await getClientOrders();
    if (!data.statusCode) {
      setOrders(data);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Grid container flexDirection="column" gap="16px">
          {(role === "ADMINISTRATOR" || role === "H_MARKETING") && (
            <Grid container spacing="18px">
              <S.CardGrid item xs={12} lg={12} xl={12} sx={{ minHeight: "400px" }}>
                <Card>
                  <Grid container flexDirection="column">
                    <Grid container>
                      <Grid item xl={6}>
                        <p className="cardTitle">Informações do Cliente</p>
                        <Grid container>
                          <p className="subtitle">Gerenciar informações básicas do cliente</p>
                        </Grid>
                      </Grid>
                      <Grid container item xl={6} justifyContent="flex-end" gap="10px">
                        <S.MailButton style={{ padding: 0 }}>
                          <RiMailFill size="28px" color="#fff" />
                        </S.MailButton>
                      </Grid>
                    </Grid>
                    <S.ClientFormGrid
                      container
                      flexDirection="column"
                      sx={{ minHeight: "270px" }}
                      gap="30px"
                      mt="35px"
                      justifyContent="center"
                    >
                      <Grid container gap="15px">
                        <Grid container lg={5.85} xl={5.85}>
                          <Grid item lg={3} xl={3}>
                            <p>Nome:</p>
                          </Grid>
                          <S.BorderedInputGrid item lg={8.5} xl={8.5} container flexDirection="column">
                            <S.Input
                              name="name"
                              id="name"
                              placeholder="Nome Completo do Cliente Cadastrado"
                            />
                          </S.BorderedInputGrid>
                        </Grid>
                        <Grid container lg={5.85} xl={5.85}>
                          <Grid item lg={3} xl={3}>
                            <p>Username:</p>
                          </Grid>
                          <S.BorderedInputGrid item lg={8.5} xl={8.5} container flexDirection="column">
                            <S.Input
                              name="username"
                              id="username"
                              placeholder="@usernamedocliente"
                            />
                          </S.BorderedInputGrid>
                        </Grid>
                      </Grid>
                      <Grid container gap="15px">
                        <Grid container lg={5.85} xl={5.85}>
                          <Grid item lg={3} xl={3}>
                            <p>Email:</p>
                          </Grid>
                          <S.BorderedInputGrid item lg={8.5} xl={8.5} container flexDirection="column">
                            <S.Input
                              name="email"
                              id="email"
                              placeholder="emaildoclientecadastrado@gmail.com"
                            />
                          </S.BorderedInputGrid>
                        </Grid>
                        <Grid container lg={5.85} xl={5.85}>
                          <Grid item lg={3} xl={3}>
                            <p>Telefone:</p>
                          </Grid>
                          <S.BorderedInputGrid item lg={8.5} xl={8.5} container flexDirection="column">
                            <S.Input
                              name="contactNumber"
                              id="contactNumber"
                              placeholder="82 55555-5555"
                            />
                          </S.BorderedInputGrid>
                        </Grid>
                      </Grid>
                      <Grid container gap="15px">
                        <Grid container lg={5.85} xl={5.85}>
                          <Grid item lg={3} xl={3}>
                            <p>CPF:</p>
                          </Grid>
                          <S.BorderedInputGrid item lg={8.5} xl={8.5} container flexDirection="column">
                            <S.Input
                              name="cpf"
                              id="cpf"
                              placeholder="000.000.000-00"
                            />
                          </S.BorderedInputGrid>
                        </Grid>
                        <Grid container lg={5.85} xl={5.85}>
                          <Grid item lg={3} xl={3}>
                            <p>CEP:</p>
                          </Grid>
                          <S.BorderedInputGrid item lg={8.5} xl={8.5} container flexDirection="column">
                            <S.Input
                              name="postalCode"
                              id="postalCode"
                              placeholder="55555-555"
                            />
                          </S.BorderedInputGrid>
                        </Grid>
                      </Grid>
                      <Grid container gap="15px">
                        <Grid container lg={5.85} xl={5.85}>
                          <Grid item lg={3} xl={3}>
                            <p>Estado:</p>
                          </Grid>
                          <S.BorderedInputGrid item lg={8.5} xl={8.5} container flexDirection="column">
                            <S.Input
                              name="state"
                              id="state"
                              placeholder="Estado do cliente"
                            />
                          </S.BorderedInputGrid>
                        </Grid>
                        <Grid container lg={5.85} xl={5.85}>
                          <Grid item lg={3} xl={3}>
                            <p>Cidade:</p>
                          </Grid>
                          <S.BorderedInputGrid item lg={8.5} xl={8.5} container flexDirection="column">
                            <S.Input
                              name="city"
                              id="city"
                              placeholder="Cidade do cliente"
                            />
                          </S.BorderedInputGrid>
                        </Grid>
                      </Grid>
                      <Grid container gap="15px">
                        <Grid container lg={5.85} xl={5.85}>
                          <Grid item lg={3} xl={3}>
                            <p>Bairro:</p>
                          </Grid>
                          <S.BorderedInputGrid item lg={8.5} xl={8.5} container flexDirection="column">
                            <S.Input
                              name="neighborhood"
                              id="neighborhood"
                              placeholder="Bairro do cliente"
                            />
                          </S.BorderedInputGrid>
                        </Grid>
                        <Grid container lg={5.85} xl={5.85}>
                          <Grid item lg={3} xl={3}>
                            <p>Número:</p>
                          </Grid>
                          <S.BorderedInputGrid item lg={8.5} xl={8.5} container flexDirection="column">
                            <S.Input
                              name="houseNumber"
                              id="houseNumber"
                              placeholder="Número da residência"
                            />
                          </S.BorderedInputGrid>
                        </Grid>
                      </Grid>
                      <Grid container gap="15px">
                        <Grid container lg={5.85} xl={5.85}>
                          <Grid item lg={3} xl={3}>
                            <p>Endereço:</p>
                          </Grid>
                          <S.BorderedInputGrid item lg={8.5} xl={8.5} container flexDirection="column">
                            <S.Input
                              name="address"
                              id="address"
                              placeholder="Endereço do cliente"
                            />
                          </S.BorderedInputGrid>
                        </Grid>
                        <Grid container lg={5.85} xl={5.85}>
                          <Grid item lg={3} xl={3}>
                            <p>Complemento:</p>
                          </Grid>
                          <S.BorderedInputGrid item lg={8.5} xl={8.5} container flexDirection="column">
                            <S.Input
                              name="addressComplement"
                              id="addressComplement"
                              placeholder="Complemento do endereço"
                            />
                          </S.BorderedInputGrid>
                        </Grid>
                      </Grid>
                    </S.ClientFormGrid>
                    <Grid container justifyContent="flex-end" gap="10px" mt="35px">
                      {/*                      <ButtonCustom
                        label="Cancelar"
                        sx={{
                          background: "linear-gradient(180deg, #818181 0%, #2a2020 155.15%)",
                          width: "100px",
                        }}
                      />*/}
                      <ButtonCustom
                        label='Salvar Alterações'
                        type='submit'
                        sx={{ width: "165px", height: "36px !important", fontSize: "14px", fontWeight: "600" }}
                      />
                    </Grid>
                  </Grid>
                </Card>
              </S.CardGrid>
            </Grid>
          )}
          <Grid container spacing="18px">
            <S.CardGrid item xs={12} lg={12} xl={12} sx={{ height: "384px" }}>
              <S.TableCard sx={{ overflow: 'auto' }}>
                <Grid container flexDirection="column" mt="20px">
                  <Grid container>
                    <Grid item xl={6}>
                      <p className="cardTitle">Histórido de Pedidos</p>
                    </Grid>
                    <Grid container item xl={6} justifyContent="flex-end" gap="10px">
                      <S.MailButton style={{ padding: 0 }}>
                        <FiArrowUpRight size="28px" color="#fff" />
                      </S.MailButton>
                    </Grid>
                  </Grid>
                  <VuiBox mt="15px">
                    <Table
                      mb={role === "ADMINISTRATOR" ? "20px" : 0}
                      columns={[
                        { name: "pedido", align: "left" },
                        { name: "data", align: "left" },
                        { name: "afiliado", align: "left" },
                        { name: "total", align: "center" },
                        { name: "forma de pagamento", align: "center" },
                        { name: "itens", align: "center" },
                      ]}
                      rows={
                        orders &&
                        orders.map((order, index) => {
                          return {
                            pedido: (
                              <S.InfoTableGrid container flexDirection="column">
                                <p className="numero" style={{
                                  color: '#FC6508'}}
                                >
                                  #{order.numero}
                                </p>
                              </S.InfoTableGrid>
                            ),
                            data: (
                              <S.InfoTableGrid container flexDirection="column">
                                <p className="data">
                                  {order.data}
                                </p>
                              </S.InfoTableGrid>
                            ),
                            afiliado: (
                              <S.InfoTableGrid container>
                                <p className="afiliado">
                                  {order.afiliado}
                                </p>
                              </S.InfoTableGrid>
                            ),
                            total: (
                              <S.InfoTableGrid container justifyContent="center">
                                <p className="total">
                                  {order.total}
                                </p>
                              </S.InfoTableGrid>
                            ),
                            "forma de pagamento": (
                              <S.InfoTableGrid container justifyContent="center">
                                <p className="formaPagamento">
                                  {order.formaPagamento}
                                </p>
                              </S.InfoTableGrid>
                            ),
                            itens: (
                              <S.InfoTableGrid container justifyContent="center">
                                <p className="itens">
                                  {order.itens}
                                </p>
                              </S.InfoTableGrid>
                            ),
                          };
                        })
                      }
                    />
                  </VuiBox>
                </Grid>
              </S.TableCard>
            </S.CardGrid>
          </Grid>
        </Grid>
      </VuiBox>
    </DashboardLayout>
  );
};

export default EcommerceClientDetails;