import React from "react";
import * as S from "./style";
import VuiTypography from "components/VuiTypography";
import VuiBox from "components/VuiBox";
import { IoArrowUp, IoArrowDown } from "react-icons/io5";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import placeholderImg from "assets/placeholder.png";
import { Grid } from "@mui/material";
import { abbreviateNumbers } from "utils/utils";
import filter from "assets/icons/filter.svg";
import VuiProgress from "components/VuiProgress";

const StateRanking = ({ stateList, rankingType, xs, lg, xl, openFilterModalFn }) => {
  return (
    <S.RankingCard item xs={xs} lg={lg} xl={xl} sx={{ height: "auto" }}>
      <Card sx={{ height: "100%" }}>
        <Grid container justifyContent="space-between">
          <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
            Vendas por Estados
          </VuiTypography>
          <S.EditButton>
            <img src={filter} draggable="false" onClick={openFilterModalFn} />
          </S.EditButton>
        </Grid>
        {/*         {influencerList?.length === 0 &&
          <p>Sem informações</p>
        } */}
        {stateList?.map((influencer, index) => (
          <VuiBox key={index}>
            <VuiBox display="flex" alignItems="center" mb="10px" mt="20px">
              <Grid container justifyContent='space-between'>
                <S.RankingGrid display='flex' gap="10px">
                  <p>{index + 1}.</p>
                  <S.RankingPhoto sx={{ marginLeft: index === 0 ? "4px" : 0 }}>
                    <div className="photoContainer">
                      <img
                        src={
                          influencer?.influencer.avatar
                            ? influencer?.influencer.avatar
                            : placeholderImg
                        }
                        width="100%"
                        height="100%"
                        draggable="false"
                      />
                    </div>
                  </S.RankingPhoto>
                  <S.NumberGrid flexDirection="column">
                    <p>{"Teste"}</p>
                    <Grid width="10rem" sx={{ lineHeight: "0.5" }}>
                      <VuiTypography color="white" variant="button" fontWeight="bold">
                        {0}%
                      </VuiTypography>
                      <VuiProgress
                        value={0}
                        color="viuzz"
                        label={false}
                        sx={{ background: "rgba(96, 96, 96, 0.5)" }}
                      />
                    </Grid>
                  </S.NumberGrid>
                </S.RankingGrid>
                <p style={{ color: '#fff', alignSelf: 'center' }}>R$ 100.000,00</p>
              </Grid>
            </VuiBox>
          </VuiBox>
        ))}
      </Card>
    </S.RankingCard>
  );
};

StateRanking.defaultProps = {
  stateList: [],
  xs: 12,
  lg: 6,
  xl: 5,
};

StateRanking.propTypes = {
  stateList: PropTypes.array,
  xs: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
};

export default StateRanking;
