import { EventNote, Payments, MarkunreadMailbox, AirportShuttle, LocationOn } from "@mui/icons-material";

export const mockDeliverySteps = [
  { title: "Pedido Realizado", 
    icon: <EventNote sx={{ width: 30, height: 30 }} />, 
    date: "31/12/2025", 
    status: "realizado" 
  },
  { title: "Pedido Pago", 
    icon: <Payments sx={{ width: 30, height: 30 }} />, 
    date: "31/12/2025", 
    status: "pago" 
  },
  { title: "Pedido Enviado", 
    icon: <MarkunreadMailbox sx={{ width: 30, height: 30 }} />, 
    date: "-", 
    status: "enviado" 
  },
  { title: "Pedido a Caminho", 
    icon: <AirportShuttle sx={{ width: 30, height: 30 }} />, 
    date: "-", 
    status: "caminho" 
  },
  { title: "Pedido Entregue", 
    icon: <LocationOn sx={{ width: 30, height: 30 }} />, 
    date: "-", 
    status: "entregue" 
  }
];