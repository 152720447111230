export let REACT_APP_API_URL = process.env.REACT_APP_API_URL;
if (!process.env.REACT_APP_API_URL) REACT_APP_API_URL = process.env.REACT_APP_API_DEV_URL
import mockProductsList from "./MockedLists/MockProductsList";

export const getProducts = async (page, filters) => {
  const size = 10;
  const start = page * size;
  const end = start + size;

  const filteredProducts = mockProductsList.filter((product) => {
    const matchesSubCategories = filters?.subCategories?.length
      ? filters.subCategories.some((subCategory) => product.subCategories?.includes(subCategory))
      : true;

    const matchesCategories = filters?.categories?.length
      ? filters.categories.some((category) => product.categories?.includes(category))
      : true;

    const matchesName = filters?.name
      ? product.name.toLowerCase().includes(filters.name.toLowerCase())
      : true;

    return matchesSubCategories && matchesCategories && matchesName;
  });

  const data = filteredProducts.slice(start, end);
  return data;
};

export const getProductsCategories = async () => {
  const uniqueCategories = [...new Set(mockProductsList.map((product) => product.categories))];

  const data = uniqueCategories.map((category) => ({ id: category, name: category }));
  return data;
};

export const getProductsSubCategories = async () => {
  const uniqueSubCategories = [...new Set(mockProductsList.map((product) => product.subCategories))];

  const data = uniqueSubCategories.map((subCategory) => ({ id: subCategory, name: subCategory }));
  return data;
};

export const deleteProduct = async (name) => {
  const productIndex = mockProductsList.findIndex((product) => product.name === name);
  if (productIndex !== -1) {
    mockProductsList.splice(productIndex, 1);
    return { success: true };
  }
};

export const editProductStatus = async (name, updates) => {
  const productIndex = mockProductsList.findIndex((product) => product.name === name);

  if (productIndex === -1) {
    return { message: "Produto não encontrado" };
  }

  mockProductsList[productIndex] = {
    ...mockProductsList[productIndex],
    ...updates,
  };

  return { success: true };
};

export const editProduct = async (productInfo) => {
  // Encontre o produto na lista mockada
  const productIndex = mockProductsList.findIndex((product) => product.id === productInfo.id);

  if (productIndex === -1) {
    return { message: "Produto não encontrado" }; // Garantir que sempre retorne um objeto
  }

  // Atualize o produto no mockProductsList
  mockProductsList[productIndex] = {
    ...mockProductsList[productIndex],
    ...productInfo,
  };

  return { success: true }; // Retorne um objeto com a chave 'success'
};



export const createProduct = async (productInfo) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/products`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(productInfo),
  });
  const data = await response.json();
  return data;
};

export const editProductPhoto = async (edit) => {
  const formData = new FormData();
  Object.entries(edit).forEach(entry => {
    const isArray = Array.isArray(entry[1]);
    if (isArray) {
      entry[1].forEach((str, index) => {
        formData.append(`${entry[0]}[${index}]`, str);
      })
      return
    }
    formData.append(entry[0], entry[1]);
  });
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + ``, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`
    },
    body: formData,
  });
  const data = await response.json();
  return data;
};