import styled from 'styled-components'
import { Button, Grid } from '@mui/material'

export const EditButton = styled(Button)`
  width: 41px !important;
  min-width: 30px !important;
  height: 41px !important;
  border-radius: 12px !important;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 100%) !important;
`

export const IconBlock = styled(EditButton)`
  width: 65px !important;
  height: 65px !important;
  cursor: default !important;
  transform: none !important;
`

export const HistoricButton = styled(EditButton)`
  width: 41px !important;
  height: 41px !important;
  padding: 5px !important;
`

export const MainContainer = styled(Grid)`
  .positive {
    color: #65EFAD;
  }
  .negative {
    color: #D7007B;
  }
  .transfer {
    color: #FB8404;
  }
  .subtitle {
    font-size: 14px;
    opacity: 0.75;
  }
  .cardTitle {
    font-size: 20px;
    font-weight: 600;
  }
`

export const CardGrid = styled(Grid)`
  font-size: 14px;
  overflow: auto;
`

export const BlockGrid = styled(Grid)`
  /* justify-content: space-between !important; */
  color: #fff;
  min-width: 350px !important;

  .title {
    opacity: 0.75;
    font-size: 16px;
  }
  .value {
    font-size: 26px;
    font-weight: 700;
  }
  .percentage {
    font-size: 16px;
    align-self: center;
    margin-left: 5px;
  }
`

export const TableGrid = styled(Grid)`
  p {
    color: #fff;
  }
`

export const DateGrid = styled(Grid)`
  justify-content: space-between;

  @media (max-width: 992px) {
    justify-content: center;
    gap: 16px;
  }
`

export const HiddenGrid = styled(Grid)`
  visibility: hidden;
`

export const PieChartGrid = styled(Grid)`
  min-height: 240px;
  margin-top: 30px !important;
  .apexcharts-legend-text{
    color: rgba(255, 255, 255, 0.75) !important;
  }
  .apexcharts-legend-marker{
    border-radius: 3px !important;
  }
`

export const AgeChartGrid = styled(Grid)`
  height: 100%;
  margin-top: 30px;
`