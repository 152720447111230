const mockOrders = [
  {
    numero: "100001",
    data: "23/12/2024 às 14:19",
    cliente: "Bruno Cadence",
    total: "R$ 1.000.000,00",
    pagamento: "Pago",
    status: "Não processado",
    itens: 100
  },
  {
    numero: "100002",
    data: "23/12/2024 às 14:19",
    cliente: "Jorge Donthe",
    total: "R$ 1.000.000,00",
    pagamento: "Pendente",
    status: "Não processado",
    itens: 100
  },
  {
    numero: "100003",
    data: "23/12/2024 às 14:19",
    cliente: "Clothilde Gyjts",
    total: "R$ 1.000.000,00",
    pagamento: "Pago",
    status: "Em produção",
    itens: 100
  },
  {
    numero: "100004",
    data: "23/12/2024 às 14:19",
    cliente: "Josefa Beatrice",
    total: "R$ 1.000.000,00",
    pagamento: "Cancelado",
    status: "Não processado",
    itens: 100
  },
  {
    numero: "100005",
    data: "23/12/2024 às 14:19",
    cliente: "Clothilde Gyjts",
    total: "R$ 1.000.000,00",
    pagamento: "Pago",
    status: "Em transporte",
    itens: 100
  },
  {
    numero: "100006",
    data: "23/12/2024 às 14:19",
    cliente: "Bruno Cadence",
    total: "R$ 1.000.000,00",
    pagamento: "Pago",
    status: "Entregue",
    itens: 100
  },
  {
    numero: "100007",
    data: "23/12/2024 às 14:19",
    cliente: "Bruno Cadence",
    total: "R$ 1.000.000,00",
    pagamento: "Pago",
    status: "Entregue",
    itens: 100
  },
  {
    numero: "100008",
    data: "23/12/2024 às 14:19",
    cliente: "Josefa Beatrice",
    total: "R$ 1.000.000,00",
    pagamento: "Cancelado",
    status: "Cancelado",
    itens: 100
  },
  {
    numero: "100009",
    data: "23/12/2024 às 14:19",
    cliente: "Clothilde Gyjts",
    total: "R$ 1.000.000,00",
    pagamento: "Pago",
    status: "Em produção",
    itens: 100
  },
  {
    numero: "100010",
    data: "23/12/2024 às 14:19",
    cliente: "Jorge Donthe",
    total: "R$ 1.000.000,00",
    pagamento: "Pendente",
    status: "Não processado",
    itens: 100
  },
  {
    numero: "100011",
    data: "23/12/2024 às 14:19",
    cliente: "Bruno Cadence",
    total: "R$ 1.000.000,00",
    pagamento: "Pago",
    status: "Não processado",
    itens: 100
  },
  {
    numero: "100012",
    data: "23/12/2024 às 14:19",
    cliente: "Jorge Donthe",
    total: "R$ 1.000.000,00",
    pagamento: "Pendente",
    status: "Não processado",
    itens: 100
  },
  {
    numero: "100013",
    data: "23/12/2024 às 14:19",
    cliente: "Clothilde Gyjts",
    total: "R$ 1.000.000,00",
    pagamento: "Pago",
    status: "Em produção",
    itens: 100
  },
  {
    numero: "100014",
    data: "23/12/2024 às 14:19",
    cliente: "Josefa Beatrice",
    total: "R$ 1.000.000,00",
    pagamento: "Cancelado",
    status: "Não processado",
    itens: 100
  },
  {
    numero: "100015",
    data: "23/12/2024 às 14:19",
    cliente: "Clothilde Gyjts",
    total: "R$ 1.000.000,00",
    pagamento: "Pago",
    status: "Em transporte",
    itens: 100
  },
  {
    numero: "100016",
    data: "23/12/2024 às 14:19",
    cliente: "Bruno Cadence",
    total: "R$ 1.000.000,00",
    pagamento: "Pago",
    status: "Entregue",
    itens: 100
  },
  {
    numero: "100017",
    data: "23/12/2024 às 14:19",
    cliente: "Bruno Cadence",
    total: "R$ 1.000.000,00",
    pagamento: "Pago",
    status: "Entregue",
    itens: 100
  },
  {
    numero: "100018",
    data: "23/12/2024 às 14:19",
    cliente: "Josefa Beatrice",
    total: "R$ 1.000.000,00",
    pagamento: "Cancelado",
    status: "Cancelado",
    itens: 100
  },
  {
    numero: "100019",
    data: "23/12/2024 às 14:19",
    cliente: "Clothilde Gyjts",
    total: "R$ 1.000.000,00",
    pagamento: "Pago",
    status: "Em produção",
    itens: 100
  },
  {
    numero: "100020",
    data: "23/12/2024 às 14:19",
    cliente: "Jorge Donthe",
    total: "R$ 1.000.000,00",
    pagamento: "Pendente",
    status: "Não processado",
    itens: 100
  }
]

export default mockOrders