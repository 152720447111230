

import { useEffect, useState, useContext } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard React example components
import SidenavCollapse from "defaultComponents/Sidenav/SidenavCollapse";
import SidenavCard from "defaultComponents/Sidenav/SidenavCard";

// Custom styles for the Sidenav
import SidenavRoot from "defaultComponents/Sidenav/SidenavRoot";
import sidenavLogoLabel from "defaultComponents/Sidenav/styles/sidenav";

// Vision UI Dashboard React context
import { useVisionUIController, setMiniSidenav, setTransparentSidenav } from "context";

// Vision UI Dashboard React icons
import Viraliza from '../../assets/viraliza.svg'
import { IoExitOutline } from 'react-icons/io5'

import * as S from './styles/style'
import { useNavigate } from "react-router-dom";
import { roleBasedRoutes } from "../../routes/routes";
import { TokenContext } from "context/TokenContext";

// function Sidenav({ color, brand, brandName, routes, ...rest }) {
function Sidenav({ color, ...rest }) {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const { decodedToken, setUserToken } = useContext(TokenContext);

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("dashUser");
    localStorage.removeItem("token");
    setUserToken(null)
    navigate("/login");
    /*     FB.init({
          appId: '589588973346726',
          xfbml: true,
          version: 'v17.0'
        });
        FB.logout(function(response) {
          console.log(response);
       }); */
  };

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1024);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setTransparentSidenav(dispatch, false);
    }
    const userType = decodedToken?.type;
    setRoutes(userType ? roleBasedRoutes[userType]() : []);
  }, []);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, route, href, subRoutes }) => {
    let returnValue;

    const subRouteName = subRoutes ? pathname.split("/").slice(1)[1] : '';

    if (type === "collapse") {
      returnValue = (
        <div key={key}>
          <SidenavCollapse
            color={"primary"}
            key={route}
            routeKey={key}
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
            route={route}
            subRoutes={subRoutes}
            subRouteActive={subRouteName}
          />
        </div>
      );
    } else if (type === "title") {
      returnValue = (
        <VuiTypography
          key={title}
          color="white"
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </VuiTypography>
      );
    } else if (type === "divider") {
      returnValue = <Divider light key={title} />;
    }

    return returnValue;
  });

  return (
    <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, miniSidenav }}>
      <VuiBox
        pt={1}
        pb={0.5}
        px={4}
        textAlign="center"
      >
        <VuiBox
          display={{ xs: "block", lg: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <VuiTypography variant="h6" color="text">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </VuiTypography>
        </VuiBox>
        <VuiBox component={NavLink} to="/" display="flex" alignItems="center">
          <VuiBox
            sx={
              ((theme) => sidenavLogoLabel(theme, { miniSidenav }),
              {
                display: "flex",
                alignItems: "center",
                margin: "0 auto",
              })
            }
          >
            <VuiBox
              display="flex"
              sx={
                ((theme) => sidenavLogoLabel(theme, { miniSidenav, transparentSidenav }),
                {
                  mr: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : 1,
                })
              }
            >
              <img src={Viraliza} />
            </VuiBox>
          </VuiBox>
        </VuiBox>
      </VuiBox>
      <Divider light />
      <List>{renderRoutes}</List>
      <S.BottomBox
        my={2}
        mx={2}
        mt="auto"
        sx={({ breakpoints }) => ({
          [breakpoints.up("xl")]: {
            pt: 2,
          },
          [breakpoints.only("xl")]: {
            pt: 1,
          },
          [breakpoints.down("xl")]: {
            pt: 2,
          },
        })}
      >
        <S.LogoutButton onClick={handleLogout}>
          <IoExitOutline size='24px' />
          Sair
        </S.LogoutButton>
        <Link component="a" href="/versions" target="_blank" rel="noopener noreferrer">
          <p className="versions">V1.7.3</p>
        </Link>
      </S.BottomBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  // brand: PropTypes.string,
};

export default Sidenav;
