export const mockProducts = [
  {
    nome: "Nome do Produto Extremamente Grande",
    modelo: "Modelo do Produto",
    preco: "1.000,00",
    quantidade: 1000,
    total: "1.000.000,00"
  },
  {
    nome: "Nome do Produto Extremamente Grande",
    modelo: "Modelo do Produto",
    preco: "1.000,00",
    quantidade: 1000,
    total: "1.000.000,00"
  },
  {
    nome: "Nome do Produto Extremamente Grande",
    modelo: "Modelo do Produto",
    preco: "1.000,00",
    quantidade: 1000,
    total: "1.000.000,00"
  },
  {
    nome: "Nome do Produto Extremamente Grande",
    modelo: "Modelo do Produto",
    preco: "1.000,00",
    quantidade: 1000,
    total: "1.000.000,00"
  },
  {
    nome: "Nome do Produto Extremamente Grande",
    modelo: "Modelo do Produto",
    preco: "1.000,00",
    quantidade: 1000,
    total: "1.000.000,00"
  },
  {
    nome: "Nome do Produto Extremamente Grande",
    modelo: "Modelo do Produto",
    preco: "1.000,00",
    quantidade: 1000,
    total: "1.000.000,00"
  },
  {
    nome: "Nome do Produto Extremamente Grande",
    modelo: "Modelo do Produto",
    preco: "1.000,00",
    quantidade: 1000,
    total: "1.000.000,00"
  },
  {
    nome: "Nome do Produto Extremamente Grande",
    modelo: "Modelo do Produto",
    preco: "1.000,00",
    quantidade: 1000,
    total: "1.000.000,00"
  },
  {
    nome: "Nome do Produto Extremamente Grande",
    modelo: "Modelo do Produto",
    preco: "1.000,00",
    quantidade: 1000,
    total: "1.000.000,00"
  },
  {
    nome: "Nome do Produto Extremamente Grande",
    modelo: "Modelo do Produto",
    preco: "1.000,00",
    quantidade: 1000,
    total: "1.000.000,00"
  },
  {
    nome: "Nome do Produto Extremamente Grande",
    modelo: "Modelo do Produto",
    preco: "1.000,00",
    quantidade: 1000,
    total: "1.000.000,00"
  },
  {
    nome: "Nome do Produto Extremamente Grande",
    modelo: "Modelo do Produto",
    preco: "1.000,00",
    quantidade: 1000,
    total: "1.000.000,00"
  },
  {
    nome: "Nome do Produto Extremamente Grande",
    modelo: "Modelo do Produto",
    preco: "1.000,00",
    quantidade: 1000,
    total: "1.000.000,00"
  },
  {
    nome: "Nome do Produto Extremamente Grande",
    modelo: "Modelo do Produto",
    preco: "1.000,00",
    quantidade: 1000,
    total: "1.000.000,00"
  },
  {
    nome: "Nome do Produto Extremamente Grande",
    modelo: "Modelo do Produto",
    preco: "1.000,00",
    quantidade: 1000,
    total: "1.000.000,00"
  },
  {
    nome: "Nome do Produto Extremamente Grande",
    modelo: "Modelo do Produto",
    preco: "1.000,00",
    quantidade: 1000,
    total: "1.000.000,00"
  },
  {
    nome: "Nome do Produto Extremamente Grande",
    modelo: "Modelo do Produto",
    preco: "1.000,00",
    quantidade: 1000,
    total: "1.000.000,00"
  },
  {
    nome: "Nome do Produto Extremamente Grande",
    modelo: "Modelo do Produto",
    preco: "1.000,00",
    quantidade: 1000,
    total: "1.000.000,00"
  },
  {
    nome: "Nome do Produto Extremamente Grande",
    modelo: "Modelo do Produto",
    preco: "1.000,00",
    quantidade: 1000,
    total: "1.000.000,00"
  },
  {
    nome: "Nome do Produto Extremamente Grande",
    modelo: "Modelo do Produto",
    preco: "1.000,00",
    quantidade: 1000,
    total: "1.000.000,00"
  }
]
