export const mockOrderResume = [
  {
    title: "Total de produtos:",
    value: "1.000.000,00"
  },
  {
    title: "Serviços adicionais:",
    value: "1.000.000,00"
  },
  {
    title: "Desconto:",
    value: "1.000.000,00"
  },
  {
    title: "Frete:",
    value: "1.000.000,00"
  },
  {
    title: "Método de pagemento:",
    value: "1.000.000,00"
  }
]