const mockProductsList = [
  {
    id: 1,
    name: "Moletom com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 199.99",
    description: "Descrição do produto grande...",
    visible: false,
    categories: "Gabô",
    subCategories: "Moletons",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 2,
    name: "Camiseta com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 99.99",
    description: "Descrição do produto grande...",
    visible: true,
    categories: "Alvxaro",
    subCategories: "Camisetas",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 3,
    name: "FunkoPop com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 149.99",
    description: "Descrição do produto grande...",
    visible: false,
    categories: "Hugo Novaes",
    subCategories: "FunkoPop",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 4,
    name: "Caneca com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 39.99",
    description: "Descrição do produto grande...",
    visible: true,
    categories: "Maceió Ordinário",
    subCategories: "Canecas",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 5,
    name: "Camiseta com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 99.99",
    description: "Descrição do produto grande...",
    visible: true,
    categories: "Mano Walter",
    subCategories: "Camisetas",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 6,
    name: "Chaveiro com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 29.99",
    description: "Descrição do produto grande...",
    visible: true,
    categories: "Leuriscleia",
    subCategories: "Chaveiros",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 7,
    name: "Caneca com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 39.99",
    description: "Descrição do produto grande...",
    visible: true,
    categories: "Gabô",
    subCategories: "Canecas",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 8,
    name: "Moletom com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 199.99",
    description: "Descrição do produto grande...",
    visible: false,
    categories: "Alvxaro",
    subCategories: "Moletons",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 9,
    name: "Chaveiro com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 29.99",
    description: "Descrição do produto grande...",
    visible: true,
    categories: "Maceió Ordinário",
    subCategories: "Chaveiros",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 10,
    name: "FunkoPop com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 149.99",
    description: "Descrição do produto grande...",
    visible: false,
    categories: "Leuriscleia",
    subCategories: "FunkoPop",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 11,
    name: "Camiseta com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 79.99",
    description: "Descrição do produto grande...",
    visible: true,
    categories: "Gabô",
    subCategories: "Camisetas",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 12,
    name: "Moletom com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 179.99",
    description: "Descrição do produto grande...",
    visible: false,
    categories: "Maceió Ordinário",
    subCategories: "Moletons",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 13,
    name: "Caneca com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 59.99",
    description: "Descrição do produto grande...",
    visible: true,
    categories: "Hugo Novaes",
    subCategories: "Canecas",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 14,
    name: "FunkoPop com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 129.99",
    description: "Descrição do produto grande...",
    visible: true,
    categories: "Mano Walter",
    subCategories: "FunkoPop",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 15,
    name: "Chaveiro com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 49.99",
    description: "Descrição do produto grande...",
    visible: false,
    categories: "Alvxaro",
    subCategories: "Chaveiros",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 16,
    name: "Camiseta com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 79.99",
    description: "Descrição do produto grande...",
    visible: true,
    categories: "Leuriscleia",
    subCategories: "Camisetas",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 17,
    name: "Moletom com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 179.99",
    description: "Descrição do produto grande...",
    visible: true,
    categories: "Hugo Novaes",
    subCategories: "Moletons",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 18,
    name: "Caneca com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 59.99",
    description: "Descrição do produto grande...",
    visible: false,
    categories: "Alvxaro",
    subCategories: "Canecas",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 19,
    name: "FunkoPop com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 169.99",
    description: "Descrição do produto grande...",
    visible: true,
    categories: "Maceió Ordinário",
    subCategories: "FunkoPop",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 20,
    name: "Chaveiro com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 39.99",
    description: "Descrição do produto grande...",
    visible: true,
    categories: "Gabô",
    subCategories: "Chaveiros",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 21,
    name: "Moletom com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 189.99",
    description: "Descrição do produto grande...",
    visible: true,
    categories: "Leuriscleia",
    subCategories: "Moletons",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 22,
    name: "Camiseta com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 89.99",
    description: "Descrição do produto grande...",
    visible: false,
    categories: "Mano Walter",
    subCategories: "Camisetas",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 23,
    name: "FunkoPop com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 159.99",
    description: "Descrição do produto grande...",
    visible: false,
    categories: "Alvxaro",
    subCategories: "FunkoPop",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 24,
    name: "Caneca com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 49.99",
    description: "Descrição do produto grande...",
    visible: true,
    categories: "Mano Walter",
    subCategories: "Canecas",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 25,
    name: "Camiseta com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 89.99",
    description: "Descrição do produto grande...",
    visible: false,
    categories: "Gabô",
    subCategories: "Camisetas",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 26,
    name: "Chaveiro com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 39.99",
    description: "Descrição do produto grande...",
    visible: true,
    categories: "Maceió Ordinário",
    subCategories: "Chaveiros",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 27,
    name: "Caneca com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 49.99",
    description: "Descrição do produto grande...",
    visible: true,
    categories: "Leuriscleia",
    subCategories: "Canecas",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 28,
    name: "Moletom com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 189.99",
    description: "Descrição do produto grande...",
    visible: true,
    categories: "Maceió Ordinário",
    subCategories: "Moletons",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 29,
    name: "Chaveiro com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 49.99",
    description: "Descrição do produto grande...",
    visible: false,
    categories: "Alvxaro",
    subCategories: "Chaveiros",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
  {
    id: 30,
    name: "FunkoPop com o nome extremamente grande",
    metaName: "MetaName",
    price: "R$ 159.99",
    description: "Descrição do produto grande...",
    visible: true,
    categories: "Hugo Novaes",
    subCategories: "FunkoPop",
    image: "https://via.placeholder.com/150",
    stock: "Pré-Venda"
  },
];

export default mockProductsList
