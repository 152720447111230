export const mockOrderClient = [
  {
    title: "Username:",
    value: "@docliente"
  },
  {
    title: "Nome completo:",
    value: "Nome do Cliente Extremamente Grande"
  },
  {
    title: "Número para contato:",
    value: "+55 (13) 98935-2211"
  },
  {
    title: "E-mail:",
    value: "nomedoclienteextremamentegrande@gmail.com"
  },
  {
    title: "CPF:",
    value: "771.483.924-46"
  },
  {
    title: "Endereço de entrega:",
    value: "Rua Miguel Vieira de Novais, N 38, Centro"
  },
  {
    title: "CEP:",
    value: "CEP 57560-970"
  },
  {
    title: "Estado:",
    value: "Alagoas"
  },
  {
    title: "Cidade:",
    value: "Dois Riachos"
  },
  {
    title: "Referência:",
    value: "Referência: ao lado da padaria Pão & Mel, em frente à academia Bem-Estar"
  },
  {
    title: "Frete:",
    value: "Jadlog"
  },
  {
    title: "Data do pedido:",
    value: "31 de dez 2024"
  },
  {
    title: "Data de entrega:",
    value: "-"
  },
]

