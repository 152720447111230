import { Card, Button, Grid, Typography } from '@mui/material';
import styled from 'styled-components';
import { Timeline, TimelineItem, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';

export const TableCard = styled(Card)`
  padding: 0 22px !important;
  
  .filterIcon {
    @media (max-width: 1600px) {
      margin-right: 18px;
    }
    @media (max-width: 992px) {
      margin-right: 0px;
    }
    margin-right: 24px;
  }
`

export const OrderGridTitle = styled(Grid)`
  color: #ffffff;
  line-height: 24px;
  font-weight: 700;
  font-size: 17px;

  .orderStatus{
    display: flex;
    white-space: nowrap;
    gap: 15px;
    font-weight: 500;
    font-size: 12px;
  } 

  .orderDateStatus {
    font-weight: 500;
    font-size: 12px;
    color: #8F8F8F;
  }
`

export const OrderProductsGrid = styled(Grid)`
  padding-top: 5px;

  @media (max-width: 1300px){
    flex-direction: column !important;
  }

  :hover {
    cursor: pointer;
  }

  .productPhoto {
    border-radius: 12px;
  }
`

export const OrderTextGrid = styled(Grid)`
  padding-top: 5px;
  font-weight: 700;
  color: #ffffff;
  font-size: 12px;
  line-height: 24px;

  .name{
    line-height: 20px;
    font-size: 14px;
  }
  .model{
    color: #A0AEC0;
    font-weight: 400;
    line-height: 15px;
  }
  .infoOrderClient{
    color: #8F8F8F;
  }
  .totalResume{
    font-size: 17px;  
  }
  
  @media (max-width: 1440px) {
    font-size: 10px;

    .name{
      font-size: 12px;
    }
  }
`

export const ProductPhoto = styled(Grid)`
  .photoContainer {
    overflow: hidden;
    border-radius: 10px;
    width: 45px;
    height: 50px;
    margin-top: 5px;

    img {
      width: 100%;
      height: 100%;
      text-align: center;
      object-fit: cover;
      color: transparent;
      text-indent: 10000px;
      height: auto;
    }
  }
`

export const EditButtonGrid = styled(Grid)`
`

export const EditButton = styled(Button)`
  width: 41px !important;
  min-width: 30px !important;
  height: 41px !important;
  border-radius: 12px !important;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 100%) !important;
`

export const StatusBadgeGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  font-weight: 500;
  font-size: 13px;
  padding: 0 10px;
  border-radius: 15px;
`

export const ResumeBadgeGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  font-weight: 500;
  font-size: 13px;
  padding: 0 10px;
  border-radius: 15px;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 100%)
`

export const OrderResumeTotal = styled(Grid)`
  @media (max-width: 1300px){
    padding-bottom: 20px;
  }
`

export const DeliveryGrid = styled(Grid)`
  overflow: auto;
  display:flex;
  flex-wrap: nowrap !important;
  
  @media (max-width: 1900px){
    padding-bottom: 20px;
  }
`

export const DeliveryTimeline = styled(Timeline)`
  display: flex;
  flex-direction: row !important;
  padding-top: 0 !important;
`

export const DeliveryTimelineItem = styled(TimelineItem)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center
`

export const DeliveryTimelineContent = styled(TimelineContent)`
  text-align: center;
  padding: 0 !important;
`

export const DeliveryTimelineDot = styled(TimelineDot)`
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  
  &.enviado {
    background: linear-gradient(180deg, #FB8404 0%, #FE1E14 100%) border-box;
    border: none;
  }

  &.realizado, &.pago {
    background: linear-gradient(#000000, #000000) padding-box, linear-gradient(180deg, #FB8404 0%, #FE1E14 100%) border-box;
  }

  &:not(.enviado):not(.realizado):not(.pago) {
    border-color: rgba(255, 255, 255, 0.6);
    border-width: 2px;
    border-style: solid;
    background: none;
  }
`;

export const DeliveryTypography = styled(Typography)`
  .deliveryStep{
    font-size: 12px;
  }
  .deliveryDate{
    font-size: 10px;
    color: #8F8F8F;
  }
`

export const DeliveryTimelineConnector = styled(TimelineConnector)`
  position: relative;
  top: 50px;
  left: 15px;
  width: 100px !important;
  height: 2px;

  &.realizado, &.pago {
    background-image: linear-gradient(180deg, #FB8404 0%, #FE1E14 100%);
  }

  &:not(.realizado):not(.pago) {
    background-color: rgba(255, 255, 255, 0.6);
  }

  @media (max-width: 1900px){
    top: 40px;
  }
`;