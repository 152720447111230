import styled from "styled-components";
import { Button, Grid, TextField, Card } from "@mui/material";
import TimelineList from "defaultComponents/Timeline/TimelineList";
import { Link } from "react-router-dom";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";

export const EditButton = styled(Button)`
  width: 41px !important;
  min-width: 30px !important;
  height: 41px !important;
  border-radius: 12px !important;
  background: linear-gradient(180deg, #fb8404 0%, #fe1e14 100%) !important;
`;

export const Input = styled(TextField)`
  background-color: transparent;
  border-radius: 10px;
  min-height: 40px;
`;

export const ScheduleList = styled(TimelineList)`
  padding: 0 22px !important;
  height: 100% !important;
`;

export const GraphMainContainer = styled(Grid)`
  .positive {
    color: #65efad;
  }
  .negative {
    color: #d7007b;
  }
  .value {
    font-size: 26px;
    font-weight: 700;
  }
  .percentage {
    font-size: 16px;
    align-self: center;
  }
  .title {
    opacity: 0.75;
    font-size: 16px;
  }
  .subtitle {
    font-size: 14px;
    opacity: 0.75;
  }
  .cardTitle {
    font-size: 18px;
    font-weight: 600;
  }
  .instagram {
    background: linear-gradient(180deg, #fb8404 0%, #fe1e14 100%);
  }
  .tiktok {
    background: linear-gradient(180deg, #f8df00 0%, #6a4000 100%);
  }
  .twitter {
    background: linear-gradient(180deg, #00ace2 0%, #003e52 100%);
  }
  .youtube {
    background: linear-gradient(180deg, #ff0000 0%, #5e4343 100%);
  }
  .spotify {
    background: linear-gradient(180deg, #00d554 0%, #003e52 100%);
  }
  .emptyBar {
    background: linear-gradient(180deg, #999999 0%, #343434 100%);
  }
`;

export const TitleGrid = styled(Grid)`
  margin-top: 30px;
  font-size: 28px;
  font-weight: 500;
`;

export const RankingCard = styled(Card)`
  padding: 32px 0 20px 22px !important;
  min-height: 300px;
  height: 100% !important;
`;

export const ValuesGrid = styled(Grid)`
  gap: 10px;
`;

export const CardGrid = styled(Grid)`
  font-size: 14px;
  overflow: auto;
  .apexcharts-toolbar {
    display: none;
  }
  .subtitle {
    font-size: 14px;
    opacity: 0.75;
  }
  .cardTitle {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const SocialGrid = styled(Grid)`
  border-radius: 5px;
  color: #fff;
  justify-content: center;
  text-align: center;
  padding: 5px;
  min-width: 120px;
  cursor: pointer;
`;

export const SocialNameGrid = styled(Grid)`
  font-size: 15px;
  font-weight: 400;
`;

export const BoardsGrid = styled(Grid)`
  justify-content: end;

  @media (max-width: 1200px) {
    justify-content: center;
  }
`;

export const BarContainer = styled(Grid)`
  margin-top: 5px;

  .bar {
    height: 8px;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    display: flex;
    div {
      height: 100%;
    }
    .instagram {
      background-color: #fc6009;
    }
    .tiktok {
      background-color: #f8df00;
    }
    .twitter {
      background-color: #33ccff;
    }
    .youtube {
      background-color: #ff0000;
    }
    .spotify {
      background-color: #00d554;
    }
  }
`;

export const ProgressGrid = styled(Grid)`
  border-radius: 10px;
  background: #2b0b03;
  backdrop-filter: blur(2px);
  color: #fff;
  padding: 10px 15px;
  min-width: 250px;

  @media (max-width: 992px) {
    width: 100%;
  }

  svg {
    align-self: center;
  }

  .followers {
    font-size: 16px;
  }
`;

export const TooltipBase = styled.div`
  /*   border-radius: 5px;
  box-shadow: 2px 2px 6px -4px #999;
  cursor: default;
  font-size: 14px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  z-index: 12;
  transition: .15s ease all; */
  top: -70px;
  z-index: 9999;
  color: #fff;
  background: rgba(30, 30, 30, 0.8);
  border-radius: 5px;
  box-shadow: 2px 2px 6px -5px #999;

  @supports (hanging-punctuation: first) and (font: -apple-system-body) and
    (-webkit-appearance: none) {
    top: 60px;
  }

  .tooltip-title {
    font-size: 12px;
    background: rgba(0, 0, 0, 0.7);
    border-bottom: 1px solid #333;
    padding: 6px;
    margin-bottom: 4px;
  }

  .tooltip-row {
    padding: 0 10px;
    text-align: left;
    justify-content: left;
    align-items: center;
    padding-bottom: 4px;
    display: flex;
    font-size: 14px;
  }

  .tooltip-marker {
    width: 12px;
    height: 12px;
    position: relative;
    top: 0;
    margin-right: 10px;
    border-radius: 50%;
  }
`;

export const MainContainer = styled(Grid)`
  .subTitle {
    font-size: 14px;
    color: #9c9a9a;
  }
`;

export const GraphGrid = styled(Grid)`
  min-height: 310px;
`;

export const BusinessConversionGrid = styled(Grid)`
  min-height: 450px;
`;

export const CommercialPipelineGrid = styled(Grid)`
  && {
    max-height: 120px;
    max-width: 300px;
    background-color: #101010;
    align-items: center;
    padding: 20px 0;
    border-radius: 12px;
    cursor: pointer;
    margin-top: 15px;
  }
`;

export const UsersPipelineGrid = styled(Grid)`
  && {
    align-items: center;
    cursor: pointer;
    margin-top: 15px;
  }
`;

export const RouterLink = styled(Link)`
  color: inherit;
  text-decoration: inherit;
`;

export const OrderInfoTitleGrid = styled(Grid)`
  color: #ffffff;
  line-height: 24px;
  font-weight: 700;
  font-size: 17px;
`;

export const OrderInfoGrid = styled(Grid)`
  font-size: 14px;

  .infoKey {
    color: #fff;
    font-weight: 700;
  }

  .infoValue {
    color: #8f8f8f;
    font-weight: 500;
  }
`;

export const SocialsGrid = styled(Grid)`
  gap: 20px;

  @media (max-width: 992px) {
    flex-wrap: wrap !important;
    justify-content: center;
  }

  .active {
    img {
      cursor: pointer;
    }
  }

  .disabled {
    filter: grayscale(1);
  }
`;

export const OrderProductsGrid = styled(Grid)`
  padding-top: 5px;

  @media (max-width: 1300px) {
    flex-direction: column !important;
  }

  :hover {
    cursor: pointer;
  }

  .productPhoto {
    border-radius: 12px;
  }
`;

export const OrderTextGrid = styled(Grid)`
  padding-top: 5px;
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
  line-height: 24px;

  /*   @media (min-width: 1300px) {
    margin-left: 20px !important;
  } */
  .name {
    line-height: 20px;
    font-weight: 700;
  }
  .model {
    color: #a0aec0;
    font-size: 12px;
    line-height: 15px;
  }
  .resume,
  .client {
    opacity: 0.85;
  }
  .totalResume {
    font-size: 17px;
  }
`;

export const ProductPhoto = styled(Grid)`
  .photoContainer {
    overflow: hidden;
    border-radius: 10px;
    width: 45px;
    height: 50px;
    margin-top: 5px;

    img {
      width: 100%;
      height: 100%;
      text-align: center;
      object-fit: cover;
      color: transparent;
      text-indent: 10000px;
      height: auto;
    }
  }
`;

export const TableGrid = styled(Grid)`
  margin-top: 20px;
`;

export const RedirectButtonGrid = styled(Grid)`
  position: absolute;
  right: 0;
  right: 20px;
  top: 20px;
  z-index: 1;
`;

export const InfluencerProfileButtonGrid = styled(Grid)`

  p {
    align-self: center;
    font-size: 14px;
  }
`;

export const DisabledButton = styled(ButtonCustom)`
  width: 95% !important;
  height: 100% !important;
  border-radius: 12px !important;
  border: 0.1px solid #FFFFFF80 !important;
  .subTitle {
    font-size: 18px;
    font-weight: 600;
  }

  .info {
    font-size: 12px;
    font-weight: 500;
  }

  &.Mui-disabled {
    background: #101010;
    color: #343434;
  }
`

export const InputGrid = styled(Grid)`
  gap: 20px;
  .MuiOutlinedInput-input {
    color: #FFFFFF;
  }
  .MuiInputBase-root.Mui-focused {
    box-shadow: 0px 0px 10px #FC6508;
    svg {
      color: #E22B00;
    }
    .MuiOutlinedInput-input {
      color: #E22B00;
    }
  }
  .MuiAutocomplete-endAdornment {
    top: 5px !important;
    transform: none !important;
  }
  .MuiAutocomplete-root .MuiAutocomplete-input {
    padding-top: 0 !important;
  }
  .MuiAutocomplete-root .MuiOutlinedInput-root {
    padding-top: 5px !important;
  }
  .MuiAutocomplete-popper {
    background-color: #0E0E0E;
    border: 1px solid;
  }
  .MuiFormHelperText-root{
    margin-top: 5px;
  }
  & .MuiOutlinedInput-root.Mui-focused{
    & > fieldset {
      border-color: transparent;
    }
  }
  .MuiPaper-root-MuiAutocomplete-paper {
    border-radius: 5px !important;
  }
  .MuiPaper-root{
    border-radius: 5px !important;
  }
  svg {
    color: white;
    transform: rotateY(180deg);
  }
`

export const BorderedInputGrid = styled(InputGrid)`
  .MuiOutlinedInput-input {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 18px !important;
  }
  .MuiOutlinedInput-notchedOutline {
    height: 45px;
    border-radius: 10px;
    border: 0.4px solid;
    border-color: rgba(255, 255, 255, 0.50) !important;
  }
  .MuiInputBase-root.Mui-focused {
    box-shadow: none;
    svg {
      color: #fff;
    }
    .MuiOutlinedInput-input {
      color: #fff;
    }
  }
`

export const AffiliateFormGrid = styled(Grid)`
  margin-top: 20px;
  
  p {
    font-size: 18px;
  }

  .explanationText {
    font-size: 12px;
    color: #8f8f8f;
    align-self: center;
  }
`