const mockClientOrders = [
  {
    numero: "100001",
    data: "23/12/2024 às 14:19",
    afiliado: "Gabô",
    total: "R$ 1.000.000,00",
    formaPagamento: "Débito",
    itens: 100
  },
  {
    numero: "100002",
    data: "23/12/2024 às 14:19",
    afiliado: "Alvxaro",
    total: "R$ 1.000.000,00",
    formaPagamento: "Crédito",
    itens: 100
  },
  {
    numero: "100003",
    data: "23/12/2024 às 14:19",
    afiliado: "Gabô",
    total: "R$ 1.000.000,00",
    formaPagamento: "Pix",
    itens: 100
  },
  {
    numero: "100004",
    data: "23/12/2024 às 14:19",
    afiliado: "Alvxaro",
    total: "R$ 1.000.000,00",
    formaPagamento: "Débito",
    itens: 100
  },
  {
    numero: "100005",
    data: "23/12/2024 às 14:19",
    afiliado: "Gabô",
    total: "R$ 1.000.000,00",
    formaPagamento: "Crédito",
    itens: 100
  },
  {
    numero: "100006",
    data: "23/12/2024 às 14:19",
    afiliado: "Alvxaro",
    total: "R$ 1.000.000,00",
    formaPagamento: "Pix",
    itens: 100
  },
  {
    numero: "100007",
    data: "23/12/2024 às 14:19",
    afiliado: "Gabô",
    total: "R$ 1.000.000,00",
    formaPagamento: "Débito",
    itens: 100
  },
  {
    numero: "100008",
    data: "23/12/2024 às 14:19",
    afiliado: "Alvxaro",
    total: "R$ 1.000.000,00",
    formaPagamento: "Crédito",
    itens: 100
  },
  {
    numero: "100009",
    data: "23/12/2024 às 14:19",
    afiliado: "Gabô",
    total: "R$ 1.000.000,00",
    formaPagamento: "Pix",
    itens: 100
  },
  {
    numero: "100010",
    data: "23/12/2024 às 14:19",
    afiliado: "Alvxaro",
    total: "R$ 1.000.000,00",
    formaPagamento: "Débito",
    itens: 100
  }
]

export default mockClientOrders