import styled from "styled-components";
import { Button, Grid, TextField } from "@mui/material";
import Card from "@mui/material/Card";

export const TableCard = styled(Card)`
  padding: 0 22px !important;
  
  .filterIcon {
    @media (max-width: 1600px) {
      margin-right: 18px;
    }
    @media (max-width: 992px) {
      margin-right: 0px;
    }
    margin-right: 24px;
  }
`

export const InfoTableGrid = styled(Grid)`
  flex-wrap: nowrap !important;

  .client {
    font-size: 16px;
    color: #FFFFFF;
    font-weight: 700;
  }
`

export const MailButton = styled(Button)`
  width: 41px !important;
  min-width: 30px !important;
  height: 41px !important;
  border-radius: 12px !important;
  background: linear-gradient(180deg, #fb8404 0%, #fe1e14 100%) !important;
`;

export const CardGrid = styled(Grid)`
  font-size: 14px;
  overflow: auto;
  .apexcharts-toolbar {
    display: none;
  }
  .subtitle {
    font-size: 14px;
    opacity: 0.75;
  }
  .cardTitle {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const ClientFormGrid = styled(Grid)`
  margin-top: 20px;
  
  p {
    font-size: 18px;
  }

  .explanationText {
    font-size: 12px;
    color: #8f8f8f;
    align-self: center;
  }
`

export const InputGrid = styled(Grid)`
  gap: 20px;
  .MuiOutlinedInput-input {
    color: #FFFFFF;
  }
  .MuiInputBase-root.Mui-focused {
    box-shadow: 0px 0px 10px #FC6508;
    svg {
      color: #E22B00;
    }
    .MuiOutlinedInput-input {
      color: #E22B00;
    }
  }
  .MuiAutocomplete-endAdornment {
    top: 5px !important;
    transform: none !important;
  }
  .MuiAutocomplete-root .MuiAutocomplete-input {
    padding-top: 0 !important;
  }
  .MuiAutocomplete-root .MuiOutlinedInput-root {
    padding-top: 5px !important;
  }
  .MuiAutocomplete-popper {
    background-color: #0E0E0E;
    border: 1px solid;
  }
  .MuiFormHelperText-root{
    margin-top: 5px;
  }
  & .MuiOutlinedInput-root.Mui-focused{
    & > fieldset {
      border-color: transparent;
    }
  }
  .MuiPaper-root-MuiAutocomplete-paper {
    border-radius: 5px !important;
  }
  .MuiPaper-root{
    border-radius: 5px !important;
  }
  svg {
    color: white;
    transform: rotateY(180deg);
  }
`

export const BorderedInputGrid = styled(InputGrid)`
  .MuiOutlinedInput-input {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 18px !important;
  }
  .MuiOutlinedInput-notchedOutline {
    height: 45px;
    border-radius: 10px;
    border: 0.4px solid;
    border-color: rgba(255, 255, 255, 0.50) !important;
  }
  .MuiInputBase-root.Mui-focused {
    box-shadow: none;
    svg {
      color: #fff;
    }
    .MuiOutlinedInput-input {
      color: #fff;
    }
  }
`

export const Input = styled(TextField)`
  background-color: transparent;
  /* border: 0.4px solid rgba(255, 255, 255, 0.50) !important; */
  border-radius: 10px;
  width: 100%;
  min-height: 40px;
`