import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import VuiBox from "components/VuiBox";
import { Card, Grid, FormControl, FormControlLabel, RadioGroup, Radio } from "@mui/material";
import * as S from "./style";
import { IoEllipsisVertical, IoArrowDown, IoArrowUp } from "react-icons/io5";
import { useState, useEffect, useContext } from "react";
import { getMarketingPageData } from "utils/requests/project";
import LineChart from "defaultComponents/Charts/LineCharts/LineChart";
import BarChart from "defaultComponents/Charts/BarCharts/BarChart";
import { capitalize } from "utils/utils";
import { TokenContext } from "context/TokenContext";
import pencil from "../../../../assets/icons/pencil.png";
import instagram from "../../../../assets/icons/socials/instagram.png";
import youtube from "../../../../assets/icons/socials/youtube.png";
import tiktok from "../../../../assets/icons/socials/tiktok.png";
import twitter from "../../../../assets/icons/socials/twitter.png";
import spotify from "../../../../assets/icons/socials/spotify.png";
import Table from "defaultComponents/Tables/Table";
import VuiAvatar from "components/VuiAvatar";
import { Link } from "react-router-dom";
import arrowRightUp from "assets/icons/arrow-right-up.svg";
import StateRanking from "./StateRanking/StateRanking";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import { RiAccountBoxFill, RiAccountBoxLine, RiMailFill } from "react-icons/ri";
import Modal from "components/Modal/Modal";
import EditAffiliate from "./ModalContent/EditAffiliate";

const Avatar = ({ img, theme, children, ...rest }) => (
  <VuiAvatar
    src={img}
    size="md"
    {...rest}
    sx={({ borders: { borderWidth }, palette: { light }, functions: { rgba } }) => ({
      borderRadius: "15px",
      position: "relative",
    })}
  >
    {children}
  </VuiAvatar>
);

const AffiliateDetails = () => {
  const { decodedToken } = useContext(TokenContext);
  const role = decodedToken?.type;

  const [projectHomeData, setProjectHomeData] = useState();
  const [chartData, setChartData] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [rankingFilter, setRankingFilter] = useState('Hoje');
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [chartOptions, setChartOptions] = useState({
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      theme: "dark",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      labels: {
        style: {
          colors: "#c8cfca",
          fontSize: "10px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#c8cfca",
          fontSize: "10px",
        },
      },
    },
    legend: {
      show: false,
    },
    grid: {
      strokeDashArray: 5,
      borderColor: "#56577A",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0,
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        inverseColors: true,
        opacityFrom: 0.8,
        opacityTo: 0,
        stops: [],
      },
      colors: ["#FC6009", "#F8DF00", "#33CCFF"],
    },
    colors: ["#FC6009", "#F8DF00", "#33CCFF"],
  });
  const [barChartData, setBarChartData] = useState([]);
  const [barChartOptions, setBarChartOptions] = useState({
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        barHeight: "85%",
        distributed: true,
        horizontal: true,
        borderRadius: 4,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      colors: ["transparent"],
      width: 5,
    },
    colors: ["#007093", "#00752D", "#A58200", "#00ACE2", "#9D171C", "#6D788D"],
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#fff"],
      },
      formatter: (value) => {
        return (
          "R$ " +
          Number((value / 100).toFixed(2)).toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        );
      },
    },
    labels: {
      style: {
        colors: "#c8cfca",
        fontSize: "10px",
      },
    },
    xaxis: {
      categories: ["Campanhas", "Santander", "Palco", "Eventos", "Livros", "Audio Visual"],
      labels: {
        style: {
          colors: "#c8cfca",
          fontSize: "10px",
        },
        formatter: (value) => {
          return Number((value / 100).toFixed(2)).toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#c8cfca",
          fontSize: "12px",
        },
      },
    },
    tooltip: {
      enabled: false,
      theme: "dark",
      x: {
        show: false,
      },
      y: {
        show: false,
        title: {
          formatter: function () {
            return "";
          },
        },
      },
    },
  });

  const fetchData = async () => {
    const data = await getMarketingPageData();
    if (!data.message) {
      setProjectHomeData(data);
      setChartData(data?.lastMonthProjects?.graphData?.data);
      setChartOptions((chartOptions) => ({
        ...chartOptions,
        xaxis: {
          ...chartOptions.xaxis,
          categories: data?.lastMonthProjects?.graphData?.labels,
        },
      }));
      setBarChartData([
        {
          data: data?.salesByType?.data,
        },
      ]);
      setBarChartOptions((chartOptions) => ({
        ...chartOptions,
        xaxis: {
          ...chartOptions.xaxis,
          categories: data?.salesByType?.labels.map((label) => capitalize(label.toLowerCase())),
        },
      }));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const test = [
    { name: "Entradas", filter: "ENTRADA" },
    { name: "Resumo", filter: "SAIDA" },
    { name: "Lista de Pedidos", filter: "TRANSFERENCIA" },
  ];

  const mockProducts = [
    {
      nome: "Nome do Produto Extremamente Grande",
      modelo: "Modelo do Produto",
      preco: "1.000,00",
      quantidade: 1000,
      total: "1.000.000,00",
    },
    {
      nome: "Nome do Produto Extremamente Grande",
      modelo: "Modelo do Produto",
      preco: "1.000,00",
      quantidade: 1000,
      total: "1.000.000,00",
    },
    {
      nome: "Nome do Produto Extremamente Grande",
      modelo: "Modelo do Produto",
      preco: "1.000,00",
      quantidade: 1000,
      total: "1.000.000,00",
    },
    {
      nome: "Nome do Produto Extremamente Grande",
      modelo: "Modelo do Produto",
      preco: "1.000,00",
      quantidade: 1000,
      total: "1.000.000,00",
    },
    {
      nome: "Nome do Produto Extremamente Grande",
      modelo: "Modelo do Produto",
      preco: "1.000,00",
      quantidade: 1000,
      total: "1.000.000,00",
    },
  ];

  const handleRadioChange = (event) => {
    setRankingFilter(event.target.value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Grid container flexDirection="column" gap="16px">
          {(role === "ADMINISTRATOR" || role === "H_MARKETING") && (
            <Grid container spacing="18px">
              <S.CardGrid item xs={12} lg={7} xl={8} sx={{ minHeight: "400px" }}>
                <Card>
                  <Grid container flexDirection="column">
                    <Grid container>
                      <Grid item xl={6}>
                        <p className="cardTitle">Nome</p>
                        <Grid container>
                          <p className="subtitle">@username</p>
                        </Grid>
                      </Grid>
                      <Grid container item xl={6} justifyContent="flex-end" gap="10px">
                        <ButtonCustom
                          sx={{
                            width: "200px",
                            height: "41px !important",
                            borderRadius: "12px !important",
                          }}
                        >
                          <S.InfluencerProfileButtonGrid
                            container
                            gap="5px"
                            justifyContent="center"
                          >
                            <p>Perfil do Influencer</p>
                            <RiAccountBoxLine size="26px" />
                          </S.InfluencerProfileButtonGrid>
                        </ButtonCustom>
                        <S.EditButton style={{ padding: 0 }}>
                          <RiMailFill size="24px" color="#fff" />
                        </S.EditButton>
                      </Grid>
                    </Grid>
                    <S.AffiliateFormGrid
                      container
                      flexDirection="column"
                      sx={{ minHeight: "270px" }}
                      gap="30px"
                      justifyContent="center"
                    >
                      <Grid container>
                        <Grid item lg={3} xl={3}>
                          <p>Link de Afiliado:</p>
                        </Grid>
                        <S.BorderedInputGrid item lg={8} xl={7} container flexDirection="column">
                          <S.Input
                            name="description"
                            id="description"
                            placeholder="Insira o link de afiliado"
                          />
                        </S.BorderedInputGrid>
                      </Grid>
                      <Grid container>
                        <Grid item lg={3} xl={3}>
                          <p>Cupom de Afiliado:</p>
                        </Grid>
                        <S.BorderedInputGrid item lg={8} xl={5} container flexDirection="column">
                          <S.Input
                            name="description"
                            id="description"
                            placeholder="Insira o cupom de afiliado"
                          />
                        </S.BorderedInputGrid>
                      </Grid>
                      <Grid container>
                        <Grid item lg={3} xl={3}>
                          <p>Comissão:</p>
                        </Grid>
                        <S.BorderedInputGrid item lg={8} xl={5} container flexDirection="column">
                          <S.Input
                            name="description"
                            id="description"
                            placeholder="Insira o percentual de comissão"
                          />
                        </S.BorderedInputGrid>
                        <Grid container item xl={4} pl="10px">
                          <p className="explanationText">{"(01 - 100%)"}</p>
                        </Grid>
                      </Grid>
                    </S.AffiliateFormGrid>
                    <Grid container justifyContent="flex-end" gap="10px">
                      {/*                      <ButtonCustom
                        label="Cancelar"
                        sx={{
                          background: "linear-gradient(180deg, #818181 0%, #2a2020 155.15%)",
                          width: "100px",
                        }}
                      />*/}
                      <ButtonCustom label="Salvar Alterações" sx={{ width: "150px" }} />
                    </Grid>
                  </Grid>
                </Card>
              </S.CardGrid>
              <S.CardGrid item xs={12} lg={5} xl={4} sx={{ padding: 0 }}>
                <Card sx={{ paddingY: 0 }}>
                  <Grid display="flex" justifyContent="space-between" alignItems="center" mb="10px">
                    <S.OrderInfoTitleGrid>
                      <p>Informações do Afiliado</p>
                    </S.OrderInfoTitleGrid>
                    <Grid>
                      <S.EditButton onClick={() => setOpenEditModal(true)}>
                        <img src={pencil} />
                      </S.EditButton>
                    </Grid>
                  </Grid>
                  <S.OrderInfoGrid container flexDirection="column" gap="20px">
                    <S.OrderInfoGrid container justifyContent="space-between">
                      <p className="infoKey">Username:</p>
                      <p className="infoValue">@docliente</p>
                    </S.OrderInfoGrid>
                    <Grid container justifyContent="space-between">
                      <p className="infoKey">Nome Completo:</p>
                      <p className="infoValue">Nome do Afiliado Completo da S.</p>
                    </Grid>
                    <Grid container justifyContent="space-between">
                      <p className="infoKey">Número de Contato:</p>
                      <p className="infoValue">{"+55 (13) 98935-2211"}</p>
                    </Grid>
                    <Grid container justifyContent="space-between">
                      <p className="infoKey">Email:</p>
                      <p className="infoValue">email_do_afiliadoss@gmail.com</p>
                    </Grid>
                    <Grid container justifyContent="space-between">
                      <p className="infoKey">CPF:</p>
                      <p className="infoValue">111.784.664-90</p>
                    </Grid>
                    <Grid container justifyContent="space-between">
                      <p className="infoKey">Cidade:</p>
                      <p className="infoValue">Maceió</p>
                    </Grid>
                    <Grid container justifyContent="space-between">
                      <p className="infoKey">Estado:</p>
                      <p className="infoValue">Alagoas</p>
                    </Grid>
                    <Grid container justifyContent="space-between">
                      <p className="infoKey">Data de Afiliação:</p>
                      <p className="infoValue">21/01/2025</p>
                    </Grid>
                    <Grid container justifyContent="space-between">
                      <p className="infoKey">Redes Sociais:</p>
                      <Grid display="flex" justifyContent="space-between">
                        <S.SocialsGrid display="flex" item justifyContent="center">
                          {true ? (
                            <Link to={`https://www.instagram.com/`} target="_blank">
                              <img src={instagram} height="18px" draggable="false" />
                            </Link>
                          ) : (
                            <img
                              src={instagram}
                              height="18px"
                              draggable="false"
                              className="disabled"
                            />
                          )}
                          {true ? (
                            <Link to={`https://www.youtube.com/@`} target="_blank">
                              <img src={youtube} height="18px" draggable="false" />
                            </Link>
                          ) : (
                            <img
                              src={youtube}
                              height="18px"
                              draggable="false"
                              className="disabled"
                            />
                          )}
                          {true ? (
                            <Link
                              to={`https://www.tiktok.com/@`}
                              target="_blank"
                              className={
                                /*affiliate?.instagram?.username*/ true ? "active" : "disabled"
                              }
                            >
                              <img src={tiktok} height="18px" draggable="false" />
                            </Link>
                          ) : (
                            <img
                              src={tiktok}
                              height="18px"
                              draggable="false"
                              className="disabled"
                            />
                          )}
                          {true ? (
                            <Link
                              to={`https://www.twitter.com/`}
                              target="_blank"
                              className={
                                /*affiliate?.instagram?.username*/ true ? "active" : "disabled"
                              }
                            >
                              <img src={twitter} height="18px" draggable="false" />
                            </Link>
                          ) : (
                            <img
                              src={twitter}
                              height="18px"
                              draggable="false"
                              className="disabled"
                            />
                          )}
                        </S.SocialsGrid>
                      </Grid>
                    </Grid>
                  </S.OrderInfoGrid>
                </Card>
              </S.CardGrid>
            </Grid>
          )}
          <S.GraphMainContainer container spacing="18px">
            <S.CardGrid item xs={12} lg={6} xl={7} sx={{ minHeight: "400px" }}>
              <Card>
                <Grid container flexDirection="column">
                  <Grid container>
                    <Grid item xs={12} md={12} xl={3}>
                      <p className="cardTitle">Últimas Vendas</p>
                    </Grid>
                    <S.BoardsGrid container item xs={12} md={12} xl={9} gap="10px">
                      <S.RedirectButtonGrid>
                        <Link to={"/"}>
                          <S.EditButton>
                            <img src={arrowRightUp} />
                          </S.EditButton>
                        </Link>
                      </S.RedirectButtonGrid>
                    </S.BoardsGrid>
                  </Grid>
                  <S.TableGrid container>
                    <Table
                      columns={[
                        { name: "nome do produto", align: "left", width: "45%" },
                        { name: "preço", align: "left" },
                        { name: "quantidade", align: "center" },
                        { name: "total", align: "center" },
                      ]}
                      rows={mockProducts.map((product) => {
                        return {
                          "nome do produto": (
                            <S.OrderProductsGrid container flexDirection="row">
                              <Grid container item xl={1.5} lg={3} md={2}>
                                <S.ProductPhoto>
                                  <Avatar src={product?.avatar ? product?.avatar : ""} />
                                </S.ProductPhoto>
                              </Grid>
                              <S.OrderTextGrid
                                container
                                flexDirection="column"
                                item
                                xl={8.5}
                                lg={7}
                                md={8}
                              >
                                <p className="name">{product.nome}</p>
                                <p className="model">{product.modelo}</p>
                              </S.OrderTextGrid>
                            </S.OrderProductsGrid>
                          ),
                          preço: (
                            <S.OrderTextGrid container flexDirection="column">
                              <p className="price">R$ {product.preco}</p>
                            </S.OrderTextGrid>
                          ),
                          quantidade: (
                            <S.OrderTextGrid container justifyContent="center">
                              <p className="quantity">{product.quantidade}</p>
                            </S.OrderTextGrid>
                          ),
                          total: (
                            <S.OrderTextGrid container justifyContent="center">
                              <p className="total">R$ {product.total}</p>
                            </S.OrderTextGrid>
                          ),
                        };
                      })}
                    />
                  </S.TableGrid>
                </Grid>
              </Card>
            </S.CardGrid>
            <StateRanking stateList={projectHomeData?.influencerRanking ?? []} openFilterModalFn={() => setOpenFilterModal(true)} />
          </S.GraphMainContainer>
          <S.GraphMainContainer container spacing="18px">
            <S.CardGrid item xs={12} sm={12} lg={12} xl={12} sx={{ minHeight: "400px" }}>
              <Card>
                <Grid container flexDirection="column">
                  <Grid container>
                    <Grid item xs={4} sm={4} md={4} xl={4}>
                      <p className="cardTitle">Faturamento Geral</p>
                    </Grid>
                    <S.BoardsGrid container item xs={8} sm={8} md={8} xl={8} gap="10px">
                      <S.SocialGrid className="instagram" item xl={2}>
                        <S.SocialNameGrid container justifyContent="center" gap="2px">
                          <p>Total em Vendas</p>
                        </S.SocialNameGrid>
                        <Grid container justifyContent="center">
                          <p>
                            {"R$ "}
                            {projectHomeData?.lastMonthProjects?.changeValues?.ongoing?.value ?? 0}
                          </p>
                          {projectHomeData?.lastMonthProjects?.changeValues?.ongoing?.up ? (
                            <IoArrowUp color="#65EFAD" size="22px" />
                          ) : (
                            <IoArrowDown color="#D7007B" size="22px" />
                          )}
                        </Grid>
                      </S.SocialGrid>
                      <S.SocialGrid className="tiktok" item xl={2}>
                        <S.SocialNameGrid container justifyContent="center" gap="2px">
                          <p>Link</p>
                        </S.SocialNameGrid>
                        <Grid container justifyContent="center">
                          <p>
                            {"R$ "}
                            {projectHomeData?.lastMonthProjects?.changeValues?.created?.value ?? 0}
                          </p>
                          {projectHomeData?.lastMonthProjects?.changeValues?.created?.up ? (
                            <IoArrowUp color="#65EFAD" size="22px" />
                          ) : (
                            <IoArrowDown color="#D7007B" size="22px" />
                          )}
                        </Grid>
                      </S.SocialGrid>
                      <S.SocialGrid className="twitter" item xl={2}>
                        <S.SocialNameGrid container justifyContent="center" gap="2px">
                          <p>Cupom</p>
                        </S.SocialNameGrid>
                        <Grid container justifyContent="center">
                          <p>
                            {"R$ "}
                            {projectHomeData?.lastMonthProjects?.changeValues?.finished?.value ?? 0}
                          </p>
                          {projectHomeData?.lastMonthProjects?.changeValues?.finished?.up ? (
                            <IoArrowUp color="#65EFAD" size="22px" />
                          ) : (
                            <IoArrowDown color="#D7007B" size="22px" />
                          )}
                        </Grid>
                      </S.SocialGrid>
                    </S.BoardsGrid>
                  </Grid>
                  <Grid sx={{ minHeight: "310px" }}>
                    <LineChart
                      key={chartData}
                      lineChartData={chartData}
                      lineChartOptions={chartOptions}
                    />
                  </Grid>
                </Grid>
              </Card>
            </S.CardGrid>
          </S.GraphMainContainer>
        </Grid>
      </VuiBox>
      <Modal open={openEditModal} onClose={() => setOpenEditModal(false)}>
        <EditAffiliate />
      </Modal>
      <Modal open={openFilterModal} onClose={() => setOpenFilterModal(false)} size="sm">
        <Grid container flexDirection="column" mt='30px'>
          <FormControl>
            <RadioGroup value={rankingFilter} onChange={handleRadioChange}>
              <Grid container flexDirection="column" gap="15px">
                <FormControlLabel
                  value="Hoje"
                  control={<Radio />}
                  label="Hoje"
                />
                <FormControlLabel
                  value="Última Semana"
                  control={<Radio />}
                  label="Última Semana"
                />
                <FormControlLabel
                  value="Último mês"
                  control={<Radio />}
                  label="Último mês"
                />
                <FormControlLabel
                  value="Este ano"
                  control={<Radio />}
                  label="Este ano"
                />
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>
      </Modal>
    </DashboardLayout>
  );
};

export default AffiliateDetails;
