const mockClientsList = [
  {
    id: 1,
    name: "Nome do Cliente Extremamente Grande",
    email: "cliente_extremamente_grande@gmail.com",
    city: "São José do Vale do Rio Preto",
    contactNumber: "+55 82 99999-9999",
    createDate: "25/12/2024",
    ordersQuantity: "1000"
  },
  {
    id: 2,
    name: "Nome Teste SearchBar Extremamente Grande",
    email: "cliente_extremamente_grande@gmail.com",
    city: "São José do Vale do Rio Preto",
    contactNumber: "+55 82 99999-9999",
    createDate: "25/12/2024",
    ordersQuantity: "1000"
  },
  {
    id: 3,
    name: "Nome do Cliente Extremamente Grande",
    email: "cliente_extremamente_grande@gmail.com",
    city: "São José do Vale do Rio Preto",
    contactNumber: "+55 82 99999-9999",
    createDate: "25/12/2024",
    ordersQuantity: "1000"
  },
  {
    id: 4,
    name: "Nome do Cliente Extremamente Grande",
    email: "cliente_extremamente_grande@gmail.com",
    city: "São José do Vale do Rio Preto",
    contactNumber: "+55 82 99999-9999",
    createDate: "25/12/2024",
    ordersQuantity: "1000"
  },
  {
    id: 5,
    name: "Nome do Cliente Extremamente Grande",
    email: "cliente_extremamente_grande@gmail.com",
    city: "São José do Vale do Rio Preto",
    contactNumber: "+55 82 99999-9999",
    createDate: "25/12/2024",
    ordersQuantity: "1000"
  },
  {
    id: 6,
    name: "Nome do Cliente Extremamente Grande",
    email: "cliente_extremamente_grande@gmail.com",
    city: "São José do Vale do Rio Preto",
    contactNumber: "+55 82 99999-9999",
    createDate: "25/12/2024",
    ordersQuantity: "1000"
  },
  {
    id: 7,
    name: "Nome do Cliente Extremamente Grande",
    email: "cliente_extremamente_grande@gmail.com",
    city: "São José do Vale do Rio Preto",
    contactNumber: "+55 82 99999-9999",
    createDate: "25/12/2024",
    ordersQuantity: "1000"
  },
  {
    id: 8,
    name: "Nome do Cliente Extremamente Grande",
    email: "cliente_extremamente_grande@gmail.com",
    city: "São José do Vale do Rio Preto",
    contactNumber: "+55 82 99999-9999",
    createDate: "25/12/2024",
    ordersQuantity: "1000"
  },
  {
    id: 9,
    name: "Nome do Cliente Extremamente Grande",
    email: "cliente_extremamente_grande@gmail.com",
    city: "São José do Vale do Rio Preto",
    contactNumber: "+55 82 99999-9999",
    createDate: "25/12/2024",
    ordersQuantity: "1000"
  },
  {
    id: 10,
    name: "Nome do Cliente Extremamente Grande",
    email: "cliente_extremamente_grande@gmail.com",
    city: "São José do Vale do Rio Preto",
    contactNumber: "+55 82 99999-9999",
    createDate: "25/12/2024",
    ordersQuantity: "1000"
  },
  {
    id: 11,
    name: "Nome do Cliente Extremamente Grande",
    email: "email_extremamente_grande@gmail.com",
    city: "Santa Maria do Lá Ele do Mar Branco",
    contactNumber: "+55 82 55555-5555",
    createDate: "01/01/2025",
    ordersQuantity: "1000"
  },
  {
    id: 12,
    name: "Nome do Cliente Extremamente Grande",
    email: "email_extremamente_grande@gmail.com",
    city: "Santa Maria do Lá Ele do Mar Branco",
    contactNumber: "+55 82 55555-5555",
    createDate: "01/01/2025",
    ordersQuantity: "1000"
  },
  {
    id: 13,
    name: "Nome do Cliente Extremamente Grande",
    email: "email_extremamente_grande@gmail.com",
    city: "Santa Maria do Lá Ele do Mar Branco",
    contactNumber: "+55 82 55555-5555",
    createDate: "01/01/2025",
    ordersQuantity: "1000"
  },
  {
    id: 14,
    name: "Nome do Cliente Extremamente Grande",
    email: "email_extremamente_grande@gmail.com",
    city: "Santa Maria do Lá Ele do Mar Branco",
    contactNumber: "+55 82 55555-5555",
    createDate: "01/01/2025",
    ordersQuantity: "1000"
  },
  {
    id: 15,
    name: "Nome do Cliente Extremamente Grande",
    email: "email_extremamente_grande@gmail.com",
    city: "Santa Maria do Lá Ele do Mar Branco",
    contactNumber: "+55 82 55555-5555",
    createDate: "01/01/2025",
    ordersQuantity: "1000"
  },
  {
    id: 16,
    name: "Nome do Cliente Extremamente Grande",
    email: "email_extremamente_grande@gmail.com",
    city: "Santa Maria do Lá Ele do Mar Branco",
    contactNumber: "+55 82 55555-5555",
    createDate: "01/01/2025",
    ordersQuantity: "1000"
  },
  {
    id: 17,
    name: "Nome do Cliente Extremamente Grande",
    email: "email_extremamente_grande@gmail.com",
    city: "Santa Maria do Lá Ele do Mar Branco",
    contactNumber: "+55 82 55555-5555",
    createDate: "01/01/2025",
    ordersQuantity: "1000"
  },
  {
    id: 18,
    name: "Nome do Cliente Extremamente Grande",
    email: "email_extremamente_grande@gmail.com",
    city: "Santa Maria do Lá Ele do Mar Branco",
    contactNumber: "+55 82 55555-5555",
    createDate: "01/01/2025",
    ordersQuantity: "1000"
  },
  {
    id: 19,
    name: "Nome do Cliente Extremamente Grande",
    email: "email_extremamente_grande@gmail.com",
    city: "Santa Maria do Lá Ele do Mar Branco",
    contactNumber: "+55 82 55555-5555",
    createDate: "01/01/2025",
    ordersQuantity: "1000"
  },
  {
    id: 20,
    name: "Nome do Cliente Extremamente Grande",
    email: "email_extremamente_grande@gmail.com",
    city: "Santa Maria do Lá Ele do Mar Branco",
    contactNumber: "+55 82 55555-5555",
    createDate: "01/01/2025",
    ordersQuantity: "1000"
  },
  {
    id: 21,
    name: "Nome do Cliente Extremamente Grande",
    email: "cliente_extremamente_grande@gmail.com",
    city: "São José do Vale do Rio Preto",
    contactNumber: "+55 82 99999-9999",
    createDate: "25/12/2024",
    ordersQuantity: "1000"
  },
  {
    id: 22,
    name: "Nome do Cliente Extremamente Grande",
    email: "cliente_extremamente_grande@gmail.com",
    city: "São José do Vale do Rio Preto",
    contactNumber: "+55 82 99999-9999",
    createDate: "25/12/2024",
    ordersQuantity: "1000"
  },
  {
    id: 23,
    name: "Nome do Cliente Extremamente Grande",
    email: "cliente_extremamente_grande@gmail.com",
    city: "São José do Vale do Rio Preto",
    contactNumber: "+55 82 99999-9999",
    createDate: "25/12/2024",
    ordersQuantity: "1000"
  },
  {
    id: 24,
    name: "Nome do Cliente Extremamente Grande",
    email: "cliente_extremamente_grande@gmail.com",
    city: "São José do Vale do Rio Preto",
    contactNumber: "+55 82 99999-9999",
    createDate: "25/12/2024",
    ordersQuantity: "1000"
  },
  {
    id: 25,
    name: "Nome do Cliente Extremamente Grande",
    email: "cliente_extremamente_grande@gmail.com",
    city: "São José do Vale do Rio Preto",
    contactNumber: "+55 82 99999-9999",
    createDate: "25/12/2024",
    ordersQuantity: "1000"
  },
  {
    id: 26,
    name: "Nome do Cliente Extremamente Grande",
    email: "cliente_extremamente_grande@gmail.com",
    city: "São José do Vale do Rio Preto",
    contactNumber: "+55 82 99999-9999",
    createDate: "25/12/2024",
    ordersQuantity: "1000"
  },
  {
    id: 27,
    name: "Nome do Cliente Extremamente Grande",
    email: "cliente_extremamente_grande@gmail.com",
    city: "São José do Vale do Rio Preto",
    contactNumber: "+55 82 99999-9999",
    createDate: "25/12/2024",
    ordersQuantity: "1000"
  },
  {
    id: 28,
    name: "Nome do Cliente Extremamente Grande",
    email: "cliente_extremamente_grande@gmail.com",
    city: "São José do Vale do Rio Preto",
    contactNumber: "+55 82 99999-9999",
    createDate: "25/12/2024",
    ordersQuantity: "1000"
  },
  {
    id: 29,
    name: "Nome do Cliente Extremamente Grande",
    email: "cliente_extremamente_grande@gmail.com",
    city: "São José do Vale do Rio Preto",
    contactNumber: "+55 82 99999-9999",
    createDate: "25/12/2024",
    ordersQuantity: "1000"
  },
  {
    id: 30,
    name: "Nome do Cliente Extremamente Grande",
    email: "cliente_extremamente_grande@gmail.com",
    city: "São José do Vale do Rio Preto",
    contactNumber: "+55 82 99999-9999",
    createDate: "25/12/2024",
    ordersQuantity: "1000"
  },
];

export default mockClientsList
