import { useState, useContext, useEffect, useCallback } from "react";
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Table from "defaultComponents/Tables/Table";
import { Grid, Box } from "@mui/material";
import * as S from "./style";
import {
  IoArrowUp,
  IoArrowDown,
  IoEllipse,
  IoArrowBack,
  IoArrowForward,
  IoEllipsisVertical,
} from "react-icons/io5";
import { getProducts, getProductsCategories, getProductsSubCategories, deleteProduct, editProductStatus } from "./product";
import EditProduct from "./ModalContent/EditProduct";
import filter from "assets/icons/filter.svg";
import FilterModal from "components/Modal/FilterModal";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import { SearchBarContext } from "context/SearchBarContext";
import { useDebounce } from "use-debounce";
import { RiAddFill } from "react-icons/ri";
import Modal from "components/Modal/Modal";
import { TokenContext } from "context/TokenContext";
import * as T from "components/Toast/style";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import showToast from "components/Toast/Toast";
import VuiAvatar from "components/VuiAvatar";
import Pagination from '@mui/material/Pagination';
import CreateProduct from "./ModalContent/CreateProduct";
import mockProductsList from "./MockedLists/MockProductsList";

const Avatar = ({ img, theme, children, ...rest }) => (
  <VuiAvatar
    src={img}
    size="md"
    {...rest}
    sx={({ borders: { borderWidth }, palette: { light }, functions: { rgba } }) => ({
      borderRadius: "15px",
      position: "relative",
    })}
  >
    {children}
  </VuiAvatar>
);

const Products = () => {
  const [products, setProducts] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [page, setPage] = useState(1);
  const [categories, setCategories] = useState();
  const [subCategories, setSubCategories] = useState();
  const [openFilter, setOpenFilter] = useState(false);
  const [categoriesFilterValues, setCategoriesFilterValues] = useState([]);
  const [subCategoriesFilterValues, setSubCategoriesFilterValues] = useState([]);
  const [filters, setFilters] = useState({ name: null, categories: [], subCategories: [] });
  const [openCreateProductModal, setOpenCreateProductModal] = useState(false);
  const [openEditProductModal, setOpenEditProductModal] = useState(false);
  const [openMenu, setOpenMenu] = useState([]);
  const [openConfirmDeletionModal, setOpenConfirmDeletionModal] = useState(false);
  const [openConfirmStatusChangeModal, setOpenConfirmStatusChangeModal] = useState(false);
  const [chosenProduct, setChosenProduct] = useState();

  const { searchQuery } = useContext(SearchBarContext);
  const { decodedToken } = useContext(TokenContext);
  const role = decodedToken?.type;

  const [debouncedSearch] = useDebounce(searchQuery, 600);
  const [debouncedFilters] = useDebounce(filters, 600);

  const fetchCategories = async () => {
    const data = await getProductsCategories();
    if (!data.statusCode) {
      setCategories(data);
      const checkArr = Array.from({ length: data.length }, () => false);
      setCategoriesFilterValues(checkArr);
    }
  };

  const fetchSubCategories = async () => {
    const data = await getProductsSubCategories();
    if (!data.statusCode) {
      setSubCategories(data);
      const checkArr = Array.from({ length: data.length }, () => false);
      setSubCategoriesFilterValues(checkArr);
    }
  };

  const calculatePagination = () => {
    const roundUpArrSize = Math.ceil(mockProductsList.length / 10);
    const paginationArr = Array.from({ length: roundUpArrSize }, (value, index) => index);
    setPagination(paginationArr);
  };

  const fetchProducts = async () => {
    const data = await getProducts(page - 1, filters);
    if (!data.statusCode) {
      setProducts(data);
    }
  };

  const refetch = () => {
    fetchProducts();
  };

  const closeModal = () => {
    setOpenCreateProductModal(false);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    fetchSubCategories();
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [page, debouncedFilters]);

  useEffect(() => {
    calculatePagination();
  }, [products]);

  useEffect(() => {
    const selectedCategories = categoriesFilterValues
      .map((checked, index) => (checked ? categories[index]?.id : null))
      .filter((id) => id !== null);
  
    const selectedSubCategories = subCategoriesFilterValues
      .map((checked, index) => (checked ? subCategories[index]?.id : null))
      .filter((id) => id !== null);
  
    setFilters((prevData) => ({
      ...prevData,
      categories: selectedCategories,
      subCategories: selectedSubCategories,
      name: debouncedSearch
    }));
  
    setPage(1);
  }, [categoriesFilterValues, subCategoriesFilterValues, debouncedSearch]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleCategoriesChange = (index) => {
    const updatedCheckedState = categoriesFilterValues.map((item, i) =>
      i === index ? !item : item
    );
    setCategoriesFilterValues(updatedCheckedState);
  };

  const handleSubCategoriesChange = (index) => {
    const updatedCheckedState = subCategoriesFilterValues.map((item, i) =>
      i === index ? !item : item
    );
    setSubCategoriesFilterValues(updatedCheckedState);
  };

  const tagFilter = useCallback(() => {
    const checkedCategoryIndexes = categoriesFilterValues
      ?.map((bool, index) => (bool ? index : null))
      .filter((el) => el !== null);
  
    const selectedCategories = checkedCategoryIndexes?.map((index) => categories[index].id) || [];
  
    const checkedSubCategoryIndexes = subCategoriesFilterValues
      ?.map((bool, index) => (bool ? index : null))
      .filter((el) => el !== null);
  
    const selectedSubCategories = checkedSubCategoryIndexes?.map((index) => subCategories[index].id) || [];
  
    const filtersChanged =
      JSON.stringify(selectedCategories) !== JSON.stringify(filters?.categories) ||
      JSON.stringify(selectedSubCategories) !== JSON.stringify(filters?.subCategories);
  
    if (filtersChanged) {
      setFilters((prevData) => ({
        ...prevData,
        categories: selectedCategories,
        subCategories: selectedSubCategories,
      }));
      setPage(1);
    }
  }, [categoriesFilterValues, subCategoriesFilterValues, categories, subCategories, filters?.categories, filters?.subCategories]);
  
  useEffect(() => {
    tagFilter();
  }, [tagFilter]);

  const toggleOpenMenu = (index) => {
    const updatedMenu = [...openMenu];
    updatedMenu[index] = !updatedMenu[index];
    setOpenMenu(updatedMenu);
  };

  const handleEdit = (productInfo) => {
    setChosenProduct(productInfo);
    setOpenEditProductModal(true);
  };

  const handleDelete = (productInfo) => {
    setChosenProduct(productInfo);
    setOpenConfirmDeletionModal(true);
  };

  const cancelDelete = () => {
    setChosenProduct();
    setOpenConfirmDeletionModal(false);
  };

  const handleProductStatus = (productInfo) => {
    setChosenProduct(productInfo);
    setOpenConfirmStatusChangeModal(true);
  };

  const cancelStatusChange = () => {
    setChosenProduct();
    setOpenConfirmStatusChangeModal(false);
  };

  const productStatusChange = async (bool) => {
    await editProductStatus(chosenProduct?.name, { visible: bool }).then((res) => {
      if (!res.message) {
        showToast(
          bool ? "Produto colocado à venda!" : "Produto colocado como não visível!",
          "success"
        );
        fetchProducts();
        cancelStatusChange();
      }
      if (res.message) {
        showToast("Falha ao alterar visibilidade", "error");
      }
    });
  };

  const handleProductDelete = async (name) => {
    await deleteProduct(chosenProduct?.name).then((res) => {
      if (!res.message) {
        showToast("Produto deletado com sucesso!", "success");
        fetchProducts();
        cancelDelete();
      }
      if (res.message) {
        showToast("Falha ao deletar produto!", "error");
      }
    });
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <DashboardLayout
      onClick={() => (openMenu.includes(true) ? setOpenMenu(openMenu.map((bool) => false)) : null)}
    >
      <DashboardNavbar />
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <VuiBox py={1}>
        <VuiBox mb={1}>
          <S.TableCard>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
              <VuiTypography variant="lg" color="white">
                Produtos
              </VuiTypography>
              <S.EditButton onClick={() => setOpenFilter(true)}>
                <img src={filter} draggable="false" />
              </S.EditButton>
            </VuiBox>
            <VuiBox pb="40px">
              <Table
                mb={role === "ADMINISTRATOR" ? "40px" : 0}
                columns={[
                  { name: "produtos", align: "left" },
                  { name: "categoria", align: "left" },
                  { name: "preço", align: "center" },
                  { name: "estoque", align: "center" },
                  { name: "status", align: "center" },
                  { name: "", align: "center" },
                ]}
                rows={
                  products &&
                  products.map((product, index) => {
                    return {
                      produtos: (
                        <S.ProductGrid
                          container
                          flexDirection="row"
                          // onClick={() =>
                          //   navigate(`/Products/${product?.name ? product?.name : ""}`)
                          // }
                          key={product?.id}
                        >
                          <Grid container item xl={1.5} lg={3} md={2}>
                            <S.ProductPhoto>
                              <Avatar src={product?.avatar ? product?.avatar : ""} />
                            </S.ProductPhoto>
                          </Grid>
                          <S.ProductTextGrid
                            container
                            flexDirection="column"
                            item
                            xl={8.5}
                            lg={7}
                            md={8}
                          >
                            <p className="name">{product.name}</p>
                            <p className="description">
                            {product?.description?.length > 60
                              ? product?.description?.substring(0, 60) + "..."
                              : product?.description}
                          </p>
                          </S.ProductTextGrid>
                        </S.ProductGrid>
                      ),
                      categoria: (
                        <S.CategoriesGrid container flexDirection="column">
                          <p className="categories">
                            {product?.categories?.length > 60
                              ? product?.categories?.substring(0, 60) + "..."
                              : product?.categories}
                          </p>
                          <p className="subCategories">
                            {product?.subCategories?.length > 60
                              ? product?.subCategories?.substring(0, 60) + "..."
                              : product?.subCategories}
                          </p>
                        </S.CategoriesGrid>
                      ),
                      preço: (
                        <S.ProductTextGrid container justifyContent="center">
                          <p>
                            {product.price}
                          </p>
                        </S.ProductTextGrid>
                      ),
                      estoque: (
                        <S.ProductTextGrid container justifyContent="center">
                          <p>
                            {product.stock}
                          </p>
                        </S.ProductTextGrid>
                      ),
                      status: (
                        <S.StatusGrid container justifyContent="center">
                          <IoEllipse color={product?.visible ? "#65EFAD" : "#D7007B"} size="12px" />
                          <p className="status">{product?.visible ? "À VENDA" : "NÃO VISÍVEL"}</p>
                        </S.StatusGrid>
                      ),
                      "": (
                        <Grid container justifyContent="space-between">
                          {role === "ADMINISTRATOR" && (
                            <Grid
                              item
                              xs={3}
                              md={1}
                              xl={1}
                              sx={{ alignSelf: "center" }}
                              onClick={() => toggleOpenMenu(index)}
                            >
                              <IoEllipsisVertical size="20px" style={{ cursor: "pointer" }} />
                            </Grid>
                          )}
                          {openMenu[index] && (
                            <S.OptionsContainer container justifyContent="flex-end">
                              <S.OptionsGrid display="flex" flexDirection="column">
                                <p onClick={() => handleEdit(product)}>Editar produto</p>
                                <S.Divider />
                                {product?.visible && (
                                  <>
                                    <p onClick={() => handleProductStatus(product)}>Mudar visibilidade</p>
                                  </>
                                )}
                                {!product?.visible && (
                                  <>
                                    <p onClick={() => handleProductStatus(product)}>Mudar visibilidade</p>
                                  </>
                                )}
                                <S.Divider />
                                <p onClick={() => handleDelete(product)}>Excluir produto</p>
                              </S.OptionsGrid>
                            </S.OptionsContainer>
                          )}
                        </Grid>
                      )
                    };
                  })
                }
              />
            </VuiBox>
          </S.TableCard>
        </VuiBox>
      </VuiBox>
      <Grid container justifyContent="center">
        <S.PaginationGrid container justifyContent="center">
          <Pagination
            count={pagination?.length}
            page={page}
            siblingCount={1}
            boundaryCount={1}
            onChange={handleChange}
          />
        </S.PaginationGrid>
      </Grid>
      {role === "ADMINISTRATOR" && (
        <Box sx={{ "& > :not(style)": { m: 1 } }}>
          <S.ModalFab color="primary" onClick={() => setOpenCreateProductModal(true)}>
            <RiAddFill size="30px" />
          </S.ModalFab>
        </Box>
      )}
      <FilterModal size="sm" open={openFilter} onClose={() => setOpenFilter(false)}>
        <p>Filtragem por categoria</p>
        {categories &&
          categories.map((cat, index) => (
            <Grid container key={index}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={categoriesFilterValues[index]}
                onChange={() => handleCategoriesChange(index)}
                name={cat.name}
              />
              <p style={{ fontSize: "18px" }}>{cat.name}</p>
            </Grid>
          ))}
        <p style={{ marginTop: "20px" }}>Filtragem por subcategoria</p>
        {subCategories &&
          subCategories.map((cat, index) => (
            <Grid container key={index}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={subCategoriesFilterValues[index]}
                onChange={() => handleSubCategoriesChange(index)}
                name={cat.name}
              />
              <p style={{ fontSize: "18px" }}>{cat.name}</p>
            </Grid>
          ))}
      </FilterModal>
      <Modal open={openCreateProductModal} onClose={() => setOpenCreateProductModal(false)}>
        <CreateProduct refetch={refetch} closeModal={closeModal} />
      </Modal>
      <Modal open={openEditProductModal} onClose={() => setOpenEditProductModal(false)}>
        <EditProduct
          product={chosenProduct}
          refetch={refetch}
          closeModal={() => setOpenEditProductModal(false)}
        />
      </Modal>
      <Modal
        size="sm"
        open={openConfirmDeletionModal}
        onClose={() => setOpenConfirmDeletionModal(false)}
      >
        <Grid container flexDirection="column" gap="20px">
          <p style={{ fontSize: "24px" }}>Confirmar exclusão</p>
          <p style={{ fontSize: "18px" }}>Deseja excluir esse produto? Essa ação é irreversível</p>
          <Grid container gap="15px" justifyContent="center">
            <Grid item lg={3}>
              <ButtonCustom
                label="Cancelar"
                onClick={() => cancelDelete()}
                sx={{ background: "linear-gradient(180deg, #818181 0%, #2a2020 155.15%)" }}
              ></ButtonCustom>
            </Grid>
            <Grid item lg={3}>
              <ButtonCustom
                label="Confirmar"
                onClick={() => handleProductDelete(chosenProduct?.id)}
              ></ButtonCustom>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal size="sm" open={openConfirmStatusChangeModal} onClose={() => cancelStatusChange()}>
        <Grid container flexDirection="column" gap="20px">
          <p style={{ fontSize: "24px" }}>Confirmar mudança de visibilidade</p>
          <p style={{ fontSize: "18px" }}>
            Deseja colocar este produto como {chosenProduct?.visible ? "NÃO VISÍVEL" : "À VENDA"}?
          </p>
          <Grid container gap="15px" justifyContent="center">
            <Grid item lg={3}>
              <ButtonCustom
                label="Cancelar"
                onClick={() => cancelStatusChange()}
                sx={{ background: "linear-gradient(180deg, #818181 0%, #2a2020 155.15%)" }}
              ></ButtonCustom>
            </Grid>
            <Grid item lg={3}>
              <ButtonCustom
                label="Confirmar"
                onClick={() => productStatusChange(!chosenProduct?.visible)}
              ></ButtonCustom>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </DashboardLayout>
  );
};

export default Products;