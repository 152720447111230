import { useState, useContext, useEffect } from "react";
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Table from "defaultComponents/Tables/Table";
import { Grid } from "@mui/material";
import * as S from "./style";
import { useNavigate } from "react-router-dom";
import {
  IoEllipsisVertical,
} from "react-icons/io5";
import { getClients, deleteClient } from "./clients";
import EcommerceClientDetails from "./ModalContent/EcommerceClientDetails";
import { SearchBarContext } from "context/SearchBarContext";
import { useDebounce } from "use-debounce";
import Modal from "components/Modal/Modal";
import { TokenContext } from "context/TokenContext";
import * as T from "components/Toast/style";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import showToast from "components/Toast/Toast";
import Pagination from '@mui/material/Pagination';
import mockClientsList from "./MockedLists/MockClientsList";

const EcommerceClients = () => {
  const navigate = useNavigate();

  const [clients, setClients] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({ name: null, subCategories: [] });
  const [openMenu, setOpenMenu] = useState([]);
  const [openEcommerceClientDetailsModal, setOpenEcommerceClientDetailsModal] = useState(false);
  const [openConfirmDeletionModal, setOpenConfirmDeletionModal] = useState(false);
  const [chosenClient, setChosenClient] = useState();

  const { searchQuery } = useContext(SearchBarContext);
  const { decodedToken } = useContext(TokenContext);
  const role = decodedToken?.type;

  const [debouncedSearch] = useDebounce(searchQuery, 600);

  const calculatePagination = () => {
    const roundUpArrSize = Math.ceil(mockClientsList.length / 10);
    const paginationArr = Array.from({ length: roundUpArrSize }, (value, index) => index);
    setPagination(paginationArr);
  };

  const fetchClients = async () => {
    const data = await getClients(page - 1, filters);
    if (!data.statusCode) {
      setClients(data);
    }
  };

  const refetch = () => {
    fetchClients();
  };

  useEffect(() => {
    fetchClients();
    refetch();
  }, [page, filters]);
  
  useEffect(() => {
    calculatePagination();
  }, [clients]);

  useEffect(() => {  
    setFilters((prevData) => ({
      ...prevData,
      name: debouncedSearch
    }));
  
    setPage(1);
  }, [debouncedSearch]);

  const toggleOpenMenu = (index) => {
    const updatedMenu = [...openMenu];
    updatedMenu[index] = !updatedMenu[index];
    setOpenMenu(updatedMenu);
  };
  
  const handleDelete = (clientInfo) => {
    setChosenClient(clientInfo);
    setOpenConfirmDeletionModal(true);
  };

  const cancelDelete = () => {
    setChosenClient();
    setOpenConfirmDeletionModal(false);
  };

  const handleClientDelete = async (name) => {
    await deleteClient(chosenClient?.name).then((res) => {
      if (!res.message) {
        showToast("Produto deletado com sucesso!", "success");
        fetchClients();
        cancelDelete();
      }
      if (res.message) {
        showToast("Falha ao deletar produto!", "error");
      }
    });
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <DashboardLayout
      onClick={() => (openMenu.includes(true) ? setOpenMenu(openMenu.map((bool) => false)) : null)}
    >
      <DashboardNavbar />
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <VuiBox py={1}>
        <VuiBox mb={1}>
          <S.TableCard>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
              <VuiTypography variant="lg" color="white">
                Clientes
              </VuiTypography>
            </VuiBox>
            <VuiBox pb="40px">
              <Table
                mb={role === "ADMINISTRATOR" ? "40px" : 0}
                columns={[
                  { name: "nome do cliente", align: "left", width: "30%" },
                  { name: "email", align: "left" },
                  { name: "cidade", align: "left" },
                  { name: "número de telefone", align: "left" },
                  { name: "data de criação", align: "left" },
                  { name: "", align: "center" },
                ]}
                rows={
                  clients &&
                  clients.map((client, index) => {
                    return {
                      "nome do cliente": (
                        <S.ClientGrid
                          container
                          flexDirection="row"
                          key={client?.id}
                        >
                          <S.ClientTextGrid
                            container
                            flexDirection="column"
                            item
                            xl={8.5}
                            lg={7}
                            md={8}
                          >
                            <p className="name">{client.name}</p>
                            <p>{client.ordersQuantity} pedidos</p>
                          </S.ClientTextGrid>
                        </S.ClientGrid>
                      ),
                      email: (
                        <S.ClientTextGrid container flexDirection="column">
                          <p>
                            {client.email}
                          </p>
                        </S.ClientTextGrid>
                      ),
                      cidade: (
                        <S.ClientTextGrid container>
                          <p>
                            {client.city}
                          </p>
                        </S.ClientTextGrid>
                      ),
                      "número de telefone": (
                        <S.ClientTextGrid container>
                          <p>
                          {client.contactNumber}
                          </p>
                        </S.ClientTextGrid>
                      ),
                      "data de criação": (
                        <S.ClientTextGrid container>
                          <p>
                          {client.createDate}
                          </p>
                        </S.ClientTextGrid>
                      ),
                      "": (
                        <Grid container justifyContent="space-between">
                          {role === "ADMINISTRATOR" && (
                            <Grid
                              item
                              xs={3}
                              md={1}
                              xl={1}
                              sx={{ alignSelf: "center" }}
                              onClick={() => toggleOpenMenu(index)}
                            >
                              <IoEllipsisVertical size="20px" style={{ cursor: "pointer" }} />
                            </Grid>
                          )}
                          {openMenu[index] && (
                            <S.OptionsContainer container justifyContent="flex-end">
                              <S.OptionsGrid display="flex" flexDirection="column">
                                <p onClick={() => navigate(`/ecommerce/clientes/${client?.id}`)}>
                                  Detalhes
                                </p>
                                <S.Divider />
                                <p>Email</p>
                                <S.Divider />
                                <p onClick={() => handleDelete(client)}>Excluir</p>
                              </S.OptionsGrid>
                            </S.OptionsContainer>
                          )}
                        </Grid>
                      )
                    };
                  })
                }
              />
            </VuiBox>
          </S.TableCard>
        </VuiBox>
      </VuiBox>
      <Grid container justifyContent="center">
        <S.PaginationGrid container justifyContent="center">
          <Pagination
            count={pagination?.length}
            page={page}
            siblingCount={1}
            boundaryCount={1}
            onChange={handleChange}
          />
        </S.PaginationGrid>
      </Grid>
      <Modal open={openEcommerceClientDetailsModal} onClose={() => setOpenEcommerceClientDetailsModal(false)}>
        <EcommerceClientDetails
          product={chosenClient}
          refetch={refetch}
          closeModal={() => setOpenEcommerceClientDetailsModal(false)}
        />
      </Modal>
      <Modal
        size="sm"
        open={openConfirmDeletionModal}
        onClose={() => setOpenConfirmDeletionModal(false)}
      >
        <Grid container flexDirection="column" gap="20px">
          <p style={{ fontSize: "24px" }}>Confirmar exclusão</p>
          <p style={{ fontSize: "18px" }}>Deseja excluir esse cliente? Essa ação é irreversível</p>
          <Grid container gap="15px" justifyContent="center">
            <Grid item lg={3}>
              <ButtonCustom
                label="Cancelar"
                onClick={() => cancelDelete()}
                sx={{ background: "linear-gradient(180deg, #818181 0%, #2a2020 155.15%)" }}
              ></ButtonCustom>
            </Grid>
            <Grid item lg={3}>
              <ButtonCustom
                label="Confirmar"
                onClick={() => handleClientDelete(chosenClient?.id)}
              ></ButtonCustom>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </DashboardLayout>
  );
};

export default EcommerceClients;