import { Grid } from '@mui/material';
import Table from "defaultComponents/Tables/Table";
import { TableContainer } from '@mui/material';
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import moment from 'moment';
import 'moment/locale/pt-br';
import { useState, useContext, useEffect } from "react";
import { mockProducts } from '../MockedLists/MockProducts';
import { mockOrderResume } from '../MockedLists/MockOrderResume';
import { mockOrderClient } from '../MockedLists/MockOrderClient';
import { mockDeliverySteps } from '../MockedLists/MockDeliverySteps';
import VuiAvatar from "components/VuiAvatar";
import { TokenContext } from "context/TokenContext";
import * as T from "components/Toast/style";
import * as S from "./style";
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import { useLocation } from 'react-router-dom';
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import pencil from '../../../../assets/icons/pencil.png';
import Modal from "components/Modal/Modal";
import EditOrderInfo from './ModalContent/EditOrderInfo';

const Avatar = ({ img, theme, children, ...rest }) => (
  <VuiAvatar
    src={img}
    size="md"
    {...rest}
    sx={({ borders: { borderWidth }, palette: { light }, functions: { rgba } }) => ({
      borderRadius: "15px",
      position: "relative",
    })}
  >
    {children}
  </VuiAvatar>
);

const Order = () => {
  const location = useLocation();
  const { numero, data, status } = location.state || {};
  const { grey } = colors;
  const { borderWidth } = borders;

  moment.locale("pt-br")
  const { decodedToken } = useContext(TokenContext);
  const role = decodedToken?.type;

  const [products, setProducts] = useState([]);
  const [orderResume, setOrderResume] = useState([]);
  const [orderClient, setOrderClient] = useState([]);
  const [deliverySteps, setDeliverySteps] = useState([]);

  const [chosenOrder, setChosenOrder] = useState();
  const [openEditOrderInfoModal, setOpenEditOrderInfoModal] = useState(false);

  const fetchProducts = async () => {
    setProducts(mockProducts);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchOrderResume = async () => {
    setOrderResume(mockOrderResume);
  };

  useEffect(() => {
    fetchOrderResume();
  }, []);

  const fetchOrderClient = async () => {
    setOrderClient(mockOrderClient);
  };

  useEffect(() => {
    fetchOrderClient();
  }, []);

  const fetchDeliverySteps = async () => {
    setDeliverySteps(mockDeliverySteps);
  };

  useEffect(() => {
    fetchDeliverySteps();
  }, []);

  const handleEdit = (orderInfo) => {
    setChosenOrder(orderInfo);
    setOpenEditOrderInfoModal(true);
  };

  return (
    <DashboardLayout>
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <DashboardNavbar/>
      <Grid 
        container spacing='18px'
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridTemplateAreas: `
          "pedido pedido resumo"
          "pedido pedido informacao"
          "entrega entrega informacao"
          `,
        }}
      >
        <Grid item sx={{ gridArea: 'pedido', height: "630px" }}>
          <S.TableCard>
            <Grid display="flex" justifyContent="space-between" alignItems="center" mb="5px">
              <Grid mt="20px">
                <S.OrderGridTitle >
                  <p className="orderGridTitle">
                    Pedido #{numero}
                  </p>
                </S.OrderGridTitle>
                <S.OrderGridTitle>
                  <p className="orderDateStatus">
                    {data}
                  </p>
                </S.OrderGridTitle>
              </Grid>
              <S.OrderGridTitle>
                <div className="orderStatus">
                  <span className="orderDateStatus">Status do pedido: </span>
                  <S.StatusBadgeGrid
                    container
                    style={{
                      background: status === "Cancelado" ? "linear-gradient(180deg, #B51E23 0%, #3D0002 100%)" : 
                                status === "Em produção" || status === "Em transporte" ? 
                                "linear-gradient(180deg, #FB8404 0%, #FE1E14 100%)" : 
                                status === "Entregue" ? "linear-gradient(180deg, #00D755 0%, #00240D 100%)" :
                                "#727272"
                    }}
                  >
                    <span className="orderResumeStatus">{status}</span>
                  </S.StatusBadgeGrid>
                </div>
              </S.OrderGridTitle>
            </Grid>
            <Grid>
              <TableContainer sx={{ height: "460px" }}>
                <Table 
                  mb={role === "ADMINISTRATOR" ? "10px" : 0}
                  columns={[
                    { name: "nome do produto", align: "left", width: "45%" },
                    { name: "preço", align: "left" },
                    { name: "quantidade", align: "center" },
                    { name: "total", align: "center" },
                  ]}
                  rows={
                    products &&
                    products.map((product) => {
                      return {
                        "nome do produto": (
                          <S.OrderProductsGrid
                            container
                            flexDirection="row"
                          >
                            <Grid container item xl={1.5} lg={3} md={2}>
                              <S.ProductPhoto>
                                <Avatar src={product?.avatar ? product?.avatar : ""} />
                              </S.ProductPhoto>
                            </Grid>
                            <S.OrderTextGrid
                              container
                              flexDirection="column"
                              item
                              xl={8.5}
                              lg={7}
                              md={8}
                            >
                              <p className="name">
                                {product.nome}
                              </p>
                              <p className="model">
                                {product.modelo}
                              </p>
                            </S.OrderTextGrid>
                          </S.OrderProductsGrid>
                        ),
                        preço: (
                          <S.OrderTextGrid container flexDirection="column">
                            <p className="price">
                              R$ {product.preco}
                            </p>
                          </S.OrderTextGrid>
                        ),
                        quantidade: (
                          <S.OrderTextGrid container justifyContent="center">
                            <p className="quantity">
                              {product.quantidade}
                            </p>
                          </S.OrderTextGrid>
                        ),
                        total: (
                          <S.OrderTextGrid container justifyContent="center">
                            <p className="total">
                              R$ {product.total}
                            </p>
                          </S.OrderTextGrid>
                        ),
                      };
                    })
                  }
                />
              </TableContainer>
              <Grid container justifyContent="flex-end" mt="20px">
                <ButtonCustom
                  label='Registrar Entrega'
                  type='submit'
                  sx={{ width: "165px", height: "36px !important", fontSize: "12px", fontWeight: "600" }}
                  onClick={() => setValidateFields(true)}
                />
              </Grid>
            </Grid>
          </S.TableCard>
        </Grid>
        <Grid item sx={{ gridArea: 'resumo', height: "281px" }}>
          <S.TableCard sx={{ overflow: 'auto' }}>
            <Grid display="flex" justifyContent="space-between" alignItems="center" mt="20px" mb="10px">
              <S.OrderGridTitle>
                <p className="orderGridTitle">
                  Resumo do Pedido
                </p>
              </S.OrderGridTitle>
              <Grid display='flex' flexDirection='column' justifyContent='center'>
                <S.ResumeBadgeGrid container>
                  <p className="orderResumeStatus">
                    Pago
                  </p>
                </S.ResumeBadgeGrid>
              </Grid>
            </Grid>
            { orderResume &&
            orderResume.map((resume) => (
              <Grid display="flex" justifyContent="space-between">
                <S.OrderTextGrid>
                  <p>
                    {resume.title}
                  </p>
                </S.OrderTextGrid>
                <S.OrderTextGrid>
                  <p className="infoOrderClient">
                    {resume.value}
                  </p>
                </S.OrderTextGrid>
              </Grid>
            ))}
            <S.OrderResumeTotal display="flex" justifyContent="space-between" borderTop={`${borderWidth[1]} solid ${grey[700]}`} mt="10px" pt="5px">
              <S.OrderTextGrid>
                <p className="totalResume">
                  Total do pedido:
                </p>
              </S.OrderTextGrid>
              <S.OrderTextGrid>
                <p className="totalResume">
                R$ 1.000.000,00
                </p>
              </S.OrderTextGrid>
            </S.OrderResumeTotal>           
          </S.TableCard>
        </Grid>
        <Grid item sx={{ gridArea: 'entrega', height: "215px" }}>
          <S.TableCard>
            <S.OrderGridTitle mt="20px">
              <p className="orderGridTitle">
                Detalhes da Entrega
              </p>
            </S.OrderGridTitle>
            <S.DeliveryGrid container>
              { deliverySteps &&
              deliverySteps.map((step, index) => (
                <S.DeliveryTimeline item>
                  <S.DeliveryTimelineItem status={step.status}>
                      <S.DeliveryTimelineContent >
                        <S.DeliveryTimelineDot 
                          className={step.status}
                        >
                          {step.icon}
                        </S.DeliveryTimelineDot>
                      </S.DeliveryTimelineContent>
                      <S.DeliveryTypography>
                        <p className="deliveryStep">
                          {step.title}
                        </p>
                      </S.DeliveryTypography>
                      <S.DeliveryTypography>
                        <p className="deliveryDate">
                          {step.date}
                        </p>
                      </S.DeliveryTypography>
                  </S.DeliveryTimelineItem>
                  {index < 4 && (
                    <S.DeliveryTimelineConnector
                      className={step.status}
                    />
                  )}
                </S.DeliveryTimeline>
              ))}
            </S.DeliveryGrid>
          </S.TableCard>
        </Grid>
        <Grid item sx={{ gridArea: 'informacao', height: "564px" }}>
          <S.TableCard sx={{ overflow: "auto" }}>
            <Grid pb="10px">
              <Grid display="flex" justifyContent="space-between" alignItems="center" mt="20px" mb="10px">
                <S.OrderGridTitle>
                  <p className="orderGridTitle">
                    Informações do Pedido
                  </p>
                </S.OrderGridTitle>
                {role === 'ADMINISTRATOR' && (
                  <S.EditButtonGrid>
                    <S.EditButton onClick={() => handleEdit()}>
                      <img src={pencil} />
                    </S.EditButton>
                  </S.EditButtonGrid>
                )}
              </Grid>
              {orderClient &&
              orderClient.map((client) => (
                <Grid display="flex" justifyContent="space-between" alignItems="center" mb="5px">
                  <S.OrderTextGrid width= "50%">
                    <p>
                      {client.title}
                    </p>
                  </S.OrderTextGrid>
                  <S.OrderTextGrid width= "50%" sx={{ textAlign: "right" }}>
                    <p className="infoOrderClient">
                      {client.value}
                    </p>
                  </S.OrderTextGrid>
                </Grid>
              ))}
            </Grid>
          </S.TableCard>
        </Grid>
      </Grid>
      <Modal open={openEditOrderInfoModal} onClose={() => setOpenEditOrderInfoModal(false)}>
        <EditOrderInfo
          order={chosenOrder}
          closeModal={() => setOpenEditOrderInfoModal(false)}
        />
      </Modal>
    </DashboardLayout >
  )
}

export default Order