import styled from "styled-components"
import { Grid } from "@mui/material"
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

export const MainContainer = styled(Grid)`
  color: #fff;
  padding: 40px 40px;

  .title {
    font-size: 26px;
    font-weight: 700;
    text-align: center;
  }
  .lastUpdate {
    font-size: 15px;
    font-weight: 400;
    opacity: 0.75;
    text-align: right;
  }
  .description {
    font-size: 14px;
    font-weight: 400;
  }

  ul {
    list-style: none;
  }
  ol {
    list-style: disc;
    margin-left: 20px;
  }

  @media(min-width: 1600px){
    .title {
      font-size: 30px;
    }
    .lastUpdate {
      font-size: 18px;
    }
    .description {
      font-size: 18px;
    }
  }

  @media(min-width: 1288px) and (max-width: 1599px){
    .title {
      font-size: 28px;
    }
    .lastUpdate {
      font-size: 16px;
    }
    .description {
      font-size: 16px;
    }
  }

  @media(max-width: 960px){
    .title {
      font-size: 24px;
    }
    .lastUpdate {
      font-size: 14px;
    }
    .description {
      font-size: 13px;
    }
  }

  @media(min-width: 360px) and (max-width: 860px){
    .title {
      font-size: 22px;
    }
    .lastUpdate {
      font-size: 12px;
    }
    .description {
      font-size: 11px;
    }
  }
`

export const VersionsTableContainer = styled(VuiBox)`
  margin-top: 30px;
  padding-bottom: 100%;
`

export const TableTitle = styled(VuiTypography)`
  color: white !important;
  font-weight: 700 !important;
  padding-left: 10px;

  @media(min-width: 1600px){
    font-size: 24px !important;
  }

  @media(min-width: 1288px) and (max-width: 1599px){
    font-size: 22px !important;
  }

  @media(max-width: 960px){
    font-size: 18px !important;
  }

  @media(min-width: 360px) and (max-width: 860px){
    font-size: 14px !important;
  }
`

export const VersionsTable = styled(VuiBox)`
  margin-top: 7px;
  min-width: 298px;
  border: 1px solid #000000;
`

export const TableHeader = styled(Grid)`
  background-color: #000000BF;
  font-weight: 700;
  padding: 18px 30px;

  @media(max-width: 960px){
    padding: 12px 20px;
  }

  @media(min-width: 360px) and (max-width: 860px){
    font-size: 12px !important;
  }
`

export const HeaderContent = styled(VuiTypography)`
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #FFFFFF !important;

  @media(min-width: 1600px){
    font-size: 20px !important;
  }

  @media(min-width: 1288px) and (max-width: 1599px){
    font-size: 18px !important;
  }

  @media(max-width: 960px){
    font-size: 14px !important;
  }

  @media(min-width: 360px) and (max-width: 860px){
    font-size: 12px !important;
    display: flex;
    align-items: center;
    height: 100%;
  }
`

export const TableBody = styled(Grid)`
  border-bottom: 1px solid #000000;
  padding: 18px 30px;

  @media(max-width: 960px){
    padding: 12px 20px;
  }
`

export const BodyContent = styled(VuiTypography)`
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #FFFFFF !important;

  @media(min-width: 1600px){
    font-size: 18px !important;
  }

  @media(min-width: 1288px) and (max-width: 1599px){
    font-size: 16px !important;
  }

  @media(max-width: 960px){
    font-size: 12px !important;
  }

  @media(min-width: 360px) and (max-width: 860px){
    font-size: 10px !important;
  }
`

export const NoteContent = styled(VuiTypography)`
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #FFFFFF !important;
  margin-bottom: 15px !important;

  &:last-child {
    margin-bottom: 0px !important;
  }

  @media(min-width: 1600px){
    font-size: 18px !important;
  }

  @media(min-width: 1288px) and (max-width: 1599px){
    font-size: 16px !important;
  }

  @media(max-width: 960px){
    font-size: 12px !important;
    margin-bottom: 12px !important;
  }

  @media(min-width: 360px) and (max-width: 860px){
    font-size: 10px !important;
    margin-right: 20px !important;
  }
`