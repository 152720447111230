import { useState, useEffect, useContext } from "react";
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiPagination from "components/VuiPagination";
import Table from "defaultComponents/Tables/Table";
import { Grid, Box, Tabs, Tab } from "@mui/material";
import * as S from "./style";
import instagram from "../../../assets/icons/socials/instagram.png";
import youtube from "../../../assets/icons/socials/youtube.png";
import tiktok from "../../../assets/icons/socials/tiktok.png";
import twitter from "../../../assets/icons/socials/twitter.png";
import spotify from "../../../assets/icons/socials/spotify.png";
import { useNavigate, Link } from "react-router-dom";
import {
  IoEllipse,
  IoEllipsisVertical,
} from "react-icons/io5";
import { getInfluencers, getInfluencerTags, editInfluencer } from "utils/requests/auth";
import filter from "assets/icons/filter.svg";
import FilterModal from "components/Modal/FilterModal";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import { SearchBarContext } from "context/SearchBarContext";
import { useDebounce } from "use-debounce";
import { abbreviateNumbers } from "utils/utils";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { RiAddFill, RiUser3Fill, RiUserAddFill } from "react-icons/ri";
import Modal from "components/Modal/Modal";
import { TokenContext } from "context/TokenContext";
import * as T from "components/Toast/style";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import showToast from "components/Toast/Toast";
import { deleteUser } from "utils/requests/auth";
import VuiAvatar from "components/VuiAvatar";
import Pagination from "@mui/material/Pagination";
import breakpoints from "assets/theme/base/breakpoints";
import CreateAffiliate from "./AffiliateDetails/ModalContent/CreateAffiliate";

const Affiliates = () => {
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [page, setPage] = useState(1);
  const [tags, setTags] = useState();
  const [openFilter, setOpenFilter] = useState(false);
  const [filterValues, setFilterValues] = useState([]);
  const [filters, setFilters] = useState({ name: null, userTags: [] });
  const [openCreateInfluencerModal, setOpenCreateInfluencerModal] = useState(false);
  const [openMenu, setOpenMenu] = useState([]);
  const [openConfirmDeletionModal, setOpenConfirmDeletionModal] = useState(false);
  const [openConfirmStatusChangeModal, setOpenConfirmStatusChangeModal] = useState(false);
  const [chosenInfluencer, setChosenInfluencer] = useState();
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState("affiliateList");
  const [openCreateAffiliateModal, setOpenCreateAffiliateModal] = useState(false);

  const { searchQuery } = useContext(SearchBarContext);
  const { decodedToken } = useContext(TokenContext);
  const role = decodedToken?.type;

  const [debouncedSearch] = useDebounce(searchQuery, 600);
  const [debouncedFilters] = useDebounce(filters, 600);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.lg
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const fetchTags = async () => {
    const data = await getInfluencerTags();
    if (!data.statusCode) {
      setTags(data);
      const checkArr = Array.from({ length: data.length }, () => false);
      setFilterValues(checkArr);
    }
  };

  const calculatePagination = () => {
    const roundUpArrSize = Math.ceil(users[1] / 10);
    const paginationArr = Array.from({ length: roundUpArrSize }, (value, index) => index);
    setPagination(paginationArr);
  };

  const fetchUsers = async () => {
    const data = await getInfluencers(page - 1, filters);
    if (!data.statusCode) {
      setUsers(data);
    }
  };

  /*  useEffect(() => {
    fetchTags();
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [page, debouncedFilters]);

  useEffect(() => {
    calculatePagination();
  }, [users]);*/

  useEffect(() => {
    setFilters((prevData) => ({
      ...prevData,
      name: debouncedSearch ? debouncedSearch.toUpperCase() : null,
    }));
    setPage(1);
  }, [debouncedSearch]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleOnChange = (position) => {
    const updatedCheckedState = filterValues.map((item, index) =>
      index === position ? !item : item
    );
    setFilterValues(updatedCheckedState);
  };

  const tagFilter = () => {
    const checkedIndexes = filterValues
      ?.map((bool, index) => (bool === true ? index : null))
      .filter((el) => el !== null);
    const result = checkedIndexes?.map((index) => filtersArr[index]);
/*    result.length !== filters?.userTags?.length
      ? setFilters((prevData) => ({ ...prevData, userTags: result }))
      : null;
    setPage(1);*/
  };

  useEffect(() => {
    tagFilter();
  }, [filterValues]);

  const toggleOpenMenu = (index) => {
    const updatedMenu = [...openMenu];
    updatedMenu[index] = !updatedMenu[index];
    setOpenMenu(updatedMenu);
  };

  const handleInfluencerDelete = (influencerInfo) => {
    setChosenInfluencer(influencerInfo);
    setOpenConfirmDeletionModal(true);
  };

  const cancelDelete = () => {
    setChosenInfluencer();
    setOpenConfirmDeletionModal(false);
  };

  const handleInfluencerStatus = (influencerInfo) => {
    setChosenInfluencer(influencerInfo);
    setOpenConfirmStatusChangeModal(true);
  };

  const cancelStatusChange = () => {
    setChosenInfluencer();
    setOpenConfirmStatusChangeModal(false);
  };

  const userStatusChange = async (bool) => {
    await editInfluencer(chosenInfluencer?.username, { active: bool }).then((res) => {
      if (!res.message) {
        showToast(
          bool ? "Usuário ativado com sucesso" : "Usuário desativado com sucesso!",
          "success"
        );
        fetchUsers();
        cancelStatusChange();
      }
      if (res.message) {
        showToast("Falha ao alterar dados", "error");
      }
    });
  };

  const handleUserDelete = async (username) => {
    await deleteUser(username).then((res) => {
      if (!res.message) {
        showToast("Usuário deletado com sucesso!", "success");
        fetchUsers();
        cancelDelete();
      }
      if (res.message) {
        showToast("Falha ao alterar dados", "error");
      }
    });
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const mockList = [
    {
      id: 1,
      name: "Nome do Afiliado",
      email: "email@mail.com",
      username: "@username",
      status: true,
      salesTotal: 1000000,
      instagram: "mock",
      twitter: "mock",
      requestDate: "2024-03-22T19:48:36.333Z"
    },
  ];

  const filtersArr = ['Hoje', 'Última semana', 'Último mês', 'Este ano']

  return (
    <DashboardLayout
      onClick={() => (openMenu.includes(true) ? setOpenMenu(openMenu.map((bool) => false)) : null)}
    >
      <DashboardNavbar />
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <VuiBox py={1}>
        <VuiBox mb={1}>
          <Grid container item justifyContent="center">
            <Tabs
              orientation={tabsOrientation}
              value={tabValue}
              onChange={handleSetTabValue}
              sx={{ background: "transparent", display: "flex", justifyContent: "flex-end" }}
            >
              <Tab
                label="LISTA DE AFILIADOS"
                value="affiliateList"
                icon={<RiUser3Fill color="white" size="16px" />}
              />
              <Tab
                label="SOLICITAÇÕES"
                value="requests"
                icon={<RiUserAddFill color="white" size="16px" />}
              />
            </Tabs>
          </Grid>
            <S.TableCard>
              <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
                <VuiTypography variant="lg" color="white">
                  Afiliados
                </VuiTypography>
                <S.EditButton onClick={() => setOpenFilter(true)}>
                  <img src={filter} draggable="false" />
                </S.EditButton>
              </VuiBox>
              <VuiBox>
                {tabValue === "affiliateList" && (
                  <Table
                    mb={role === "ADMINISTRATOR" ? "40px" : 0}
                    columns={[
                      { name: "nome do afiliado", align: "left" },
                      { name: "email", align: "left" },
                      { name: "status", align: "center" },
                      { name: "total em vendas", align: "center" },
                      { name: "redes", align: "center" },
                    ]}
                    rows={mockList.map((affiliate, index) => {
                      return {
                        "nome do afiliado": (
                          <S.NameGrid container flexDirection="column" justifyContent="center">
                            <p className="name">
                              {affiliate?.name}
                            </p>
                            <p className="username">
                              {affiliate?.username}
                            </p>
                          </S.NameGrid>
                        ),
                        email: <p className="tableColumn">{affiliate?.email}</p>,
                        status: (
                          <S.StatusGrid container justifyContent="center">
                            <IoEllipse
                              color={affiliate?.status ? "#65EFAD" : "#D7007B"}
                              size="12px"
                            />
                            <p className="status">{affiliate?.status ? "ATIVO" : "INATIVO"}</p>
                          </S.StatusGrid>
                        ),
                        "total em vendas": <p className="tableColumn">R$ 19491919</p>,
                        redes: (
                          <Grid container justifyContent="space-between">
                            <S.SocialsGrid
                              container
                              item
                              xs={9}
                              md={11}
                              xl={11}
                              justifyContent="center"
                            >
                              {affiliate?.instagram?.username ? (
                                <Link
                                  to={`https://www.instagram.com/${affiliate?.instagram?.username}`}
                                  target="_blank"
                                >
                                  <img src={instagram} height="18px" draggable="false" />
                                </Link>
                              ) : (
                                <img
                                  src={instagram}
                                  height="18px"
                                  draggable="false"
                                  className="disabled"
                                />
                              )}
                              {affiliate?.youtube?.username ? (
                                <Link
                                  to={`https://www.youtube.com/@${affiliate?.youtube?.username}`}
                                  target="_blank"
                                >
                                  <img src={youtube} height="18px" draggable="false" />
                                </Link>
                              ) : (
                                <img
                                  src={youtube}
                                  height="18px"
                                  draggable="false"
                                  className="disabled"
                                />
                              )}
                              {affiliate?.tiktok?.username ? (
                                <Link
                                  to={`https://www.tiktok.com/@${affiliate?.tiktok?.username}`}
                                  target="_blank"
                                  className={affiliate?.instagram?.username ? "active" : "disabled"}
                                >
                                  <img src={tiktok} height="18px" draggable="false" />
                                </Link>
                              ) : (
                                <img
                                  src={tiktok}
                                  height="18px"
                                  draggable="false"
                                  className="disabled"
                                />
                              )}
                              {affiliate?.twitter?.username ? (
                                <Link
                                  to={`https://www.twitter.com/${affiliate?.twitter?.username}`}
                                  target="_blank"
                                  className={affiliate?.instagram?.username ? "active" : "disabled"}
                                >
                                  <img src={twitter} height="18px" draggable="false" />
                                </Link>
                              ) : (
                                <img
                                  src={twitter}
                                  height="18px"
                                  draggable="false"
                                  className="disabled"
                                />
                              )}
                              <img
                                src={spotify}
                                height="18px"
                                draggable="false"
                                style={{ filter: "grayscale(1)" }}
                              />
                            </S.SocialsGrid>
                            {role === "ADMINISTRATOR" && (
                              <Grid
                                item
                                xs={3}
                                md={1}
                                xl={1}
                                sx={{ alignSelf: "center" }}
                                onClick={() => toggleOpenMenu(index)}
                              >
                                <IoEllipsisVertical size="20px" style={{ cursor: "pointer" }} />
                              </Grid>
                            )}
                            {openMenu[index] && (
                              <S.OptionsContainer container justifyContent="flex-end">
                                <S.OptionsGrid display="flex" flexDirection="column">
                                  <p onClick={() => navigate(`/ecommerce/afiliados/${affiliate?.id}`)}>
                                    Detalhes
                                  </p>
                                  {/*                              <p onClick={() => handleInfluencerStatus(affiliate)}>Ativar</p>*/}
                                  <S.Divider />
                                  <p /*onClick={() => handleInfluencerDelete(affiliate)}*/>
                                    Excluir
                                  </p>
                                </S.OptionsGrid>
                              </S.OptionsContainer>
                            )}
                          </Grid>
                        ),
                      };
                    })}
                  />
                )}
                {tabValue === "requests" && (
                  <Table
                    mb={role === "ADMINISTRATOR" ? "40px" : 0}
                    columns={[
                      { name: "nome do afiliado", align: "left" },
                      { name: "email", align: "left" },
                      { name: "data de solicitação", align: "center" },
                      { name: "redes", align: "center" },
                    ]}
                    rows={mockList.map((affiliate, index) => {
                      return {
                        "nome do afiliado": (
                          <S.NameGrid container flexDirection="column" justifyContent="center">
                            <p className="name">
                              {affiliate?.name}
                            </p>
                            <p className="username">
                              {affiliate?.username}
                            </p>
                          </S.NameGrid>
                        ),
                        email: <p className="tableColumn">{affiliate?.email}</p>,
                        "data de solicitação": <p className="tableColumn">24/02/2025</p>,
                        redes: (
                          <Grid container justifyContent="space-between">
                            <S.SocialsGrid
                              container
                              item
                              xs={9}
                              md={11}
                              xl={11}
                              justifyContent="center"
                            >
                              {affiliate?.instagram?.username ? (
                                <Link
                                  to={`https://www.instagram.com/${affiliate?.instagram?.username}`}
                                  target="_blank"
                                >
                                  <img src={instagram} height="18px" draggable="false" />
                                </Link>
                              ) : (
                                <img
                                  src={instagram}
                                  height="18px"
                                  draggable="false"
                                  className="disabled"
                                />
                              )}
                              {affiliate?.youtube?.username ? (
                                <Link
                                  to={`https://www.youtube.com/@${affiliate?.youtube?.username}`}
                                  target="_blank"
                                >
                                  <img src={youtube} height="18px" draggable="false" />
                                </Link>
                              ) : (
                                <img
                                  src={youtube}
                                  height="18px"
                                  draggable="false"
                                  className="disabled"
                                />
                              )}
                              {affiliate?.tiktok?.username ? (
                                <Link
                                  to={`https://www.tiktok.com/@${affiliate?.tiktok?.username}`}
                                  target="_blank"
                                  className={affiliate?.instagram?.username ? "active" : "disabled"}
                                >
                                  <img src={tiktok} height="18px" draggable="false" />
                                </Link>
                              ) : (
                                <img
                                  src={tiktok}
                                  height="18px"
                                  draggable="false"
                                  className="disabled"
                                />
                              )}
                              {affiliate?.twitter?.username ? (
                                <Link
                                  to={`https://www.twitter.com/${affiliate?.twitter?.username}`}
                                  target="_blank"
                                  className={affiliate?.instagram?.username ? "active" : "disabled"}
                                >
                                  <img src={twitter} height="18px" draggable="false" />
                                </Link>
                              ) : (
                                <img
                                  src={twitter}
                                  height="18px"
                                  draggable="false"
                                  className="disabled"
                                />
                              )}
                              <img
                                src={spotify}
                                height="18px"
                                draggable="false"
                                style={{ filter: "grayscale(1)" }}
                              />
                            </S.SocialsGrid>
                            {role === "ADMINISTRATOR" && (
                              <Grid
                                item
                                xs={3}
                                md={1}
                                xl={1}
                                sx={{ alignSelf: "center" }}
                                onClick={() => toggleOpenMenu(index)}
                              >
                                <IoEllipsisVertical size="20px" style={{ cursor: "pointer" }} />
                              </Grid>
                            )}
                            {openMenu[index] && (
                              <S.OptionsContainer container justifyContent="flex-end">
                                <S.OptionsGrid display="flex" flexDirection="column">
                                  <p /*onClick={() => handleInfluencerStatus(affiliate)}*/>
                                    Autorizar
                                  </p>
                                  {/*                              <p onClick={() => handleInfluencerStatus(affiliate)}>Ativar</p>*/}
                                  <S.Divider />
                                  <p /*onClick={() => handleInfluencerDelete(affiliate)}*/>
                                    Negar
                                  </p>
                                </S.OptionsGrid>
                              </S.OptionsContainer>
                            )}
                          </Grid>
                        ),
                      };
                    })}
                  />
                )}
              </VuiBox>
              <Grid container justifyContent="center" mb="10px">
                <S.PaginationGrid container justifyContent="center">
                  <Pagination
                    count={pagination?.length}
                    page={page}
                    siblingCount={1}
                    boundaryCount={1}
                    onChange={handleChange}
                  />
                </S.PaginationGrid>
              </Grid>
            </S.TableCard>
        </VuiBox>
      </VuiBox>
      {(role === "ADMINISTRATOR" || role === "H_COMERCIAL") && (
        <Box sx={{ "& > :not(style)": { m: 1 } }}>
          <S.ModalFab color="primary" onClick={() => setOpenCreateAffiliateModal(true)}>
            <RiAddFill size="30px" />
          </S.ModalFab>
        </Box>
      )}
      <FilterModal size="sm" open={openFilter} onClose={() => setOpenFilter(false)}>
        <p>Filtragem por xxxx</p>
        {filtersArr.map((cat, index) => (
            <Grid container key={index}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={filterValues[index]}
                onChange={() => handleOnChange(index)}
                name={cat}
              />
              <p style={{ fontSize: "18px" }}>{cat}</p>
            </Grid>
          ))}
      </FilterModal>
      <Modal open={openCreateInfluencerModal} onClose={() => setOpenCreateInfluencerModal(false)}>
        {/*<CreateInfluencer />*/}
      </Modal>
      <Modal
        size="sm"
        open={openConfirmDeletionModal}
        onClose={() => setOpenConfirmDeletionModal(false)}
      >
        <Grid container flexDirection="column" gap="20px">
          <p style={{ fontSize: "24px" }}>Confirmar exclusão</p>
          <p style={{ fontSize: "18px" }}>Deseja excluir essa entrada? Essa ação é irreversível</p>
          <Grid container gap="15px" justifyContent="center">
            <Grid item lg={3}>
              <ButtonCustom
                label="Cancelar"
                onClick={() => cancelDelete()}
                sx={{ background: "linear-gradient(180deg, #818181 0%, #2a2020 155.15%)" }}
              ></ButtonCustom>
            </Grid>
            <Grid item lg={3}>
              <ButtonCustom
                label="Confirmar"
                onClick={() => handleUserDelete(chosenInfluencer?.username)}
              ></ButtonCustom>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal size="sm" open={openConfirmStatusChangeModal} onClose={() => cancelStatusChange()}>
        <Grid container flexDirection="column" gap="20px">
          <p style={{ fontSize: "24px" }}>Confirmar mudança de status</p>
          <p style={{ fontSize: "18px" }}>
            Deseja {chosenInfluencer?.active ? "DESATIVAR" : "ATIVAR"} este usuário?
          </p>
          <Grid container gap="15px" justifyContent="center">
            <Grid item lg={3}>
              <ButtonCustom
                label="Cancelar"
                onClick={() => cancelStatusChange()}
                sx={{ background: "linear-gradient(180deg, #818181 0%, #2a2020 155.15%)" }}
              ></ButtonCustom>
            </Grid>
            <Grid item lg={3}>
              <ButtonCustom
                label="Confirmar"
                onClick={() => userStatusChange(!chosenInfluencer?.active)}
              ></ButtonCustom>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal open={openCreateAffiliateModal} onClose={() => setOpenCreateAffiliateModal(false)}>
        <CreateAffiliate />
      </Modal>
    </DashboardLayout>
  );
};

export default Affiliates;
