import { useEffect, useState } from "react";
import * as S from "./style";
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import { Grid, Tab, Tabs } from "@mui/material";
import Datepicker from "components/Datepicker/Datepicker";
import MonthDatepicker from "components/Datepicker/MonthDatepicker";
import filter from "assets/icons/filter.svg";
import {
  RiWalletFill,
  RiArrowRightUpLine,
  RiArrowUpCircleLine,
  RiArrowDownCircleLine,
  RiShoppingCart2Fill,
  RiDownload2Line,
  RiListView,
  RiLightbulbFill,
} from "react-icons/ri";
import LineChart from "defaultComponents/Charts/LineCharts/LineChart";
import Table from "defaultComponents/Tables/Table";
import { Link } from "react-router-dom";
import { getFinancialHomeData, getFinancialGraph } from "utils/requests/financial";
import { getFinancialData } from "utils/requests/financial";
import moment from "moment";
import "moment/locale/pt-br";
import useMediaQuery from "@mui/material/useMediaQuery";
import { BsQuestionLg } from "react-icons/bs";
import LightTooltip from "components/Tooltip/LightTooltip";
import breakpoints from "assets/theme/base/breakpoints";
import { MdOutlineListAlt } from "react-icons/md";
import PieChart from "defaultComponents/Charts/PieCharts/PieChart";
import DoubleBarChart from "defaultComponents/Charts/BarCharts/DoubleBarChart";
import BarChart from "defaultComponents/Charts/BarCharts/BarChart";
import { abbreviateNumbers } from "utils/utils";
import DownloadSummary from "./Download/DownloadSummary";
import DownloadInsights from "./Download/DownloadInsights";
import Modal from "components/Modal/Modal";

const Reports = () => {
  const mobile = useMediaQuery("(max-width: 992px)");

  moment.locale("pt-br");

  const formatNumbers = (val) => {
    return Number(val.toFixed(2)).toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const [pagination, setPagination] = useState([]);
  const [filterDate, setFilterDate] = useState();
  const [tableData, setTableData] = useState([]);
  const [homeData, setHomeData] = useState({});
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState("summary");
  const [openCsvModal, setOpenCsvModal] = useState(false);
  const [totalIncomePerItem, setTotalIncomePerItemChart] = useState({
    series: [
      {
        name: "Mês Anterior",
        data: ["44", "55", "41", "64", "22"],
      },
      {
        name: "Mês Atual",
        data: ["53", "32", "33", "52", "13"],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 430,
        toolbar: {
          show: false,
        },
        labels: ["Female", "Male"],
      },
      colors: ["#BE4E75", "#733278"],
      grid: {
        show: false,
      },
      legend: {
        show: false,
      },
      plotOptions: {
        bar: {
          borderRadius: 3,
          columnWidth: "12px",
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        intersect: false,
        theme: "dark",
      },
      xaxis: {
        labels: {
          style: {
            colors: "#fff",
          },
        },
        categories: ["Produto 01", "Produto 02", "Produto 03", "Produto 04", "Produto 05"],
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            colors: "#fff",
          },
          formatter: function (val) {
            return "R$ " + val;
          },
        },
      },
    },
  });

  const [mostSalesChart, setMostSalesChart] = useState({
    series: [44, 55, 41, 58],
    options: {
      chart: {
        type: "donut",
      },
      legend: {
        fontSize: "16px",
      },
      labels: ["Produto 01", "Produto 02", "Produto 03", "Produto 04"],
      colors: ["#AE2C7A", "#733278", "#47C094", "#552CAE"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return (
              "R$ " +
              Number(val.toFixed(2)).toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            );
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  const [incomeOriginChart, setIncomeOriginChart] = useState({
    series: [44, 55, 41, 58, 60],
    options: {
      chart: {
        type: "donut",
      },
      legend: {
        fontSize: "16px",
      },
      labels: ["Link Afiliado", "Cupom", "Orgânico", "Marketing", "Venda Direta"],
      colors: ["#50859D", "#47C094", "#DB972C", "#744C75", "#92181C"],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return (
              "R$ " +
              Number(val.toFixed(2)).toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            );
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  const [incomeBySalesmanChart, setIncomeBySalesmanChartData] = useState({
    series: [
      {
        name: "",
        data: [500550, 456590, 300000, 200000, 100000],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          barHeight: "85%",
          distributed: true,
          horizontal: true,
          borderRadius: 15,
          borderRadiusApplication: "end",
          dataLabels: {
            position: "bottom",
          },
        },
      },
      grid: {
        borderColor: "#56577A",
        strokeDashArray: 10,
        yaxis: {
          lines: {
            show: false,
          },
        },
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      legend: {
        show: false,
      },
      stroke: {
        colors: ["transparent"],
        width: 5,
      },
      colors: ["#44956B", "#D85308", "#D85308", "#D85308", "#D85308", "#D85308"],
      dataLabels: {
        enabled: false,
        textAnchor: "start",
        style: {
          colors: ["#fff"],
        },
      },
      xaxis: {
        categories: ["Bianca Z", "Eduardo F", "Teste1", "Teste2", "Teste3"],
        tickAmount: 5,
        labels: {
          formatter: (val) => {
            return abbreviateNumbers(val);
          },
          style: {
            colors: "#fff",
            fontSize: "10px",
          },
          showDuplicates: false,
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#fff",
            fontSize: "12px",
          },
        },
      },
      tooltip: {
        theme: "dark",
        x: {
          show: false,
        },
        y: {
          formatter: (val) => {
            return abbreviateNumbers(val, 2);
          },
          title: {
            formatter: function () {
              return "";
            },
          },
        },
      },
    },
  });

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.lg
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  /*  const fetchTableData = async () => {
    const startDate = filterDate ? moment(filterDate[0], "DD-MM-YYYY HH:mm:ss").valueOf() : "";
    const endDate = filterDate ? moment(filterDate[1], "DD-MM-YYYY HH:mm:ss").valueOf() : "";
    const data = await getFinancialData(
      0,
      5,
      { type: ["ENTRADA", "SAIDA", "TRANSFERENCIA"] },
      startDate,
      endDate
    );
    if (!data.statusCode) {
      setTableData(data);
    }
  };*/

  /*  const fetchHomeData = async () => {
    const startDate = filterDate ? moment(filterDate[0], "DD-MM-YYYY HH:mm:ss").valueOf() : "";
    const endDate = filterDate ? moment(filterDate[1], "DD-MM-YYYY HH:mm:ss").valueOf() : "";
    if (startDate && endDate) {
      const data = await getFinancialHomeData(startDate, endDate);
      if (!data.statusCode) {
        setHomeData(data);
      }
    }
  };

  useEffect(() => {
    fetchTableData();
    fetchHomeData();
  }, [filterDate]);*/

  const QuestionTooltip = ({ message }) => (
    <LightTooltip title={message}>
      <div style={{ width: "fit-content", textAlign: "right", fontSize: "16px" }}>
        <BsQuestionLg style={{ backgroundColor: "#1e1e1e", borderRadius: "5px" }} />
      </div>
    </LightTooltip>
  );

  const mockOrders = [
    {
      order: "1000000",
      date: "2025-02-15T03:00:00.000Z",
      affiliate: "Nome Afiliado",
      value: "10000",
      payment: "Débito",
      comission: 100,
      items: 100,
    },
    {
      order: "1000001",
      date: "2025-02-14T03:00:00.000Z",
      affiliate: "Nome Afiliado",
      value: "1045678",
      payment: "Crédito",
      comission: 100,
      items: 100,
    },
    {
      order: "1000002",
      date: "2025-02-13T03:00:00.000Z",
      affiliate: "Nome Afiliado",
      value: "984567",
      payment: "PIX",
      comission: 100,
      items: 100,
    },
    {
      order: "1000003",
      date: "2025-02-12T03:00:00.000Z",
      affiliate: "Nome Afiliado",
      value: "1356789",
      payment: "Débito",
      comission: 100,
      items: 100,
    },
    {
      order: "1000004",
      date: "2025-02-11T03:00:00.000Z",
      affiliate: "Nome Afiliado",
      value: "19234",
      payment: "Crédito",
      comission: 100,
      items: 100,
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <S.MainContainer container spacing="18px">
        <Grid container item justifyContent="center">
          <Tabs
            orientation={tabsOrientation}
            value={tabValue}
            onChange={handleSetTabValue}
            sx={{ background: "transparent", display: "flex", justifyContent: "flex-end" }}
          >
            <Tab
              label="RESUMO DE PEDIDOS"
              value="summary"
              icon={<MdOutlineListAlt color="white" size="16px" />}
            />
            <Tab
              label="INSIGHTS DE VENDAS"
              value="insights"
              icon={<RiLightbulbFill color="white" size="16px" />}
            />
          </Tabs>
        </Grid>
        <Grid container item sm={12} md={12} lg={12} xl={12} justifyContent="space-between">
          <S.DateGrid container item md={7.5} lg={7.5} xl={6.5}>
            <MonthDatepicker setDate={setFilterDate} allButton />
            <p style={{ color: "#fff" }}>Relatório de Vendas</p>
          </S.DateGrid>
          <Grid container item md={4.5} lg={4.5} xl={5.5} justifyContent="flex-end" gap="10px">
            <S.EditButton onClick={() => setOpenCsvModal(true)} style={{ padding: 0 }}>
              <RiDownload2Line size="25px" color="white" />
            </S.EditButton>
            <S.EditButton /*onClick={() => setOpenFilter(!openFilter)}*/>
              <img src={filter} />
            </S.EditButton>
          </Grid>
        </Grid>
        <Grid container item sm={12} md={12} lg={12} xl={12} justifyContent="space-between">
          <Grid item>
            <Card>
              <S.BlockGrid container justifyContent="space-between">
                <Grid>
                  <Grid container gap="10px">
                    <p className="title">Faturamento Total</p>
                    {/*<QuestionTooltip message="Todo valor negociado no mês vigente, mesmo não recebido." />*/}
                  </Grid>
                  <Grid container>
                    <p className="value">
                      R${" "}
                      {
                        /*homeData?.totalData && homeData?.totalData?.inbound.value !== 0*/ true
                          ? Number(
                              /*homeData?.totalData?.inbound?.value*/ (8000000 / 100).toFixed(2)
                            ).toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : 0
                      }
                    </p>
                    <p
                      className={`percentage ${
                        homeData?.totalData?.inbound?.up ? "positive" : "negative"
                      }`}
                      // remover o - quando integrar
                    >{`${homeData?.totalData?.inbound?.up ? "+" : "-"}${
                      /*homeData?.totalData?.inbound?.percentual*/ 15
                    }%`}</p>
                  </Grid>
                </Grid>
                <Grid>
                  <S.IconBlock>
                    <RiWalletFill color="#fff" size="30px" />
                  </S.IconBlock>
                </Grid>
              </S.BlockGrid>
            </Card>
          </Grid>
          <Grid item>
            <Card>
              <S.BlockGrid container justifyContent="space-between">
                <Grid>
                  <Grid container gap="10px">
                    <p className="title">Número Total de Pedidos</p>
                    {/*<QuestionTooltip message="Todo valor dado como recebido no mês." />*/}
                  </Grid>
                  <Grid container>
                    <p className="value">
                      {
                        /*homeData?.totalData && homeData?.totalData?.netValue.value !== 0*/ true
                          ? Number(/*homeData?.totalData?.netValue.value*/ 10000).toLocaleString(
                              "pt-BR",
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                              }
                            )
                          : 0
                      }
                    </p>
                    <p
                      className={`percentage ${
                        homeData?.totalData?.netValue?.up ? "positive" : "negative"
                      }`}
                      // remover o - quando integrar
                    >{`${/*homeData?.totalData?.netValue?.up*/ false ? "+" : "-"}${
                      /*homeData?.totalData?.netValue?.percentual*/ 5
                    }%`}</p>
                  </Grid>
                </Grid>
                <Grid>
                  <S.IconBlock>
                    <RiShoppingCart2Fill color="#fff" size="30px" />
                  </S.IconBlock>
                </Grid>
              </S.BlockGrid>
            </Card>
          </Grid>
          <Grid item>
            <Card>
              <S.BlockGrid container justifyContent="space-between">
                <Grid>
                  <Grid container gap="10px">
                    <p className="title">Ticket Médio</p>
                    {/*<QuestionTooltip message="Saídas e transferências do mês." />*/}
                  </Grid>
                  <Grid container>
                    <p className="value">
                      R${" "}
                      {
                        /*homeData?.totalData && homeData?.totalData?.outbound?.value !== 0*/ true
                          ? Number(
                              /*homeData?.totalData?.outbound?.value / 100*/ (10000).toFixed(2)
                            ).toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : 0
                      }
                    </p>
                    <p
                      className={`percentage ${
                        homeData?.totalData?.outbound?.up ? "negative" : "positive"
                      }`}
                    >{`${/*homeData?.totalData?.outbound?.up*/ true ? "+" : ""}${
                      /*homeData?.totalData?.outbound?.percentual*/ 85
                    }%`}</p>
                  </Grid>
                </Grid>
                <Grid>
                  <S.IconBlock>
                    <RiWalletFill color="#fff" size="30px" />
                  </S.IconBlock>
                </Grid>
              </S.BlockGrid>
            </Card>
          </Grid>
        </Grid>
        {tabValue === "summary" && (
          <S.CardGrid item sm={12} md={12} lg={12} xl={12}>
            <Card>
              <Grid item sm={12} md={12} lg={12} xl={12} alignItems="stretch">
                <S.TableGrid>
                  <Table
                    columns={[
                      { name: "pedido", align: "left" },
                      { name: "data", align: "left" },
                      { name: "afiliado", align: "left" },
                      { name: "valor", align: "left" },
                      { name: "forma de pagamento", align: "left" },
                      { name: "comissão", align: "left" },
                      { name: "itens", align: "left" },
                    ]}
                    rows={mockOrders.map((entry, index) => {
                      return {
                        pedido: <p style={{ color: "#FC6508" }}>#{entry?.order}</p>,
                        data: <p>{moment(entry?.date).format("L")}</p>,
                        afiliado: <p>{entry?.affiliate}</p>,
                        valor: (
                          <Grid container>
                            <p>
                              R${" "}
                              {Number((entry?.value / 100).toFixed(2)).toLocaleString("pt-BR", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </p>
                          </Grid>
                        ),
                        "forma de pagamento": <p>{entry?.payment}</p>,
                        comissão: <p>{entry?.comission ?? ""}%</p>,
                        itens: <p>{entry?.items ?? ""} itens</p>,
                      };
                    })}
                  />
                </S.TableGrid>
              </Grid>
            </Card>
          </S.CardGrid>
        )}
        {tabValue === "insights" && (
          <Grid container item gap="20px">
            <Grid item xs={12} lg={12} xl={12}>
              <Card>
                <Grid container>
                  <Grid item md={6} xl={6}>
                    <p>Produtos Mais Vendidos</p>
                    <S.PieChartGrid item xl={10}>
                      <PieChart
                        pieChartData={mostSalesChart.series}
                        pieChartOptions={mostSalesChart.options}
                      />
                    </S.PieChartGrid>
                  </Grid>
                  <Grid item md={6} xl={6}>
                    <p>Renda Total Por Item</p>
                    <S.AgeChartGrid>
                      <DoubleBarChart
                        doubleBarChartData={totalIncomePerItem.series}
                        doubleBarChartOptions={totalIncomePerItem.options}
                      />
                    </S.AgeChartGrid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} lg={12} xl={12}>
              <Card>
                <Grid container>
                  <Grid item md={6} xl={6}>
                    <p>Origem Das Receitas</p>
                    <S.PieChartGrid item xl={10}>
                      <PieChart
                        pieChartData={incomeOriginChart.series}
                        pieChartOptions={incomeOriginChart.options}
                      />
                    </S.PieChartGrid>
                  </Grid>
                  <Grid item md={6} xl={6}>
                    <p>Faturamento Por Vendedores</p>
                    <S.AgeChartGrid>
                      <BarChart
                        key={incomeBySalesmanChart?.series[0]?.data}
                        barChartData={incomeBySalesmanChart?.series}
                        barChartOptions={incomeBySalesmanChart?.options}
                      />
                    </S.AgeChartGrid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        )}
      </S.MainContainer>
      <Modal open={openCsvModal} onClose={() => setOpenCsvModal(false)} size="sm">
        {tabValue === "summary" ? <DownloadSummary /> : <DownloadInsights />}
      </Modal>
    </DashboardLayout>
  );
};

export default Reports;
