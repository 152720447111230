import styled from "styled-components"
import { Button, Grid } from "@mui/material"
import VuiBox from "components/VuiBox";
import { NavLink } from "react-router-dom";

export const LogoutButton = styled(Button)`
  border-radius: 5px 5px 15px 5px !important;
  height: 60px !important;
  width: 100%;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%), #C4C4C4;
  color: #FFF !important;
  font-size: 16px !important;
  gap: 5px;
  justify-content: start !important;

  svg {
    margin-left: 20px;
  }
`

export const BottomBox = styled(VuiBox)`
  p {
    color: white;
    opacity: 0.5;
    font-size: 12px;
    text-align: center;
    padding-top: 15px;
  }

  .versions {
    cursor: pointer;
  }
`

export const ChildrenContainer = styled(Grid)`
  margin-top: 10px;
  gap: 10px;
`

export const SubRouteLink = styled(NavLink)`
  margin: 0 2.5rem;
  padding: 0.3rem 0.8rem 0.3rem 0.8rem;
  border-radius: 8px;
`