import { useState, useEffect } from 'react';
import { Grid } from '@mui/material'
import { Formik, Form, useFormik } from 'formik';
import * as S from './style'
import * as Yup from "yup";
import showToast from 'components/Toast/Toast';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import "assets/theme/custom-flatpickr.css";
import { editProduct, getProductsSubCategories, getProductsCategories } from '../product';
import 'moment/locale/pt-br';
import Autocomplete from '@mui/material/Autocomplete';
import { IoAlertCircleOutline } from 'react-icons/io5';
import MaskMoney from 'components/Masks/MaskMoney';
import { MdCloudUpload } from "react-icons/md";
import { useFilePicker } from 'react-sage';
import DeleteIcon from '@mui/icons-material/Delete';
import Switch from '@mui/material/Switch';

const EditProduct = ({ product, refetch, closeModal }) => {
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [productImage, setProductImage] = useState('');
  const [previewUrl, setPreviewUrl] = useState(product?.backgroundPhoto ?? null);

  const imageInput = useFilePicker({ maxFileSize: 1, resizeImage: true });
 
  useEffect(() => {
    const getDataUrls = async () => {
      if (imageInput.files && imageInput.files[0]) {
        setProductImage(imageInput.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(imageInput.files[0]);
        reader.onload = () => {
          setPreviewUrl(reader.result);
        };
      };
    };
    getDataUrls();
  }, [imageInput.files]);

  const deleteProductImage = () => {
    setProductImage('');
    setPreviewUrl(null);
  }; 

  const fetchSubCategories = async () => {
    const data = await getProductsSubCategories();
    if (!data.statusCode) setSubCategories(data);
  };

  useEffect(() => {
    fetchSubCategories();
  }, []);

  const fetchCategories = async () => {
    const data = await getProductsCategories();
    if (!data.statusCode) setCategories(data);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const validation = useFormik({

    initialValues: {
      name: product?.name || '',
      metaName: product?.metaName || '',
      price: product?.price || '',
      description: product?.description || '',
      visible: product?.visible || false,
      subCategoryIds: product?.subCategories || [],
      categoryIds: product?.categories || [],
      imageUrl: product?.imageUrl || ["url default"],
      stock: product?.stock ? { name: product.stock } : [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Nome é obrigatório"),
      metaName: Yup.string().required("Meta Nome é obrigatório"),
      price: Yup.string().required("Preço é obrigatório"),
      description: Yup.string(),
      visible: Yup.boolean(),
      subCategoryIds: Yup.array(),
      categoryIds: Yup.array(),
      imageUrl: Yup.array(),
      stock: Yup.object().required("Selecione um tipo de estoque"),
    }),
    onSubmit: async (values) => {
      const startProductValues = validation.initialValues;

      const edit = {
        name: values.name !== startProductValues.name ? values.name : '',
        metaName: values.metaName !== startProductValues.metaName ? values.metaName : '',
        price: values.price !== startProductValues.price ? values.price : '',
        description: values.description !== startProductValues.description ? values.description : '',
        visible: values.visible !== startProductValues.visible ? values.visible : '',
        categoryIds: values.categoryIds !== startProductValues.categoryIds ? values.categoryIds : '',
        subCategoryIds: values.subCategoryIds.length ? values.subCategoryIds : '',
        imageUrl: values.imageUrl !== startProductValues.imageUrl ? values.imageUrl : '',
        stock: values.stock?.name === "Estoque" ? String(values.quantity || "0") : String(values.stock?.name || ''),
      };

      Object.keys(edit).forEach((key) => {
        if (edit[key] === "") {
          delete edit[key];
        }
      });

      if (Object.keys(edit).length === 0) {
        showToast("Nenhuma alteração detectada.", "warning");
        return;
      }

      try {
        const payload = {
          ...edit,
          id: product?.id,
        };
        
        const res = await editProduct(payload, edit);
        
        if (!res.message) {
          showToast("Dados alterados com sucesso!", "success");
          refetch();
          closeModal();
        } else {
          showToast("Falha ao alterar dados", "error");
        }
      } catch (error) {
        console.error('Erro ao editar o produto:', error);
        showToast("Erro ao editar o produto", "error");
      }
    }
  });

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleSwitchChange = (event) => {
    validation.setFieldValue('visible', event.target.checked);
  };

  return (
    <Grid container flexDirection='column'>
      <p>Edição de Produto</p>
      <Formik initialValues={validation.initialValues} validationSchema={validation.validationSchema} onSubmit={validation.handleSubmit}>
        <Form>
          <Grid container flexDirection='column' gap='20px' mt='15px'>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} xl={12}>
                <S.Input
                  hiddenLabel
                  name='name'
                  placeholder="Nome do Produto*"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  error={validation.errors.name}
                  helperText={validation.errors.name ? 'Nome inválido' : false}
                />
              </S.InputGrid>
            </Grid>
            <Grid container gap='20px' justifyContent='space-between'>
              <S.InputGrid item xs={12} xl={12}>
                <S.Input
                  hiddenLabel
                  multiline
                  name='description'
                  placeholder="Descrição"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.description || ""}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <Autocomplete
                  disablePortal
                  disableCloseOnSelect
                  multiple
                  name='subCategoryIds'
                  id='subCategoryIds'
                  value={subCategories.filter((option) =>
                    validation.values.subCategoryIds.includes(option.id)
                  )}
                  onChange={(e, value) => {
                    const ids = value.map((item) => item.id); 
                    validation.setFieldValue("subCategoryIds", ids);
                  }}
                  options={subCategories ? subCategories : []}
                  sx={{ width: "100%" }}
                  renderInput={(params) => <S.Input {...params} placeholder='Categorias*'/>}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option?.name === value?.name}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <Autocomplete
                  disablePortal
                  disableCloseOnSelect
                  multiple
                  name='categoryIds'
                  id='categoryIds'
                  value={categories.filter((option) =>
                    validation.values.categoryIds.includes(option.id)
                  )}
                  onChange={(e, value) => {
                    const ids = value.map((item) => item.id); 
                    validation.setFieldValue("categoryIds", ids);
                  }}
                  options={categories ? categories : []}
                  sx={{ width: "100%" }}
                  renderInput={(params) => <S.Input {...params} placeholder='Creators*'/>}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option?.name === value?.name}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} xl={12}>
                <S.Input
                  hiddenLabel
                  name='metaName'
                  placeholder="Meta Nome*"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.metaName || ""}
                  error={validation.errors.metaName}
                  helperText={validation.errors.metaName ? 'Meta nome inválido' : false}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={validation.values.stock?.name === "Estoque" ? 4.5 : 5.85} xl={validation.values.stock?.name === "Estoque" ? 4.5 : 5.85}>
                <S.Input
                  name='price'
                  placeholder='Preço*'
                  value={validation.values.price}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  error={validation.errors.price}
                  helperText={validation.errors.price ? 'Preço inválido' : false}
                  InputProps={{ inputComponent: MaskMoney }}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={validation.values.stock?.name === "Estoque" ? 4.5 : 5.85} xl={validation.values.stock?.name === "Estoque" ? 4.5 : 5.85}>
                <Autocomplete
                  name="stock"
                  disablePortal
                  value={validation.values.stock ? validation.values.stock : null}
                  onChange={(e, value) => {
                    validation.setFieldValue('stock', value);
                  }}
                  id="combo-box-demo"
                  options={[
                    { name: "Pré-venda" },
                    { name: "Estoque" },
                    { name: "Sem estoque" }
                  ]}
                  sx={{ width: "100%" }}
                  renderInput={(params) => <S.Input {...params} placeholder="Estoque*" error={validation.errors.stock && validation.touched.stock}
                  helperText={validation.errors.stock ? validation.errors.stock : false}/>}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.name === value?.name}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={2.7} xl={2.7}>
                {validation.values.stock?.name === "Estoque" && (
                  <S.Input
                    type="number"
                    placeholder="Quantidade"
                    value={validation.values.quantity || ""}
                    onChange={(e) => validation.setFieldValue('quantity', String(e.target.value))}
                  />
                )}
              </S.InputGrid>
            </Grid>
            <Grid item md={12} xl={12}>
              <S.ImageUploadContainer container onClick={imageInput.onClick} sx={{ border: (productImage || previewUrl) ? 'transparent' : '0.4px solid rgba(255, 255, 255, 0.50)' }}>
                {!productImage && !previewUrl &&
                  <S.ImageUploadGrid container justifyContent='center' flexDirection='column' alignItems='center'>
                    <MdCloudUpload color='white' size='60px' />
                    <p>Enviar imagens do produto</p>
                    <p>Tipos de arquivos compatíveis: .jpg, .jpeg, .png</p>
                    <imageInput.HiddenFileInput accept=".jpg, .jpeg, .png, .mp4" />
                  </S.ImageUploadGrid>
                }
                {(productImage || previewUrl) &&
                  <>
                    <img src={previewUrl} className='previewImg' alt="Preview da imagem" />
                    <S.DeleteFab size="small" onClick={() => deleteProductImage()}>
                      <DeleteIcon />
                    </S.DeleteFab>
                  </>
                }
              </S.ImageUploadContainer>
            </Grid>
            <Grid container justifyContent="space-between" alignItems="center">
              <p>Visibilidade do produto:</p>
              <Switch
                checked={validation.values.visible}
                onChange={handleSwitchChange}
                inputProps={{ 'aria-label': 'Controle de visibilidade do produto' }}
              />
            </Grid>
            <S.AlertGrid container gap='5px' mt='15px'>
              <IoAlertCircleOutline size='18px' color='red' style={{ alignSelf: "center" }} />
              <p className='bold'>Lembrete: </p>
              <p>* indica um campo obrigatório.</p>
            </S.AlertGrid>
          </Grid>
          <Grid container justifyContent='flex-end' mt='15px'>
            <ButtonCustom
              label='Salvar'
              type='submit'
              sx={{ width: "150px", height: "40px !important", fontSize: "18px", fontWeight: "500" }}
            />
          </Grid>
        </Form>
      </Formik>
    </Grid>
  )
}

export default EditProduct;