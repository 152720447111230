import { useState, useContext, useEffect, useRef } from "react";
import { Card, Grid, Stack } from "@mui/material";
import balance from "assets/images/balanceBG.png";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { FaEllipsisH } from "react-icons/fa";
import { IoAlertCircleOutline, IoEllipsisVertical, IoCloudUploadOutline } from "react-icons/io5";
import funds from "assets/icons/funds.svg";
import * as S from "./style";
import moment from "moment";
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import Table from "defaultComponents/Tables/Table";
import { RiEyeFill } from "react-icons/ri";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import { BsClockFill } from "react-icons/bs";
import Modal from "components/Modal/Modal";
import { requestWithdraw } from "utils/requests/withdraw";
import { TokenContext } from "context/TokenContext";
import { useParams } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { getInfluencerFinancial } from "utils/requests/financial";
import showToast from "components/Toast/Toast";
import * as T from "components/Toast/style";
import { getUserWithdrawals } from "utils/requests/withdraw";
import { reportWithdraw } from "utils/requests/withdraw";
import { uploadProjectFile } from "utils/requests/project";
import MaintenanceModal from "../Cashout/MaintenanceModal";

const CreditBalance = ({
  openActualModal,
  openFutureModal,
  financialData,
  actualButton,
  futureButton,
  setOpenMaintenanceModal,
}) => {
  const currentBalance = Number(
    ((financialData.monthlyBalance?.currentBalance ?? 0) / 100).toFixed(2)
  ).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  return (
    <Card sx={{ padding: "0", background: "none", backgroundColor: "none" }}>
      <Grid container justifyContent="space-between">
        <Grid display="flex" flexDirection="column" item md={3.8} xl={3.8}>
          <S.CardBox
            p="20px"
            display="flex"
            flexDirection="row"
            sx={{
              backgroundImage: `url(${balance})`,
              backgroundSize: "cover",
              borderRadius: "18px",
            }}
          >
            <Grid>
              <VuiBox display="flex" justifyContent="space-beetween" alignItems="center">
                <VuiTypography variant="caption" color="white" fontWeight="medium" mr="auto">
                  Saldo positivo atual
                </VuiTypography>
              </VuiBox>
              <VuiBox display="flex" justifyContent="space-beetween" alignItems="center">
                <VuiTypography
                  variant="h2"
                  color="white"
                  fontWeight="bold"
                  mr="auto"
                  sx={{ textWrap: "nowrap" }}
                >
                  R$ {currentBalance ?? 0}
                </VuiTypography>
              </VuiBox>
              <VuiBox display="flex" gap="10px">
                <p style={{ color: "white", opacity: "0.7", fontSize: "16px", marginTop: "2px" }}>
                  Saldo futuro:
                </p>
                <p style={{ color: "#5ACC93", fontSize: "18px" }}>
                  {" "}
                  +R${" "}
                  {Number(
                    ((financialData?.monthlyBalance?.pendingBalance ?? 0) / 100).toFixed(2)
                  ).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </p>
              </VuiBox>
            </Grid>
          </S.CardBox>
          <Grid container flexDirection="column" gap="10px"></Grid>
        </Grid>
        <Grid item md={3.8} xl={3.8}>
          {!actualButton && financialData.monthlyBalance?.currentBalance > 0 && (
            <S.ActiveButton /* onClick={openActualModal} */ onClick={() => setOpenMaintenanceModal(true)}>
              <div>
                <p className="subTitle">Solicitar Saldo Atual</p>
                <p className="info">até 48h para cair após solicitar.</p>
              </div>
            </S.ActiveButton>
          )}
          {actualButton && (
            <S.DisabledButton disabled>
              <div>
                <p className="subTitle">Solicitar Saldo Atual</p>
                <p className="info">Já há uma solicitação em andamento</p>
              </div>
            </S.DisabledButton>
          )}
          {!actualButton && financialData.monthlyBalance?.currentBalance <= 0 && (
            <S.DisabledButton disabled>
              <div>
                <p className="subTitle">Solicitar Saldo Atual</p>
                <p className="info">Não há saldo disponível</p>
              </div>
            </S.DisabledButton>
          )}
        </Grid>
        <Grid item md={3.8} xl={3.8}>
          {!futureButton && financialData?.monthlyBalance?.pendingBalance > 0 && (
            <S.ActiveButton /* onClick={openFutureModal} */ onClick={() => setOpenMaintenanceModal(true)}>
              <div>
                <p className="subTitle">Solicitar Saldo Futuro</p>
                <p className="info">
                  até 48h para resposta de aprovação ou recusa, com diminuição de 5% do valor.
                </p>
              </div>
            </S.ActiveButton>
          )}
          {futureButton && (
            <S.DisabledButton disabled>
              <div>
                <p className="subTitle">Solicitar Saldo Futuro</p>
                <p className="info">Ja há uma solicitação em andamento</p>
              </div>
            </S.DisabledButton>
          )}
          {!futureButton && financialData?.monthlyBalance?.pendingBalance <= 0 && (
            <S.DisabledButton disabled>
              <div>
                <p className="subTitle">Solicitar Saldo Futuro</p>
                <p className="info">Não há saldo disponível</p>
              </div>
            </S.DisabledButton>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

const InfluencerCashout = () => {
  const [openConfirmActualCashoutModal, setOpenConfirmActualCashoutModal] = useState(false);
  const [openConfirmFutureCashoutModal, setOpenConfirmFutureCashoutModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [openAttachNfModal, setOpenAttachNfModal] = useState(false);
  const [openMenu, setOpenMenu] = useState([]);
  const [page, setPage] = useState(1);
  const [financialData, setFinancialData] = useState([]);
  const [cashoutData, setCashoutData] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [chosenEntry, setChosenEntry] = useState();
  const [nfFile, setNfFile] = useState();
  const [openMaintenanceModal, setOpenMaintenanceModal] = useState(false);

  const hiddenFileInput = useRef(null);

  const handleFileInputClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = (event) => {
    const fileUploaded = event.target.files[0];
    setNfFile(fileUploaded);
  };

  const toggleOpenMenu = (index) => {
    const updatedMenu = [...openMenu];
    updatedMenu[index] = !updatedMenu[index];
    setOpenMenu(updatedMenu);
  };

  const { decodedToken } = useContext(TokenContext);

  const params = useParams();

  const fetchData = async () => {
    await getInfluencerFinancial(params?.username ?? decodedToken?.username, page - 1, 8, [
      "OUTCOME",
    ]).then((res) => {
      if (!res?.message) {
        setFinancialData(res);
      }
    });
    await getUserWithdrawals(params?.username ?? decodedToken?.username, page - 1, 9).then(
      (res) => {
        if (!res?.message) {
          setCashoutData(res);
        }
      }
    );
  };

  const calculatePagination = () => {
    const roundUpArrSize = Math.ceil(cashoutData[1] / 9);
    const paginationArr = Array.from({ length: roundUpArrSize }, (value, index) => index);
    setPagination(paginationArr);
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    if (cashoutData) calculatePagination();
  }, [cashoutData]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const attachNfToWithdraw = async (id, setOpen, message) => {
    await uploadProjectFile({ id: id, file: nfFile }).then((res) => {
      if (!res.message) {
        showToast(message, "success");
        setNfFile();
        setOpen(false);
        fetchData();
        return;
      }
      showToast("Falha ao anexar nota fiscal, contate o suporte", "error");
    });
  };

  const requestActualWithdraw = async () => {
    const withdraw = {
      value: financialData?.monthlyBalance?.currentBalance,
      type: "CURRENT_BALANCE",
      miscInfo: "",
      influencerRelated: decodedToken?.id,
    };
    await requestWithdraw(withdraw).then((res) => {
      if (!res.message && !nfFile?.name) {
        showToast("Saque solicitado com sucesso!", "success");
        setOpenConfirmActualCashoutModal(false);
        fetchData();
        return;
      }
      if (!res.message && nfFile?.name) {
        attachNfToWithdraw(
          res?.id,
          setOpenConfirmActualCashoutModal,
          "Saque solicitado com sucesso"
        );
        return;
      }
      showToast("Falha ao solicitar saque", "error");
    });
  };

  const requestFutureWithdraw = async () => {
    const withdraw = {
      value: financialData?.monthlyBalance?.pendingBalance,
      type: "FUTURE_BALANCE",
      miscInfo: "",
      influencerRelated: decodedToken?.id,
    };
    await requestWithdraw(withdraw).then((res) => {
      if (!res.message) {
        showToast("Saque solicitado com sucesso!", "success");
        setOpenConfirmFutureCashoutModal(false);
        fetchData();
        return;
      }
      showToast("Falha ao solicitar saque", "error");
    });
  };

  const reportChosenWithdrawal = async () => {
    await reportWithdraw(chosenEntry?.id).then((res) => {
      if (!res.message) {
        showToast("Saque reportado com sucesso!", "success");
        setOpenReportModal(false);
        return;
      }
      showToast(res?.message, "error");
    });
  };

  const statusColor = {
    APPROVED: "#5ACC93",
    REJECTED: "#FF1A9D",
    PENDING: "#E86A34",
  };

  const statusTranslation = {
    APPROVED: "RESGATE APROVADO",
    REJECTED: "RESGATE RECUSADO",
    PENDING: "EM PROCESSAMENTO",
  };

  return (
    <DashboardLayout
      onClick={() => (openMenu.includes(true) ? setOpenMenu(openMenu.map((bool) => false)) : null)}
    >
      <DashboardNavbar />
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Grid container flexDirection="column">
        <CreditBalance
          openActualModal={() => setOpenConfirmActualCashoutModal(true)}
          openFutureModal={() => setOpenConfirmFutureCashoutModal(true)}
          financialData={financialData}
          actualButton={cashoutData?.hasPendingWithdraws?.current}
          futureButton={cashoutData?.hasPendingWithdraws?.future}
          setOpenMaintenanceModal={setOpenMaintenanceModal}
        />
        <Card sx={{ maxHeight: "580px", overflow: "auto" }}>
          <S.TableGrid container flexDirection="column">
            <p>Histórico</p>
            <Grid container flexDirection="column">
              <Table
                columns={[
                  { name: "status", align: "left" },
                  { name: "valor", align: "left" },
                  { name: "solicitação", align: "left" },
                  { name: "resposta", align: "left" },
                  { name: "observações", align: "left" },
                ]}
                rows={
                  cashoutData?.withdrawPleas &&
                  cashoutData.withdrawPleas[0]?.map((entry, index) => {
                    return {
                      status: (
                        <Grid container key={index}>
                          <Grid item md={8} xl={9}>
                            <p>{statusTranslation[entry?.status]}</p>
                          </Grid>
                        </Grid>
                      ),
                      valor: (
                        <Grid container>
                          <p style={{ color: statusColor[entry?.status] }}>
                            R${" "}
                            {Number((entry?.value / 100).toFixed(2)).toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </p>
                        </Grid>
                      ),
                      solicitação: (
                        <p>{entry?.createdAt ? moment(entry?.createdAt).format("L") : "-"}</p>
                      ),
                      resposta: (
                        <p>{entry?.resolvedAt ? moment(entry?.resolvedAt).format("L") : "-"}</p>
                      ),
                      observações: (
                        <Grid container flexDirection="column">
                          <Grid container justifyContent="space-between">
                            <S.MiscInfoGrid item xs={11} xl={11}>
                              <p>{entry?.miscInfo}</p>
                            </S.MiscInfoGrid>
                            <Grid item xs={1} xl={1} onClick={() => toggleOpenMenu(index)}>
                              <IoEllipsisVertical
                                size="20px"
                                style={{ cursor: "pointer", zIndex: "9999" }}
                              />
                            </Grid>
                            {openMenu[index] && (
                              <S.OptionsContainer container justifyContent="flex-end">
                                <S.OptionsGrid display="flex" flexDirection="column">
                                  <p
                                    onClick={() => {
                                      setOpenDetailsModal(true);
                                      setChosenEntry(entry);
                                    }}
                                  >
                                    Ver Detalhes
                                  </p>
                                  {entry?.type === "CURRENT_BALANCE" &&
                                    !entry?.invoiceCertificateURL && (
                                      <>
                                        <S.Divider />
                                        <p
                                          onClick={() => {
                                            setOpenAttachNfModal(true);
                                            setChosenEntry(entry);
                                          }}
                                        >
                                          Anexar Nota Fiscal
                                        </p>
                                      </>
                                    )}
                                  {entry?.status === "PENDING" && (
                                    <>
                                      <S.Divider />
                                      <p
                                        onClick={() => {
                                          setOpenReportModal(true);
                                          setChosenEntry(entry);
                                        }}
                                      >
                                        Reportar
                                      </p>
                                    </>
                                  )}
                                </S.OptionsGrid>
                              </S.OptionsContainer>
                            )}
                          </Grid>
                        </Grid>
                      ),
                    };
                  })
                }
              />
            </Grid>
          </S.TableGrid>
        </Card>
        {pagination?.length > 0 && (
          <S.PaginationGrid container justifyContent="center" sx={{ marginTop: "20px" }}>
            <Pagination
              count={pagination?.length}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              onChange={handleChange}
            />
          </S.PaginationGrid>
        )}
      </Grid>
      <Modal
        size="md"
        open={openConfirmActualCashoutModal}
        onClose={() => setOpenConfirmActualCashoutModal(false)}
      >
        <S.ModalContainer container flexDirection="column" gap="20px">
          <Grid container gap="5px">
            <p className="modalTitle">Solicitar saque de</p>
            <p className="modalTitle bold">saldo atual</p>
          </Grid>
          <p className="cashoutValue">
            R${" "}
            {Number(
              ((financialData.monthlyBalance?.currentBalance ?? 0) / 100).toFixed(2)
            ).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </p>
          <S.AlertGrid container gap="5px">
            <IoAlertCircleOutline size="24px" color="red" style={{ alignSelf: "center" }} />
            <p className="bold">Lembrete: </p>
            <p>Valor será creditado na conta em até 48h.</p>
          </S.AlertGrid>
          <Grid container justifyContent="space-between">
            <S.BorderedInputGrid>
              <S.Input
                name="description"
                id="description"
                placeholder="Insira o arquivo PDF"
                InputProps={{
                  readOnly: true,
                }}
                value={nfFile?.name ?? ""}
              />
              <ButtonCustom
                sx={{
                  width: "auto",
                  borderRadius: "0 10px 10px 0 !important",
                  fontSize: "18px",
                  fontWeight: 500,
                  height: "40px !important",
                  minWidth: "45px",
                  transform: "none !important",
                }}
                onClick={handleFileInputClick}
              >
                <IoCloudUploadOutline size="25px" />
              </ButtonCustom>
              <input
                type="file"
                accept=".pdf"
                onChange={handleFileChange}
                ref={hiddenFileInput}
                style={{ display: "none" }}
              />
            </S.BorderedInputGrid>
            <Grid container justifyContent="flex-end">
              <ButtonCustom
                label="Solicitar"
                onClick={() => requestActualWithdraw()}
                sx={{
                  width: "150px",
                  fontSize: "18px",
                  fontWeight: 500,
                  height: "40px !important",
                }}
              />
            </Grid>
          </Grid>
        </S.ModalContainer>
      </Modal>
      <Modal
        size="md"
        open={openConfirmFutureCashoutModal}
        onClose={() => setOpenConfirmFutureCashoutModal(false)}
      >
        <S.ModalContainer container flexDirection="column" gap="20px">
          <Grid container gap="5px">
            <p className="modalTitle">Solicitar saque de</p>
            <p className="modalTitle bold">saldo futuro</p>
          </Grid>
          <Grid container flexDirection="column" alignContent="center">
            <p className="cashoutValue">
              R${" "}
              {Number(
                ((financialData?.monthlyBalance?.pendingBalance ?? 0) / 100).toFixed(2)
              ).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </p>
            <p className="discountWarning">
              Taxa de 5% (- R${" "}
              {Number(
                (((financialData?.monthlyBalance?.pendingBalance ?? 0) * 0.05) / 100).toFixed(2)
              ).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              )
            </p>
          </Grid>
          <S.AlertGrid container gap="5px">
            <IoAlertCircleOutline size="24px" color="red" style={{ alignSelf: "center" }} />
            <p className="bold">Lembrete: </p>
            <p>Valor depende de aprovação e caso aprovado será abatido em 5%.</p>
          </S.AlertGrid>
          <Grid display="flex" justifyContent="flex-end">
            <ButtonCustom
              label="Solicitar"
              onClick={() => requestFutureWithdraw()}
              sx={{
                width: "150px",
                fontSize: "18px",
                fontWeight: 500,
                height: "40px !important",
              }}
            />
          </Grid>
        </S.ModalContainer>
      </Modal>
      <Modal
        open={openDetailsModal}
        onClose={() => {
          setOpenDetailsModal(false);
          setChosenEntry();
        }}
      >
        <S.DetailsMainGrid container flexDirection="column" gap="15px">
          <p className="titleDetails">Detalhes</p>
          <Grid container gap="20px">
            <Grid container justifyContent="space-between">
              <Grid container item flexDirection="column" xl={5.85}>
                <p className="subtitleDetails">Nº do processo</p>
                <p className="valueDetails">{chosenEntry?.processNumber}</p>
              </Grid>
              <Grid container item flexDirection="column" xl={5.85}>
                <p className="subtitleDetails">Status</p>
                <p className="valueDetails">{statusTranslation[chosenEntry?.status]}</p>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid container item flexDirection="column" xl={5.85}>
                <p className="subtitleDetails">Data da Solicitação</p>
                <p className="valueDetails">
                  {chosenEntry?.createdAt ? moment(chosenEntry?.dateInvoiced).format("L") : ""}
                </p>
              </Grid>
              <Grid container item flexDirection="column" xl={5.85}>
                <p className="subtitleDetails">Data da Resposta</p>
                <p className="valueDetails">
                  {chosenEntry?.resolvedAt ? moment(chosenEntry?.resolvedAt).format("L") : ""}
                </p>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid container item flexDirection="column" xl={5.85}>
                <p className="subtitleDetails">Valor</p>
                <p className="valueDetails">
                  R${" "}
                  {Number((chosenEntry?.value / 100).toFixed(2)).toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
              </Grid>
              <Grid container item flexDirection="column" xl={5.85}>
                <p className="subtitleDetails">Tipo</p>
                <p className="valueDetails">
                  {chosenEntry?.type === "FUTURE_BALANCE" ? "Saldo Futuro" : "Saldo Atual"}
                </p>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid container item flexDirection="column" xl={12}>
                <p className="subtitleDetails">Observações</p>
                <p className="valueDetails wrap">{chosenEntry?.miscInfo}</p>
              </Grid>
            </Grid>
            {chosenEntry?.invoiceCertificateURL && (
              <Grid container justifyContent="space-between">
                <a href={chosenEntry?.invoiceCertificateURL} target="_blank">
                  <ButtonCustom
                    label="Download NF"
                    sx={{
                      width: "150px",
                      height: "40px !important",
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                  />
                </a>
              </Grid>
            )}
          </Grid>
          <S.AlertGrid container gap="5px">
            <IoAlertCircleOutline color="red" style={{ alignSelf: "center" }} />
            <p className="subtitleDetails reminderDetails">Lembrete:</p>
            <p className="subtitleDetails">Valor será creditado na conta em até 48h.</p>
          </S.AlertGrid>
        </S.DetailsMainGrid>
      </Modal>
      <Modal size="md" open={openReportModal} onClose={() => setOpenReportModal(false)}>
        <S.ModalContainer container flexDirection="column" gap="20px">
          <Grid container gap="5px">
            <p className="modalTitle">Deseja</p>
            <p className="modalTitle bold">REPORTAR</p>
            <p className="modalTitle">este saque?</p>
          </Grid>
          <S.AlertGrid container gap="5px">
            <IoAlertCircleOutline size="24px" color="red" style={{ alignSelf: "center" }} />
            <p className="bold">Lembrete: </p>
            <p>O saque só pode ser reportado após 48h da solicitação.</p>
          </S.AlertGrid>
          <Grid container justifyContent="flex-end" gap="15px">
            <ButtonCustom
              label="Reportar"
              onClick={() => reportChosenWithdrawal()}
              sx={{
                width: "150px",
                fontSize: "18px",
                fontWeight: 500,
                height: "40px !important",
              }}
            />
          </Grid>
        </S.ModalContainer>
      </Modal>
      <Modal
        size="md"
        open={openAttachNfModal}
        onClose={() => {
          setOpenAttachNfModal(false);
          setNfFile();
        }}
      >
        <S.ModalContainer container flexDirection="column" gap="20px">
          <Grid container gap="5px">
            <p className="modalTitle">Anexar nota fiscal</p>
          </Grid>
          <Grid container justifyContent="space-between">
            <S.BorderedInputGrid>
              <S.Input
                name="description"
                id="description"
                placeholder="Insira o arquivo PDF"
                InputProps={{
                  readOnly: true,
                }}
                value={nfFile?.name ?? ""}
              />
              <ButtonCustom
                sx={{
                  width: "auto",
                  borderRadius: "0 10px 10px 0 !important",
                  fontSize: "18px",
                  fontWeight: 500,
                  height: "40px !important",
                  minWidth: "45px",
                  transform: "none !important",
                }}
                onClick={handleFileInputClick}
              >
                <IoCloudUploadOutline size="25px" />
              </ButtonCustom>
              <input
                type="file"
                accept=".pdf"
                onChange={handleFileChange}
                ref={hiddenFileInput}
                style={{ display: "none" }}
              />
            </S.BorderedInputGrid>
            <Grid justifyContent="flex-end">
              <ButtonCustom
                label="Salvar"
                disabled={!nfFile}
                onClick={() => attachNfToWithdraw(chosenEntry?.id, setOpenAttachNfModal, "NF anexada com sucesso!")}
                sx={{
                  width: "150px",
                  fontSize: "18px",
                  fontWeight: 500,
                  height: "40px !important",
                }}
              />
            </Grid>
          </Grid>
        </S.ModalContainer>
      </Modal>
      <Modal open={openMaintenanceModal} onClose={() => setOpenMaintenanceModal(false)}>
        <MaintenanceModal />
      </Modal>
    </DashboardLayout>
  );
};

export default InfluencerCashout;
