import React from 'react'
import * as S from './style'
import { Grid } from '@mui/material'
import { IoAlertCircleOutline } from 'react-icons/io5'

const MaintenanceModal = () => {
  return (
    <S.ConstructionModalGrid container flexDirection="column">
      <Grid container justifyContent="center" sx={{ fontSize: "32px" }}>
        <p>🚧</p>
        <p>Em manutenção</p>
        <p>🚧</p>
      </Grid>
      <S.WarningGrid container gap="5px">
        <IoAlertCircleOutline color="red" style={{ alignSelf: "center" }} />
        <p className="subtitle reminder">Lembrete:</p>
        <p className="subtitle">Estamos batalhando por essa novidade T.T</p>
      </S.WarningGrid>
    </S.ConstructionModalGrid>
  )
}

export default MaintenanceModal