import { Grid } from '@mui/material'
import VuiBox from "components/VuiBox";
import * as S from "./style";

const Versions = () => {
  const releases = [
    {
      version: "1.7.1",
      notes: ["Nova versão da plataforma com correções de segurança e melhorias."],
      date: "04 de Fevereiro de 2025"
    },
    {
      version: "1.7.2",
      notes: [
        "Criativos de projetos aceitam agora mais de um influencer.",
        "Atividades de produção agora listadas em detalhamento do projeto.",
        "Melhorias nos inputs de cards em pipelines."
      ],
      date: "04 de Março de 2025"
    },
    {
      version: "1.7.3",
      notes: [
        "Correções e melhorias.",
        "Nova página: Histórico de Versões."
      ],
      date: "18 de Março de 2025"
    }
  ];
  
  return (
    <S.MainContainer container flexDirection='column' gap='20px'>
      <Grid>
        <p className='title'>Histórico de versões</p>
      </Grid>
      <Grid>
        <p className='lastUpdate'><i>Última atualização: 18/03/2025</i></p>
      </Grid>
      <Grid>
        <p className='description'>A tabela a seguir descreve as alterações importantes em cada versão do sistema após <span style={{ fontWeight: "700" }}>Abril de 2025</span>. Para receber notificações sobre atualizações dessa documentação, acesse sua dashboard.</p>
      </Grid>
      <S.VersionsTableContainer>
        <S.TableTitle>
          Viraliza Official 1.7
        </S.TableTitle>
        <S.VersionsTable container>
          <S.TableHeader container>
            <Grid item xs={2}>
              <S.HeaderContent>Versão</S.HeaderContent>
            </Grid>
            <Grid item xs={7}>
              <S.HeaderContent>Notas de lançamento</S.HeaderContent>
            </Grid>
            <Grid item xs={3}>
              <S.HeaderContent align="center">Data de lançamento</S.HeaderContent>
            </Grid>
          </S.TableHeader>
          {releases.reverse().map((release, index) => (
            <S.TableBody container key={index}>
              <Grid item xs={2}>
                <S.BodyContent>
                  {release.version}
                </S.BodyContent>
              </Grid>
              <Grid item xs={7}>
                <VuiBox>
                  {release.notes.map((note, index) => (
                    <S.NoteContent key={index}>
                      {note}
                    </S.NoteContent>
                  ))}
                </VuiBox>
              </Grid>
              <Grid item xs={3}>
                <S.BodyContent align="center">
                  {release.date}
                </S.BodyContent>
              </Grid>
            </S.TableBody>
          ))}
        </S.VersionsTable>
      </S.VersionsTableContainer>
    </S.MainContainer>
  );
};

export default Versions;