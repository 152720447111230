import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Grid } from "@mui/material";
import * as S from "./style";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import InputAdornment from "@mui/material/InputAdornment";
import {
  RiCake2Fill,
  RiInstagramLine,
  RiYoutubeFill,
  RiTiktokFill,
  RiTwitterFill,
} from "react-icons/ri";
import { editInfluencer, getInfluencerTags } from "utils/requests/auth";
import showToast from "components/Toast/Toast";
import flatpickr from "flatpickr";
import "assets/theme/custom-flatpickr.css";
import { flatpickrOptionsInfluencer } from "utils/utils";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import "moment/locale/pt-br";
import { TokenContext } from "context/TokenContext";
import VuiAvatar from "components/VuiAvatar";
import { useFilePicker } from "react-sage";
import { useNavigate } from "react-router-dom";
import { editUserPhoto } from "utils/requests/auth";

const Avatar = ({ img, theme, children, ...rest }) => (
  <VuiAvatar
    src={img}
    size="xxl"
    {...rest}
    sx={({ borders: { borderWidth }, palette: { light }, functions: { rgba } }) => ({
      position: "relative",
    })}
  >
    {children}
  </VuiAvatar>
);

const EditAffiliate = ({ userInfo, refetch, closeModal, returnedValues }) => {
  moment.locale("pt-br");

  const navigate = useNavigate();

  const { decodedToken } = React.useContext(TokenContext);
  const role = decodedToken?.type;

  const [tags, setTags] = React.useState();
  const [postAvatar, setPostAvatar] = React.useState();
  const [previewUrl, setPreviewUrl] = React.useState();

  const imageInput = useFilePicker({ maxFileSize: 1, resizeImage: true });

  React.useEffect(() => {
    const getDataUrls = async () => {
      if (imageInput.files && imageInput.files[0]) {
        setPostAvatar(imageInput.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(imageInput.files[0]);
        reader.onload = () => {
          setPreviewUrl(reader.result);
        };
      }
    };
    getDataUrls();
  }, [imageInput.files]);

  const fetchTags = async () => {
    const data = await getInfluencerTags();
    if (!data.message) setTags(data);
  };

  const sendUserData = async (values, userPhoto) => {
    if (postAvatar && userPhoto) {
      await editUserPhoto(userPhoto).then((res) => {
        if (!res.message) {
          showToast("Dados alterados com sucesso!", "success");
          refetch(res.username);
          closeModal();
        }
        if (res.message) {
          showToast("Falha ao alterar dados", "error");
        }
      });
    }
    await editInfluencer(userInfo?.username, values).then((res) => {
      if (!res?.message && userInfo?.username !== res?.username) {
        showToast("Dados alterados com sucesso!", "success");
        setTimeout(() => navigate(`/influencers/${res?.username}`), 1000);
        setTimeout(() => refetch(res?.username), 2000);
        closeModal();
        return;
      }
      if (!res.message) {
        showToast("Dados alterados com sucesso!", "success");
        refetch(res.username);
        closeModal();
      }
      if (res.message) {
        showToast("Falha ao alterar dados", "error");
      }
    });
  };

  React.useEffect(() => {
    fetchTags();
  }, []);

  const unixDate = (date) => {
    return Number(moment(date, "DD/MM/YYYY").valueOf());
  };

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: "",
      username: "",
      birthDate: "",
      bio: "",
      instagram: "",
      youtube: "",
      tiktok: "",
      twitter: "",
      spotify: "",
      userTags: [],
      searchTerms: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().min(4),
      username: Yup.string().min(4),
      birthDate: Yup.string(),
      bio: Yup.string(),
      instagram: Yup.string(),
      youtube: Yup.string(),
      tiktok: Yup.string(),
      twitter: Yup.string(),
      spotify: Yup.string(),
      userTags: Yup.array(),
      searchTerms: Yup.array(),
    }),
    onSubmit: async (values) => {
      const startInfluencerValues = validation.initialValues;
    },
  });

  flatpickr(
    "#birthDate",
    flatpickrOptionsInfluencer("birthDate", validation.setFieldValue, validation.setFieldTouched)
  );

  return (
    <>
      <Grid container flexDirection="column">
        <p>Dados do Afiliado</p>
        <form onSubmit={validation.handleSubmit}>
          <Grid container flexDirection="column" gap="20px" mt="20px">
            <Grid container gap="20px" justifyContent="space-between">
              <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                <S.Input
                  hiddenLabel
                  name="username"
                  placeholder="Username"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.username || ""}
                  error={validation.touched.username && validation.errors.username}
                  helperText={
                    validation.touched.username && validation.errors.username
                      ? "Username inválido"
                      : false
                  }
                  InputProps={{
                    readOnly: role !== "ADMINISTRATOR",
                  }}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                <S.Input
                  hiddenLabel
                  name="name"
                  placeholder="Nome"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  error={validation.touched.name && validation.errors.name}
                  helperText={
                    validation.touched.name && validation.errors.name ? "Nome inválido" : false
                  }
                />
              </S.InputGrid>
            </Grid>
            <Grid container gap="20px" justifyContent="space-between">
              <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                <S.Input
                  hiddenLabel
                  name="username"
                  placeholder="Número para Contato"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.username || ""}
                  error={validation.touched.username && validation.errors.username}
                  helperText={
                    validation.touched.username && validation.errors.username
                      ? "Username inválido"
                      : false
                  }
                  InputProps={{
                    readOnly: role !== "ADMINISTRATOR",
                  }}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                <S.Input
                  hiddenLabel
                  name="name"
                  placeholder="Email"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  error={validation.touched.name && validation.errors.name}
                  helperText={
                    validation.touched.name && validation.errors.name ? "Nome inválido" : false
                  }
                />
              </S.InputGrid>
            </Grid>
            <Grid container gap="20px" justifyContent="space-between">
              <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                <S.Input
                  hiddenLabel
                  name="username"
                  placeholder="Cidade"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.username || ""}
                  error={validation.touched.username && validation.errors.username}
                  helperText={
                    validation.touched.username && validation.errors.username
                      ? "Username inválido"
                      : false
                  }
                  InputProps={{
                    readOnly: role !== "ADMINISTRATOR",
                  }}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                <S.Input
                  hiddenLabel
                  name="name"
                  placeholder="Estado"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  error={validation.touched.name && validation.errors.name}
                  helperText={
                    validation.touched.name && validation.errors.name ? "Nome inválido" : false
                  }
                />
              </S.InputGrid>
            </Grid>
            <Grid container gap="20px" justifyContent="space-between">
            <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                <S.Input
                  hiddenLabel
                  name="name"
                  placeholder="CPF"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  error={validation.touched.name && validation.errors.name}
                  helperText={
                    validation.touched.name && validation.errors.name ? "Nome inválido" : false
                  }
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.5} xl={5.5} id="birthDate">
                <S.Input
                  name="birthDate"
                  hiddenLabel
                  type="text"
                  placeholder="Data de Nascimento"
                  fullWidth
                  data-input
                  autoComplete="off"
                  value={validation.values.birthDate}
                  error={Boolean(validation.errors.birthDate)}
                  helperText={validation.errors.birthDate}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <RiCake2Fill />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <CloseIcon
                          onClick={() => validation.setFieldValue("birthDate", "")}
                          sx={{ cursor: "pointer", "&:hover": { color: "#E22B00" } }}
                        />
                      </InputAdornment>
                    ),
                    readOnly: role !== "ADMINISTRATOR",
                  }}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent="space-between">
              <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                <S.Input
                  hiddenLabel
                  name="instagram"
                  placeholder="Instagram"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.instagram || ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <RiInstagramLine />
                      </InputAdornment>
                    ),
                  }}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                <S.Input
                  hiddenLabel
                  name="youtube"
                  placeholder="Youtube"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.youtube || ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <RiYoutubeFill />
                      </InputAdornment>
                    ),
                  }}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent="space-between">
              <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                <S.Input
                  hiddenLabel
                  name="tiktok"
                  placeholder="TikTok"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.tiktok || ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <RiTiktokFill />
                      </InputAdornment>
                    ),
                  }}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                <S.Input
                  hiddenLabel
                  name="twitter"
                  placeholder="Twitter"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.twitter || ""}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <RiTwitterFill />
                      </InputAdornment>
                    ),
                  }}
                />
              </S.InputGrid>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" mt="30px">
            <ButtonCustom
              label="Salvar"
              type="submit"
              sx={{
                width: "150px",
                height: "40px !important",
                fontSize: "18px",
                fontWeight: "500",
              }}
            />
          </Grid>
        </form>
      </Grid>
    </>
  );
};

export default EditAffiliate;
