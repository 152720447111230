export let REACT_APP_API_URL = process.env.REACT_APP_API_URL;
if (!process.env.REACT_APP_API_URL) REACT_APP_API_URL = process.env.REACT_APP_API_DEV_URL
import mockClientOrders from "./MockedLists/MockClientOrders";
import mockClientsList from "./MockedLists/MockClientsList";

export const getClients = async (page, filters) => {
  const size = 10;
  const start = page * size;
  const end = start + size;

  const filteredProducts = mockClientsList.filter((product) => {
    const matchesName = filters?.name
      ? product.name.toLowerCase().includes(filters.name.toLowerCase())
      : true;

    return matchesName;
  });

  const data = filteredProducts.slice(start, end);
  return data;
};

export const getClientOrders = async () => {
  const data = mockClientOrders;

  return data;
}

export const deleteClient = async (name) => {
  const clientIndex = mockClientsList.findIndex((client) => client.name === name);
  if (clientIndex !== -1) {
    mockClientsList.splice(clientIndex, 1);
    return { success: true };
  }
};

export const editClient = async (clientInfo) => {
  const clientIndex = mockClientsList.findIndex((client) => client.id === clientInfo.id);

  if (clientIndex === -1) {
    return { message: "Cliente não encontrado" };
  }

  mockClientsList[clientIndex] = {
    ...mockClientsList[clientIndex],
    ...clientInfo,
  };

  return { success: true };
};