import { useEffect, useState } from "react";
import NotificationItem from "defaultComponents/Items/NotificationItem";
import { Card, Grid } from '@mui/material';
import VuiTypography from 'components/VuiTypography';
import * as S from './style'
import { getNotifications } from 'utils/requests/other';
import moment from 'moment-timezone';
import 'moment/locale/pt-br';
import { useNavigate } from "react-router-dom";

const Notifications = () => {
  const navigate = useNavigate();

  const [notificationsLog, setNotificationsLog] = useState()

  const fetchData = async () => {
    await getNotifications().then(res => {
      if (!res?.message) {
        setNotificationsLog(res);
      };
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const redirectByEntityType = (notification) => {
    if (notification?.entityType === 'PipelineCards') {
      return `/marketing/pipeline`
    };
    if (notification?.entityType === 'Project') {
      return `/marketing/projetos`
    };
    if (notification?.entityType === 'WithdrawPlea') {
      return `/influencers/${notification?.creator?.username}/financeiro/saque`
    };
    if (notification?.entityType === 'Financial') {
      return `/financeiro/contas`
    };
    return ''
  };

  const splitStringByAsterisks = (text) => {
    const pattern = /(\*+)([^*]+)\1/;

    const isValid = pattern.test(text);

    if (!isValid) {
      return ['', text];
    };

    const match = text.match(pattern);
    const wrappedText = match[2];
    const afterAsterisks = text.slice(match.index + match[0].length);

    return [wrappedText, afterAsterisks.trim()];
  };

  return (
    <Card sx={{ maxHeight: "335px", backdropFilter: "blur(120px)" }}>
      <Grid display='flex' flexDirection='column'>
        <VuiTypography variant='lg' color='white' fontWeight='bold' mb='4px'>
          Notificações
        </VuiTypography>
        <S.NotificationGrid container>
          {(notificationsLog && notificationsLog[1] === 0) &&
            <p className="emptyMessage">Não há notificações</p>
          }
          {notificationsLog && notificationsLog[0]?.map((notification) => (
            <NotificationItem
              key={notification?.id}
              image={notification?.notificationBackground}
              title={
                notification?.entityType === 'WithdrawPlea'
                  ?
                  [notification?.creator?.name, notification?.action]
                  :
                  splitStringByAsterisks(notification?.action)
              }
              date={moment().to(moment(notification?.createdAt).tz('America/Maceio').format())}
              onClick={() => navigate(redirectByEntityType(notification))}
            />
          ))
          }
        </S.NotificationGrid>
      </Grid>
    </Card>
  )
}

export default Notifications