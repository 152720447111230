import { Grid } from '@mui/material'
import { Formik, Form, useFormik } from 'formik';
import * as S from './style'
import * as Yup from "yup";
import showToast from 'components/Toast/Toast';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import "assets/theme/custom-flatpickr.css";
import 'moment/locale/pt-br';

const EditOrderInfo = ({ orderInfo, refetch, closeModal }) => {
  const validation = useFormik({

    initialValues: {
      userName: orderInfo?.userName || '',
      name: orderInfo?.name || '',
      contactNumber: orderInfo?.contactNumber || '',
      email: orderInfo?.email || '',
      cpf: orderInfo?.cpf || '',
      birthday: orderInfo?.birthday || '',
      deliveryAddress: orderInfo?.deliveryAddress || '',
      cep: orderInfo?.cep || '',
      state: orderInfo?.state || '',
      city: orderInfo?.city || '',
      reference: orderInfo?.reference || '',
      shipping: orderInfo?.shipping || '',
    },
    validationSchema: Yup.object({
      userName: Yup.string(),
      name: Yup.string(),
      contactNumber: Yup.string(),
      email: Yup.string(),
      cpf: Yup.string(),
      birthday: Yup.string(),
      deliveryAddress: Yup.string(),
      cep: Yup.string(),
      state: Yup.string(),
      city: Yup.string(),
      reference: Yup.string(),
      shipping: Yup.string(),
    }),
    onSubmit: async (values) => {
      const startProductValues = validation.initialValues;

      const edit = {
        userName: values.userName !== startProductValues.userName ? values.userName : '',
        name: values.name !== startProductValues.name ? values.name : '',
        contactNumber: values.contactNumber !== startProductValues.contactNumber ? values.contactNumber : '',
        email: values.email !== startProductValues.email ? values.email : '',
        cpf: values.cpf !== startProductValues.cpf ? values.cpf : '',
        birthday: values.birthday !== startProductValues.birthday ? values.birthday : '',
        deliveryAddress: values.deliveryAddress !== startProductValues.deliveryAddress ? values.deliveryAddress : '',
        cep: values.cep !== startProductValues.cep ? values.cep : '',
        state: values.state !== startProductValues.state ? values.state : '',
        city: values.city !== startProductValues.city ? values.city : '',
        reference: values.reference !== startProductValues.reference ? values.reference : '',
        shipping: values.shipping !== startProductValues.shipping ? values.shipping : '',
      };
      
      Object.keys(edit).forEach((key) => {
        if (edit[key] === "") {
          delete edit[key];
        }
      });

      if (Object.keys(edit).length === 0)
        showToast("Sem dados alterados, campos sem alterações", "warning");
      if (Object.keys(edit).length > 0) {
        await editOrderInfo(payload, edit).then((res) => {
          if (!res.message) {
            showToast("Dados alterados com sucesso!", "success");
            refetch();
            closeModal();
          }
          if (res.message) {
            showToast("Falha ao alterar dados", "error");
          }
        });
      }
    }
  });

  return (
    <Grid container flexDirection='column'>
      <Formik initialValues={validation.initialValues} validationSchema={validation.validationSchema} onSubmit={validation.handleSubmit}>
        <Form>
          <Grid container flexDirection='column' gap='20px' mt='15px' mb='60px'>
            <p>Dados do Cliente</p>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name='userName'
                  placeholder="Username"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.userName || ""}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name='name'
                  placeholder="Nome"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name='contactNumber'
                  placeholder="Número para Contato"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.contactNumber || ""}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name='email'
                  placeholder="Email"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name='cpf'
                  placeholder="CPF"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.cpf || ""}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name='birthday'
                  placeholder="Nascimento"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.mebirthdaytaName || ""}
                />
              </S.InputGrid>
            </Grid>
          </Grid>
          <Grid container flexDirection='column' gap='20px' mt='15px'>
            <p>Dados do Pedido</p>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name='deliveryAddress'
                  placeholder="Endereço de entrega"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.deliveryAddress || ""}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name='cep'
                  placeholder="CEP"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.cep || ""}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name='state'
                  placeholder="Estado"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.state || ""}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name='city'
                  placeholder="Cidade"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.city || ""}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name='reference'
                  placeholder="Referência"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.reference || ""}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name='shipping'
                  placeholder="Frete"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.shipping || ""}
                />
              </S.InputGrid>
            </Grid>
          </Grid>
          <Grid container justifyContent='flex-end' mt='15px'>
            <ButtonCustom
              label='Salvar'
              type='submit'
              sx={{ width: "150px", height: "40px !important", fontSize: "18px", fontWeight: "500" }}
            />
          </Grid>
        </Form>
      </Formik>
    </Grid>
  )
}

export default EditOrderInfo;