import { useState, useContext, useEffect, useCallback } from "react";
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Table from "defaultComponents/Tables/Table";
import { Grid, Box } from "@mui/material";
import * as S from "./style";
import { useNavigate } from "react-router-dom";
import { SearchBarContext } from "context/SearchBarContext";
import { useDebounce } from "use-debounce";
import {
  IoArrowUp,
  IoArrowDown,
  IoEllipse,
  IoArrowBack,
  IoArrowForward,
  IoEllipsisVertical,
} from "react-icons/io5";
import filter from "assets/icons/filter.svg";
import FilterModal from "components/Modal/FilterModal";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import Modal from "components/Modal/Modal";
import { TokenContext } from "context/TokenContext";
import * as T from "components/Toast/style";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import showToast from "components/Toast/Toast";
import Pagination from '@mui/material/Pagination';
import mockOrders from "./MockedLists/MockOrders";
import { getOrders, getOrderStatus, getOrderPaymentStatus, deleteOrder } from "./order";

const Orders = () => {
  const navigate = useNavigate();

  const [orders, setOrders] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [page, setPage] = useState(1);
  const [openMenu, setOpenMenu] = useState([]);
  const [openConfirmDeletionModal, setOpenConfirmDeletionModal] = useState(false);
  const [chosenOrder, setChosenOrder] = useState();
  const [orderStatus, setOrderStatus] = useState();
  const [orderPaymentStatus, setOrderPaymentStatus] = useState();
  const [openFilter, setOpenFilter] = useState(false);
  const [orderStatusFilterValues, setOrderStatusFilterValues] = useState([]);
  const [orderPaymentStatusFilterValues, setOrderPaymentStatusFilterValues] = useState([]);
  const [filters, setFilters] = useState({ orderStatus: [], orderPaymentStatus: [] });

  const { searchQuery } = useContext(SearchBarContext);
  const { decodedToken } = useContext(TokenContext);
  const role = decodedToken?.type;

  const [debouncedSearch] = useDebounce(searchQuery, 600);
  const [debouncedFilters] = useDebounce(filters, 600);

  const fetchOrderStatus = async () => {
    const data = await getOrderStatus();
    if (!data.statusCode) {
      setOrderStatus(data);
      const checkArr = Array.from({ length: data.length }, () => false);
      setOrderStatusFilterValues(checkArr);
    }
  };

  const fetchOrderPaymentStatus = async () => {
    const data = await getOrderPaymentStatus();
    if (!data.statusCode) {
      setOrderPaymentStatus(data);
      const checkArr = Array.from({ length: data.length }, () => false);
      setOrderPaymentStatusFilterValues(checkArr);
    }
  };

  const calculatePagination = () => {
    const roundUpArrSize = Math.ceil(mockOrders.length / 10);
    const paginationArr = Array.from({ length: roundUpArrSize }, (value, index) => index);
    setPagination(paginationArr);
  };

  const fetchOrders = async () => {
    const data = await getOrders(page - 1, filters);
    if (!data.statusCode) {
      setOrders(data);
    }
  };

  useEffect(() => {
    fetchOrderStatus();
  }, []);

  useEffect(() => {
    fetchOrderPaymentStatus();
  }, []);

  useEffect(() => {
    fetchOrders();
  }, [page, debouncedFilters]);

  useEffect(() => {
    calculatePagination();
  }, [orders]);

  useEffect(() => {
    const startIndex = (page - 1) * 10;
    const endIndex = startIndex + 10;

    const paginatedOrders = mockOrders.slice(startIndex, endIndex);
  
    setOrders(paginatedOrders);
  }, [page]);

  useEffect(() => {
    const selectedOrderStatus = orderStatusFilterValues
    .map((checked, index) => (checked ? orderStatus[index]?.id : null))
    .filter((id) => id !== null);

    const selectedOrderPaymentStatus = orderPaymentStatusFilterValues
    .map((checked, index) => (checked ? orderPaymentStatus[index]?.id : null))
    .filter((id) => id !== null);

    setFilters((prevData) => ({
      ...prevData,
      status: selectedOrderStatus,
      pagamento: selectedOrderPaymentStatus,
      numero: isNaN(debouncedSearch) ? null : debouncedSearch,
      cliente: isNaN(debouncedSearch) ? debouncedSearch : null,
    }));
  
    setPage(1);
  }, [orderStatusFilterValues, orderPaymentStatusFilterValues, debouncedSearch]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleOrderStatusChange = (index) => {
      const updatedCheckedState = orderStatusFilterValues.map((item, i) =>
        i === index ? !item : item
      );
      setOrderStatusFilterValues(updatedCheckedState);
    };
  
  const handleOrderPaymentStatusChange = (index) => {
    const updatedCheckedState = orderPaymentStatusFilterValues.map((item, i) =>
      i === index ? !item : item
    );
    setOrderPaymentStatusFilterValues(updatedCheckedState);
  };

  const tagFilter = useCallback(() => {
    const checkedOrderStatusIndexes = orderStatusFilterValues
      ?.map((bool, index) => (bool ? index : null))
      .filter((el) => el !== null);
  
    const selectedOrderStatus = checkedOrderStatusIndexes?.map((index) => orderStatus[index].id) || [];
  
    const checkedOrderPaymentStatusIndexes = orderPaymentStatusFilterValues
      ?.map((bool, index) => (bool ? index : null))
      .filter((el) => el !== null);
  
    const selectedOrderPaymentStatuss = checkedOrderPaymentStatusIndexes?.map((index) => orderPaymentStatus[index].id) || [];
  
    const filtersChanged =
      JSON.stringify(selectedOrderStatus) !== JSON.stringify(filters?.orderStatus) ||
      JSON.stringify(selectedOrderPaymentStatuss) !== JSON.stringify(filters?.orderPaymentStatus);
  
    if (filtersChanged) {
      setFilters((prevData) => ({
        ...prevData,
        orderStatus: selectedOrderStatus,
        orderPaymentStatus: selectedOrderPaymentStatuss,
      }));
      setPage(1);
    }
  }, [orderStatusFilterValues, orderPaymentStatusFilterValues, orderStatus, orderPaymentStatus, filters?.orderStatus, filters?.orderPaymentStatus]);

  useEffect(() => {
    tagFilter();
  }, [tagFilter]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const toggleOpenMenu = (index) => {
    const updatedMenu = [...openMenu];
    updatedMenu[index] = !updatedMenu[index];
    setOpenMenu(updatedMenu);
  };

  const handleDelete = (orderInfo) => {
    setChosenOrder(orderInfo);
    setOpenConfirmDeletionModal(true);
  };

  const cancelDelete = () => {
    setChosenOrder();
    setOpenConfirmDeletionModal(false);
  };

  const handleOrderDelete = async (id) => {
    await deleteOrder(chosenOrder?.id).then((res) => {
      if (!res.message) {
        showToast("Produto deletado com sucesso!", "success");
        fetchOrders();
        cancelDelete();
      }
      if (res.message) {
        showToast("Falha ao deletar produto!", "error");
      }
    });
  };

  return (
    <DashboardLayout
      onClick={() => (openMenu.includes(true) ? setOpenMenu(openMenu.map((bool) => false)) : null)}
    >
      <DashboardNavbar />
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <VuiBox py={1}>
        <VuiBox mb={1}>
          <S.TableCard>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
              <VuiTypography variant="lg" color="white">
                Pedidos
              </VuiTypography>
              <S.EditButton onClick={() => setOpenFilter(true)}>
                <img src={filter} draggable="false" />
              </S.EditButton>
            </VuiBox>
            <VuiBox pb="40px">
              <Table
                mb={role === "ADMINISTRATOR" ? "40px" : 0}
                columns={[
                  { name: "numero", align: "left" },
                  { name: "cliente", align: "left" },
                  { name: "total", align: "center" },
                  { name: "pagamento", align: "center" },
                  { name: "status do pedido", align: "center" },
                  { name: "itens", align: "center" },
                  { name: "", align: "center" },
                ]}
                rows={
                  orders &&
                  orders.map((order, index) => {
                    return {
                      numero: (
                        <S.InfoTableGrid container flexDirection="column">
                          <p className="numero" style={{
                            color: '#FC6508'}}
                          >
                            #{order.numero}
                          </p>
                        </S.InfoTableGrid>
                      ),
                      cliente: (
                        <S.InfoTableGrid container flexDirection="column" justifyContent="center">
                          <p className="client">
                            {order.cliente}
                          </p>
                          <p className="data">
                            {order.data}
                          </p>
                        </S.InfoTableGrid>
                      ),
                      total: (
                        <S.InfoTableGrid container justifyContent="center">
                          <p className="total">
                            {order.total}
                          </p>
                        </S.InfoTableGrid>
                      ),
                      pagamento: (
                        <S.InfoTableGrid container justifyContent="center">
                          <p className="pagamento" style={{
                            color: order.pagamento === 'Pago' ? '#5ACC93' : order.pagamento === 'Pendente' ? '#FB8404' : order.pagamento === 'Cancelado' ? '#D7007B' : 'white'
                          }}>
                            {order.pagamento}
                          </p>
                        </S.InfoTableGrid>
                      ),
                      "status do pedido": (
                        <S.InfoTableGrid container justifyContent="center">
                          <p className="status" style={{
                            color: order.status === 'Não processado' ? '#A0AEC0' : order.status === 'Em produção' ? '#FB8404' : order.status === 'Em transporte' ? '#FB8404' : order.status === 'Cancelado' ? '#D7007B' : order.status === 'Entregue' ? '#5ACC93' : 'white'
                          }}>
                            {order.status}
                          </p>
                        </S.InfoTableGrid>
                      ),
                      itens: (
                        <S.InfoTableGrid container justifyContent="center">
                          <p className="itens">
                            {order.itens}
                          </p>
                        </S.InfoTableGrid>
                      ),
                      "": (
                        <Grid container justifyContent="space-between">
                          {role === "ADMINISTRATOR" && (
                            <Grid
                              item
                              xs={3}
                              md={1}
                              xl={1}
                              sx={{ alignSelf: "center" }}
                              onClick={() => toggleOpenMenu(index)}
                            >
                              <IoEllipsisVertical size="20px" style={{ cursor: "pointer" }} />
                            </Grid>
                          )}
                          {openMenu[index] && (
                            <S.OptionsContainer container justifyContent="flex-end">
                              <S.OptionsGrid display="flex" flexDirection="column">
                                <p 
                                  onClick={() => {
                                    navigate(`/ecommerce/pedidos/${order?.numero}`, {
                                      state: {
                                        numero: order?.numero,
                                        data: order?.data,
                                        status: order?.status,
                                      }
                                    });
                                  }} 
                                  key={order?.numero}
                                >
                                  Ver Detalhes
                                </p>
                                <S.Divider />
                                <p>Enviar E-mail</p>
                                <S.Divider />
                                <p onClick={() => handleDelete(order)}>Excluir</p>
                              </S.OptionsGrid>
                            </S.OptionsContainer>
                          )}
                        </Grid>
                      )
                    };
                  })
                }
              />
            </VuiBox>
          </S.TableCard>
        </VuiBox>
      </VuiBox>
      <Grid container justifyContent="center">
        <S.PaginationGrid container justifyContent="center">
          <Pagination
            count={pagination?.length}
            page={page}
            siblingCount={1}
            boundaryCount={1}
            onChange={handleChange}
          />
        </S.PaginationGrid>
      </Grid>
      <FilterModal size="sm" open={openFilter} onClose={() => setOpenFilter(false)}>
        <p>Filtragem por status de pagamento</p>
        {orderPaymentStatus &&
          orderPaymentStatus.map((cat, index) => (
            <Grid container key={index}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={orderPaymentStatusFilterValues[index]}
                onChange={() => handleOrderPaymentStatusChange(index)}
                name={cat.name}
              />
              <p style={{ fontSize: "18px" }}>{cat.name}</p>
            </Grid>
          ))}
        <p style={{ marginTop: "20px" }}>Filtragem por status do pedido</p>
        {orderStatus &&
          orderStatus.map((cat, index) => (
            <Grid container key={index}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={orderStatusFilterValues[index]}
                onChange={() => handleOrderStatusChange(index)}
                name={cat.name}
              />
              <p style={{ fontSize: "18px" }}>{cat.name}</p>
            </Grid>
          ))}
      </FilterModal>
      <Modal
        size="sm"
        open={openConfirmDeletionModal}
        onClose={() => setOpenConfirmDeletionModal(false)}
      >
        <Grid container flexDirection="column" gap="20px">
          <p style={{ fontSize: "24px" }}>Confirmar exclusão</p>
          <p style={{ fontSize: "18px" }}>Deseja excluir esse pedido? Essa ação é irreversível</p>
          <Grid container gap="15px" justifyContent="center">
            <Grid item lg={3}>
              <ButtonCustom
                label="Cancelar"
                onClick={() => cancelDelete()}
                sx={{ background: "linear-gradient(180deg, #818181 0%, #2a2020 155.15%)" }}
              ></ButtonCustom>
            </Grid>
            <Grid item lg={3}>
              <ButtonCustom
                label="Confirmar"
                onClick={() => handleOrderDelete(chosenOrder?.id)}
              ></ButtonCustom>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </DashboardLayout>
  );
};

export default Orders;