import * as S from './style'
import { Grid } from '@mui/material'

const PrivacyPolicy = () => {
  return (
    <S.MainContainer container flexDirection='column' gap='20px'>
      <Grid>
        <p className='title'>Política de Privacidade</p>
      </Grid>
      <Grid>
        <p className='lastUpdate'>Última atualização: 26/02/2025</p>
      </Grid>
      <Grid>
        <p>Esta "Política de Privacidade do Usuário" (ou "Política de Privacidade") descreve as práticas de privacidade da Viraliza Produções LTDA. e de nossas subsidiárias e afiliadas em relação ao processamento de informações pessoais sobre você. " Para efeitos desta Política de Privacidade, "você" significa qualquer usuário que use qualquer um dos serviços que oferecemos, incluindo visitantes de nosso site e aplicativo móvel, usuários da Viraliza e quaisquer outros serviços que possamos oferecer periodicamente (coletivamente, "Serviços"). Esta Política de Privacidade se aplica a todos os usuários, incluindo os usuários que usam os Serviços por meio de seu empregador ou outra empresa. Esta Política de Privacidade do Usuário fornece informações sobre os tipos de informações pessoais que mantemos sobre você em relação a nós e como as coletamos, usamos e compartilhamos. Também informa sobre seus direitos e como eles podem exercê-los.</p>
      </Grid>
      <Grid>
        <p className='subTitle'>Serviços</p>
        <p>Se você se registrar como usuário de qualquer um de nossos Serviços, poderemos solicitar que você forneça:</p>
        <ol>
          <li>seu nome,</li>
          <li>endereço de e-mail,</li>
          <li>número de telefone de contato (landline e/ou celular),</li>
          <li>a empresa para a qual você trabalha,</li>
          <li>um nome de usuário e senha</li>
          <li>e outras informações relevantes para os Serviços que fornecemos.</li>
        </ol>
        <p>Ao fornecer informações sobre você, preste especial atenção à proteção adequada de nome de usuário e senha. Se você não for um usuário administrativo de sua conta, as mesmas informações sobre você poderão ser fornecidas por um colega ou outro terceiro. Também podemos coletar outras informações diretamente de você, de tempos em tempos.
          Se você entrar em contato com nossos serviços de suporte, coletaremos as informações necessárias para categorizar sua pergunta e responder a ela. Também usamos essas informações para monitorar possíveis problemas com nossos Serviços e personalizar nossas respostas de suporte para melhor atendê-lo.</p>
        <p>Coletamos informações sobre você quando você usa qualquer parte de nossos Serviços. Por exemplo, se você estiver conectado à Plataforma de Análise de Mídia, coletaremos informações sobre a frequência com que ela é usada, quais consultas são criadas ou alteradas, quais menções são analisadas e outras informações sobre como você interage com nossa Plataforma de Análise. Também podemos coletar informações sobre como você interage com as mensagens que enviamos para você. Também inferimos informações sobre você com base nas informações fornecidas ou disponibilizadas a nós. Isso pode incluir personalizar sua experiência de uso de nossos Serviços, sugerir diferentes Serviços que você possa estar interessado em experimentar ou outras inferências sobre você. Estamos constantemente tentando melhorar nossos serviços para você. Isso significa que estamos introduzindo novos recursos de nossos Serviços regularmente, alguns dos quais podem resultar na coleta de novas informações de você. Neste caso, iremos informá-lo regularmente sobre isso.</p>
      </Grid>
      <Grid>
        <p className='subTitle'>Local na rede Internet</p>
        <p>Além das informações que você nos fornece, também podemos coletar informações sobre você durante sua visita aos nossos Serviços. Coletamos essas informações usando ferramentas automatizadas detalhadas abaixo. Essas ferramentas podem coletar informações sobre seu comportamento e seu sistema de computador, como seu endereço de internet (endereço IP), as páginas que você visualizou e as ações que você tomou ao usar os Serviços. Algumas das ferramentas que usamos para coletar automaticamente informações sobre você podem incluir:</p>
        <ol>
          <li>Cookies. Um "cookie" é um pequeno arquivo de dados transmitido de um site para o disco rígido do seu computador. Os cookies geralmente são definidos de duas maneiras, e podemos usar ambas:</li>
          <li>cookies de sessão, que não permanecem no seu computador depois que você fecha o navegador, e</li>
          <li>cookies persistentes, que permanecem no seu computador até você excluí-los ou eles expirarem. Claro que, se você não quiser ter cookies em seus dispositivos, poderá desativá-los a qualquer momento modificando as configurações do seu navegador da Internet. No entanto, ao desativar os cookies em seu dispositivo, você pode ser proibido de usar plenamente os recursos dos Serviços ou perder o acesso a algumas funcionalidades.</li>
          <li>Diferenciamos entre duas categorias de cookies: cookies funcionais, sem os quais a funcionalidade do nosso site seria reduzida e cookies opcionais usados por exemplo, análise de sites e fins de marketing.</li>
          <li>Utilizamos apenas cookies opcionais se tivermos obtido o seu consentimento prévio.</li>
          <li>Você pode usar nosso site sem que nenhum cookie seja definido. No seu navegador, você pode, a qualquer momento, configurar ou desativar completamente o uso de cookies. Por exemplo:</li>
          <li>Ao usar o Internet Explorer: clique no menu principal, selecione "Ferramentas/Opções da Internet" e, em seguida, na guia "Privacidade", clique em "Avançado" e desative os cookies.</li>
          <li>Ao usar o Mozilla Firefox: clique no menu principal, selecione "Ferramentas" e depois "Opções" e ajuste o nível de privacidade no painel "Privacidade".</li>
          <li>Ao usar o Opera: clique no menu principal, selecione “Ferramentas" e, em seguida, na guia “Preferências", selecione “Avançado" e “Cookies"."</li>
          <li>Ao usar o Google Chrome: clique no menu no lado direito da tela, selecione "Opções", depois "Mostrar configurações avançadas" e clique em "Cookies".</li>
          <li>Web beacons. Um Web Beacon é uma imagem eletrônica. Os Web Beacons podem rastrear certas coisas do seu computador e reportar atividades a um servidor da Web, permitindo que entendamos parte do seu comportamento. Se você optar por receber nossos e-mails, poderemos usar Web Beacons para rastrear sua reação aos nossos e-mails. Também poderemos usá-los para rastrear se você clica nos links e em que hora e data você o faz. Alguns de nossos parceiros de marketing terceirizados podem usar Web Beacons para rastrear sua interação com banners de publicidade on-line em nossos Serviços. Essas informações são coletadas apenas de forma agregada e não serão vinculadas às suas informações pessoais. Observe que qualquer arquivo de imagem em uma página da web pode funcionar como um Web Beacon.</li>
          <li>Links incorporados. Os links fornecidos em nossos e-mails e, em alguns casos, em sites de terceiros podem incluir tecnologia de rastreamento incorporada ao link. O rastreamento é realizado por meio de um sistema de redirecionamento. O sistema de redirecionamento nos permite entender como o link está sendo usado pelos destinatários do e-mail. Alguns desses links nos permitirão identificar que você clicou pessoalmente no link, e isso pode ser anexado às Informações Pessoais que mantemos sobre você. Esses dados são usados para melhorar nosso serviço a você e para nos ajudar a entender o desempenho de nossas campanhas de marketing.</li>
          <li>Websites e Serviços de Terceiros. Trabalhamos com vários provedores de serviços de tecnologia de comunicação de marketing. Esses provedores de serviços podem usar vários métodos de coleta de dados para melhorar o desempenho das campanhas de marketing que estamos contratando para fornecê-las. As informações coletadas podem ser coletadas em nossos Serviços. Por exemplo, podemos coletar dados onde nossos banners de anúncios são exibidos em sites de terceiros.</li>
        </ol>
      </Grid>
      <Grid>
        <p><b>Dados do cliente que coletamos como provedor/processador de serviços.</b> Nossos clientes podem complementar as informações pessoais que disponibilizamos a eles por meio dos serviços com informações pessoais sobre você que eles obtiveram de forma independente ("Dados obtidos pelo cliente"). Nossos clientes inserem esses dados adquiridos pelo cliente em nossos serviços, e nós só usamos os dados adquiridos pelo cliente para fornecer nossa página da Web e nossos serviços a esse cliente. No entanto, esses dados são fornecidos a critério de nossos clientes e o Tagger não tem controle sobre a entrada desses dados. Esses dados podem incluir informações adicionais (mas não públicas) nas categorias de dados identificados acima.
          Com relação a esses Dados Obtidos pelo Cliente, na medida em que forem recebidos exclusivamente de nossos clientes e não de outra forma, atuamos como um processador ou prestador de serviços em nome de nossos clientes para seus respectivos Dados Obtidos pelo Cliente. Nosso processamento de tais informações pessoais está fora do escopo desta Política de Privacidade do Usuário e está sujeito às políticas e práticas de privacidade de nossos clientes e a qualquer contrato ou outro acordo que você ou nós possamos ter com esse cliente.</p>
      </Grid>
      <Grid>
        <p><b>Para criar dados anônimos, agregados ou desidentificados.</b> Podemos criar dados anônimos, agregados ou desidentificados de suas informações pessoais e das informações pessoais de outros indivíduos. Podemos usar esses dados anônimos, agregados ou desidentificados e compartilhá-los com nossos clientes e outros terceiros para fins comerciais legais, inclusive para analisar e melhorar nossos serviços e promover nossos negócios.</p>
      </Grid>
      <Grid>
        <p className='subTitle'>Conformidade e proteção. Podemos usar suas informações pessoais para:</p>
        <ol>
          <li>cumprir com as leis aplicáveis, solicitações legais, ordens judiciais e processos legais, como responder a intimações ou solicitações de autoridades governamentais;</li>
          <li>proteger os direitos, a privacidade, a segurança ou a propriedade dos nossos clientes ou de terceiros (incluindo através da apresentação e defesa de ações judiciais);</li>
          <li>auditar nossos processos internos para conformidade com requisitos legais e contratuais ou com nossas políticas internas;</li>
          <li>aplicar os termos e condições que regem nossos serviços; e</li>
          <li>impedir, identificar, investigar e deter ameaças de segurança e atividades fraudulentas, prejudiciais, não autorizadas, antiéticas ou ilegais, incluindo ataques cibernéticos e roubo de identidade.</li>
        </ol>
      </Grid>
      <Grid>
        <p className='subTitle'>Práticas de segurança</p>
        <p>A segurança das suas informações pessoais é importante para nós. Empregamos uma série de salvaguardas técnicas, organizacionais, físicas e contratuais projetadas para proteger as informações pessoais que coletamos. No entanto, o risco à segurança é inerente a todas as tecnologias da Internet e da informação e não podemos garantir a segurança das suas informações pessoais.</p>
      </Grid>
      <Grid>
        <p className='subTitle'>Crianças</p>
        <p>Não coletamos intencionalmente informações pessoais de crianças. Definimos "crianças" da seguinte forma: qualquer pessoa com menos de 16 anos de idade, ou a idade necessária para consentir com o processamento de informações pessoais no seu país de residência. Se soubermos que recolhemos informações pessoais de uma criança sem o consentimento dos pais ou responsáveis da criança, iremos eliminá-la. Se você for pai ou guardião de uma criança e acreditar que coletamos as informações pessoais da criança sem o seu consentimento, entre em contato conosco para que essas informações sejam excluídas.</p>
      </Grid>
      <Grid>
        <p className='subTitle'>Informações pessoais confidenciais</p>
        <p>Solicitamos que você não nos forneça nenhuma informação pessoal sensível (por exemplo, informações relacionadas a origem racial ou étnica, opiniões políticas, religião ou outras crenças, saúde, biometria ou características genéticas, antecedentes criminais ou filiação a sindicatos) em ou por meio de serviços, páginas da web ou de outra forma para nós. Se você nos fornecer qualquer informação pessoal sensível quando usar serviços, você deve consentir com o nosso processamento e uso de tais informações pessoais confidenciais de acordo com esta Política de Privacidade do Usuário. Se você não consentir com o nosso processamento e uso de tais informações pessoais confidenciais, você não deve enviar tais informações pessoais confidenciais por meio de serviços.</p>
      </Grid>
      <Grid>
        <p className='subTitle'>Criação de perfil e tomada de decisão automatizada</p>
        <p>A criação de perfis é o processamento automatizado de informações pessoais para avaliar certas coisas sobre um indivíduo. Tomada de decisão automatizada significa decisões tomadas exclusivamente por meios automatizados, sem qualquer envolvimento humano. Atualmente, não usamos mecanismos automatizados de criação de perfis ou de tomada de decisão ao processar suas informações pessoais.</p>
      </Grid>
      <Grid>
        <p className='subTitle'>Retenção</p>
        <p>Nós retemos informações pessoais pelo tempo necessário para cumprir os fins para os quais as coletamos, inclusive para os fins de satisfação de quaisquer requisitos legais, contábeis ou de relatórios, para estabelecer ou defender reivindicações legais, ou para fins de prevenção de fraudes. Para determinar o período de retenção apropriado para informações pessoais, consideramos o valor, a natureza e a sensibilidade das informações pessoais, o risco potencial de danos decorrentes do uso não autorizado ou da divulgação de suas informações pessoais, os fins para os quais processamos suas informações pessoais e se podemos alcançar esses fins por meio de outros meios e os requisitos legais aplicáveis. Quando não exigirmos mais as informações pessoais que coletamos sobre você, excluiremos ou tornaremos anônimos ou, se isso não for possível (por exemplo, porque suas informações pessoais foram armazenadas em arquivos de backup), armazenaremos suas informações pessoais com segurança e as isolaremos de qualquer processamento adicional até que a exclusão seja possível. Se anonimizarmos suas informações pessoais (para que elas não possam mais ser associadas a você), poderemos usá-las indefinidamente sem aviso prévio.</p>
      </Grid>
    </S.MainContainer>
  )
}

export default PrivacyPolicy