import { Grid } from "@mui/material";
import * as S from "../style"
import moment from 'moment';
import 'moment/locale/pt-br';
import { capitalize } from "utils/utils";

const LogDetails = ({ log }) => {
  moment.locale("pt-br")

  const handleLogTranslation = (entityKey, entityValue) => {
    if (String(entityKey).includes('value')) {
      return `R$${Number(((entityValue) / 100).toFixed(2)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
    }
    if (String(entityKey).includes('date')) {
      return moment(entityValue).format('DD/MM/YYYY HH:mm')
    }
    return String(entityValue)
  }

  return (
    <S.DetailsGrid container flexDirection='column' gap='10px'>
      <p>Detalhes do Log</p>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className="subTitle">📅 Data:</p>
          <b>{log?.createdAt ? moment(log?.createdAt).format('DD/MM/YYYY') : ""}</b>
        </Grid>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className="subTitle">⏰ Hora:</p>
          <b>{log?.createdAt ? moment(log?.createdAt).format('HH:mm') : ""}</b>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} md={12} xl={12}>
          <p className="subTitle">👤 Usuário:</p>
          <p><b>{log?.user?.name ? log?.user?.name : ""}</b>{`(${log?.user?.type ?? ""})`}</p>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} md={12} xl={12}>
          <p className="subTitle">🔄 Ação:</p>
          <b>{log?.action ? capitalize(log?.action) : ""}</b>
        </Grid>
      </Grid>
      <p className="subTitle">📌 Detalhes:</p>
      <Grid container justifyContent="space-between">
        {log?.object_modified?.previousObject && Object.keys(log?.object_modified?.newObject).map((key) => {
          return (
            <>
              <Grid container item justifyContent="space-between" key={key}>
                <Grid item xs={12} md={12} xl={12} sx={{ overflowWrap: "break-word" }}>
                  <p className="subTitle">{key}:</p>
                  <Grid container gap="10px">
                    <b>"{handleLogTranslation(key, log?.object_modified?.previousObject[key])}"</b>
                    <p>para</p>
                    <b>"{handleLogTranslation(key, log?.object_modified?.newObject[key])}"</b>
                  </Grid>
                </Grid>
              </Grid>
            </>
          );
        })}
        {/*        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className="subTitle">Detalhes do log:</p>
          <p>{log?.action ? log?.action : ""}</p>
        </Grid>*/}
        {/*        <Grid item xs={12} md={5.85} xl={5.85} sx={{ overflowWrap: "break-word" }}>
          <p className="subTitle">Conta Bancária:</p>
          <p>{entry?.bankAccount ? entry?.bankAccount : ""}</p>
        </Grid>*/}
      </Grid>
    </S.DetailsGrid>
  );
};

export default LogDetails;
