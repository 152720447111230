export let REACT_APP_API_URL = process.env.REACT_APP_API_URL;
if (!process.env.REACT_APP_API_URL) REACT_APP_API_URL = process.env.REACT_APP_API_DEV_URL
import mockOrders from "./MockedLists/MockOrders";

export const getOrders = async (page, filters) => {
  const size = 10;
  const start = page * size;
  const end = start + size;

  const filteredOrders = mockOrders.filter((order) => {
    const matchesOrderStatus = filters?.status?.length
      ? filters.status.some((orderStatus) => order.status?.includes(orderStatus))
      : true;

    const matchesOrderPaymentStatus = filters?.pagamento?.length
      ? filters.pagamento.some((payment) => order.pagamento?.includes(payment))
      : true;

    const matchesNumber = filters?.numero
      ? order.numero.includes(filters.numero)
      : true;
      
    const matchesClient = filters?.cliente
    ? order.cliente.toLowerCase().includes(filters.cliente.toLowerCase())
    : true;
    
    return matchesOrderStatus && matchesOrderPaymentStatus && matchesNumber && matchesClient;
  });

  const data = filteredOrders.slice(start, end);
  return data;
};

export const getOrderStatus = async () => {
  const uniqueStatus = [...new Set(mockOrders.map((order) => order.status))];

  const data = uniqueStatus.map((orderStatus) => ({ id: orderStatus, name: orderStatus }));

  return data;
};

export const getOrderPaymentStatus = async () => {
  const uniquePaymentStatus = [...new Set(mockOrders.map((order) => order.pagamento))];

  const data = uniquePaymentStatus.map((paymentStatus) => ({ id: paymentStatus, name: paymentStatus }));

  return data;
};

export const deleteOrder = async (name) => {
  const orderIndex = mockOrders.findIndex((order) => order.name === name);
  if (orderIndex !== -1) {
    mockOrders.splice(orderIndex, 1);
    return { success: true };
  }
};